import React, { Component } from "react";

import {
  MIS_SOLICITUDES_CARD,
  BADGES,
} from "../components/constants/ProposalsConstants";

import { shorten } from "../utils/ComponentUtils";

import CardComponent from "../components/card-proposals/CardComponent";
import BadgeComponent from "../components/elements/BadgeComponent";
import ProposalsNavigation from "../components/proposals-navigation/ProposalsNavigation";
import DialogModalComponent from "../components/elements/DialogModalComponent";
import ConfirmationContent from "../components/elements/form-elements/ConfirmationContent";

import "./propuestas.css";

class MyRequests extends Component {
  renderBadges = () => {
    return BADGES.map((badge) => {
      return (
        <BadgeComponent className="badge-component-style" text={badge.text} />
      );
    });
  };
  renderSubmitModal = () => {
    return (
      <DialogModalComponent
        classNameShowButton="button-vote custom-style-new-proposal-button boton-SIAC"
        classNameBody="confirmation-style-modal"
        // showButtonText="Continuar"
        type="Submit"
        show={true}
        isHaveCloseButton={true}
        onClickHide={() => {}}
      >
        <ConfirmationContent
          classNameDiv="confirmation-div-style"
          classNamePar1="confirmation-par1-style"
          par1="SIAC"
          par2="Será redirigido a la ventana de SIAC para llenar su solicitud"
        />
        {/* <DialogModalComponent
                showButtonText="Aceptar"
              	onClickShow={this.onClickShowSimilar}
				show={this.state.showSimilar}
				onClickHide={this.onClickHideSimilar}>
                <p>Second Modal</p>
            </DialogModalComponent> */}
      </DialogModalComponent>
    );
  };

  renderProposalNavigation = () => {
    return (
      <ProposalsNavigation
        paragraph="Inicio"
        title="Mis solicitudes"
        isHaveFilterModal={true}
        isHaveSearchInput={true}
        isHaveButton={true}
      />
    );
  };

  renderProposalsCards = () => {
    return MIS_SOLICITUDES_CARD.map((info) => {
      return (
        <CardComponent
          classNameLink="card-width"
          classNameCard="card-width-under-link"
          classNameTitle="card-big-title"
          classNameSubtitle="subtitle-style"
          classNameText="card-text-style"
          title={shorten(info.title, 75)}
          subtitle={info.subtitle}
          src={info.src}
          text={shorten(info.text, 150)}
          badges={this.renderBadges()}
          to="/mis-solicitudes/1"
        />
      );
    });
  };

  render() {
    return (
      <div>
        {/* {this.renderProposalNavigation()}
				<div className="cards-content">{this.renderProposalsCards()}</div> */}
        {this.renderSubmitModal()}
      </div>
    );
  }
}

export default MyRequests;
