import * as yup from 'yup';

export const FORM_INITIAL_VALUES = () => {
    return {
        title: '',
        topic: '',
        // image: '',
        content: '',
        labels: '',
    };
};

export const FORM_SCHEMA = yup.object().shape({});

export const FORM_FILTERS_INITIAL_VALUES = () => {
    return {
        categoria: '',
        filtersLabels: '',
        antiquity: '',
        publicationDate: new Date(),
    };
};

export const FORM_FILTERS_SCHEMA = yup.object().shape({});
