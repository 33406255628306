import React from 'react'
import { CircularProgress, Backdrop }  from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    text:{
      marginLeft:5
    }
  }));

const LoadingGeneral = () => {
    const classes = useStyles();
    const loading = useSelector(state=>state.NotificationsReducer.loadingGeneral)

    return (
        <Backdrop className={classes.backdrop} open={loading} >
            <CircularProgress color="inherit" />
            <div>
              <h5 className={classes.text}>Cargando</h5>
            </div>
        </Backdrop>
    )
}

export default LoadingGeneral

