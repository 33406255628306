import * as types from "../../consts/actionTypes"

export const getPropuestas = (payload) =>{
    return{
        type: types.GET_PROPUESTAS,
        payload
    }
}

export const getPropuestasSuccess = (payload) =>{
    return{
        type: types.GET_PROPUESTAS_SUCCESS,
        payload
    }
}

export const getMyPropuestas = (payload) =>{
    return{
        type: types.GET_MY_PROPUESTAS,
        payload
    }
}

export const getMyPropuestasSuccess = (payload) =>{
    return{
        type: types.GET_MY_PROPUESTAS_SUCCESS,
        payload
    }
}

export const getOnePropuesta = (payload) =>{
    return{
        type: types.GET_ONE_PROPUESTA,
        payload
    }
}

export const getOnePropuestaSuccess = (payload) =>{
    return{
        type: types.GET_ONE_PROPUESTA_SUCCESS,
        payload
    }
}

export const supportPropuesta = (payload) =>{
    return{
        type: types.POST_SUPPORT_PROPUESTA,
        payload
    }
}

export const supportPropuestaSuccess = (payload) =>{
    return{
        type: types.POST_SUPPORT_PROPUESTA_SUCCESS,
        payload
    }
}

export const getTendenciasPropuestas = (payload) =>{
    return{
        type: types.GET_TENDENCIAS_PROPUESTAS,
        payload
    }
}

export const postPropuesta = (payload) =>{
    console.log("Creando propuesta...")
    return{
        type: types.POST_PROPUESTA,
        payload
    }
}

export const putPropuesta = (payload) =>{
    console.log("Actualizando propuesta...")
    return{
        type: types.PUT_PROPUESTA,
        payload
    }
}

export const successFlagToFalse = (payload) =>{
    return{
        type: types.PROPUESTAS_SUCCESS_FLAG_TO_FALSE,
        payload
    }
}

export const getApoyosMaximos = (payload) =>{
    return{
        type: types.GET_APOYOS_MAXIMOS,
        payload
    }
}

export const deletePropuesta = (payload) =>{
    return{
        type: types.DELETE_PROPUESTA,
        payload
    }
}



// export const getEncuestasDetails = (payload) =>{
//     return{
//         type: types.GET_ENCUESTAS_DETAILS,
//         payload
//     }
// }

// export const postVotacionUser = (payload) =>{
//     return{
//         type: types.POST_VOTACION_USER, 
//         payload
//     }
// }