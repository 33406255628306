import React, { Component } from "react";
import { Form, Col } from "react-bootstrap";
import Select from "react-select";

class LabelAndDropdown extends Component {
  handleDropdownChange = (value) => {
    // this.props.onChange(`${this.props.searchWord}`, value);
    this.props.setExternalState(value.value) 
  };
  render() {
    const customStyles = {
      container: (state) => ({
        outline: state.isFocused ? "none" : "none",
        borderRadius: ".25rem",
      }),
      control: (provided, state) => ({
        ...provided,
        background: "#F9F9FB",
        border: state.isFocused
          ? "1px solid #1387DA !important"
          : "1px solid #1387DA",

        boxShadow: state.isFocused ? "none" : "none",
        borderRadius: ".25rem",
      }),

      indicatorSeparator: () => ({
        display: "none",
      }),

      menu: (state) => ({
        marginTop: "0",
        borderLeft: "1px solid #ced4da",
        borderRight: "1px solid #ced4da",
        borderBottom: "1px solid #ced4da",
        background: "#F9F9FB",
      }),
    };
    return (
      <Form.Group as={Col} md={this.props.md} controlId={this.props.controlId}>
        <Form.Label className={`label-style ${this.props.classNameLabel} label-filtros`}>
          {this.props.labelName}
        </Form.Label>
        <Select
          styles={customStyles}
          className={this.props.classNameDropdown}
          options={this.props.options}
          cacheOptions
          isClearable={this.props.isClearable}
          placeholder={this.props.placeholder}
          onChange={this.handleDropdownChange}
          defaultValue={this.props.value}
          // isValid={this.props.isValid}
          // isInvalid={this.props.isInvalid}
          // isDirty={this.props.isDirty}
          // noOptionsMessage={() => 'Няма намерена опция'}
          // loadingMessage={() => 'Зарежда опциите'}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary: "#2475B9",
              primary25: "transparent",
              primary50: "transparent",
            },
          })}
        />

        {/* {this.props.error &&
				this.props.touched && (
					<div
						className="invalid-feedback"
						style={{
							display:
								this.props.touched &&
								(_.isEmpty(this.props.value) || this.props.value === null) &&
								!this.props.noValidationBorder
									? 'block'
									: 'none',
							paddingLeft: '12px'
						}}>
						{this.props.error}
					</div>
				)} */}
      </Form.Group>
    );
  }
}

export default LabelAndDropdown;
