import React from "react";

import { Link } from "react-router-dom";
import IconoCalendario from "../../assets/icons/calendario.svg";

import { Card, Container } from "react-bootstrap";

import ButtonComponent from "../elements/ButtonComponent";
import "moment/locale/es";
import moment from "moment";
import imagenDefault from "../../assets/images/PWA-imagen-default.jpg";
import { shorten } from "../../utils/ComponentUtils";

const CardComponent = (props) => {
  const localTime = moment(props.subtitle);
  moment.locale("es");
  localTime.locale(false);

  return (
    <div className={props.classNameLink}>
      <Card
        className={props.classNameCard}
        style={{ borderColor: props.activo ? "#2475B9" : "rgba(0,0,0,.125)" }}
      >
        <Card.Body>
          <Card.Title
            className={props.classNameTitle}
            style={{ display: "block", minHeight: 44 }}
          >
            <p style={{ top: "5%", position: "relative" }}>
              {shorten(props.title, 68)}
            </p>
          </Card.Title>
          <Card.Text>{props.proposalStatus}</Card.Text>
          {props.showAuthor && (
            <Card.Title className="autor-card">
              <p>Creada por:</p>
              <p>{props.autor}</p>
            </Card.Title>
          )}
          <Card.Subtitle
            className={`mb-2 text-muted ${props.classNameSubtitle}`}
          >
            <img src={IconoCalendario} style={{ marginRight: "0.5em" }} />
            {localTime.format("LL")}
          </Card.Subtitle>
          <Card.Text
            className={props.classNameText}
            dangerouslySetInnerHTML={{ __html: props.text }}
          ></Card.Text>
          <Card.Text
            style={{ margin: "0em 0em 1em 0em" }}
            className="etiquetas-tarjetas-propuestas"
          >
            {props.badges}
          </Card.Text>
          <div
            className={props.classNameImg}
            style={{
              backgroundImage:
                "url(" + (props.src ? props.src : imagenDefault) + ")",
            }}
          >
            <div
              style={{
                backgroundImage:
                  "url(" + (props.src ? props.src : imagenDefault) + ")",
              }}
            />
          </div>
          {props.isHaveVoteSection ? (
            <div className="card-details-vote">
              <Card.Title className={props.classNameSecondTitle}>
                {props.secondTitle}
              </Card.Title>
              <Card.Text>{props.progressBar}</Card.Text>
              <Card.Text className={props.classNameNumberOfVotes}>
                {props.numberOfVotes}
              </Card.Text>

              <ButtonComponent
                classNameButton={props.classNameButton}
                buttonText={props.buttonText}
                to={props.to}
                disabled={props.disabledButton}
              />
              <Card.Text className={props.classNameSocialMedia}>
                {props.socialMedia}
              </Card.Text>
            </div>
          ) : (
            ""
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default CardComponent;
