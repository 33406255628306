import React from 'react';

import { Badge } from 'react-bootstrap';

const BadgeComponent = (props) => {
	return (
		<Badge className={props.className} 
		style={{background: "#F0F3FF",color:"#2475B9", fontWeight: 600, fontSize:"0.9em", padding: "0.5em 1.25em"}} 
		variant="primary">
			{props.text}
		</Badge>
	);
};

export default BadgeComponent;
