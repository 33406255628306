import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import "./login.css";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "../../redux/actions/userActions";
import * as userResult from "../../redux/selectors/rootSelectors";
import * as GAservices from "../../redux/services/GoogleAnalyticsServices";

//Components Page
import LoginBg from "./LoginBg";
import LoginForm from "./LoginForm";

const Login = () => {
  //let history = useHistory();
  const dispatch = useDispatch();
  const logged = useSelector((state) => userResult.logged(state));
  const token = useSelector((state) => userResult.tokenAccess(state));
  const [validated, setValidated] = React.useState(false);

  const handleSubmit = (e, data) => {
    const form = e.currentTarget;
    console.log(form.checkValidity());
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      e.stopPropagation();
      try {
        const body = {
          username: data.user,
          password: data.password,
        };

        dispatch(userActions.login(body));
      } catch (e) {
        console.log(e);
      }
    }
    setValidated(true);
    // history.push("/");
  };

  useEffect(() => {
    GAservices.GApageView("Login");
    dispatch(userActions.registerReset())
  }, []);
  useEffect(() => {}, [logged]);

  if (token) {
    return <Redirect to="/" />;
  }

  return (
    <Row style={{ minHeight: "100vh", height: "fit-content", width: "105%" , justifyContent:"center"}}>
      {/* <LoginBg /> */}
      <LoginForm validated={validated} handleSubmit={handleSubmit} />
    </Row>
  );
};

export default Login;
