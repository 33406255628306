import ReactGA from "react-ga";

export const initGA = () => {
  ReactGA.initialize("UA-180525756-1");
};

export const GApageView = (page) => {
  ReactGA.pageview(page);
};

export const GAmodalView = (page) => {
  ReactGA.modalview(page);
};

export const GAevent = (
  categoryName,
  eventName,
  label = "Sin etiqueta",
  value = 0,
  noninteraction = false
) => {
  ReactGA.event({
    category: categoryName, // Required
    action: eventName, // Required
    label: label,
    value: value,
    nonInteraction: noninteraction,
  });
};

export const GAtiming = (categoryName, variableName, valueNum) => {
  ReactGA.timing({
    category: categoryName,
    variable: variableName,
    value: valueNum,
  });
};

export const GAexception = (detail) => {
  ReactGA.exception({ description: detail });
};
