import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  Row,
  Container,
  Col,
  BreadcrumbItem,
  Carousel,
  CarouselItem,
  Card,
  Badge,
} from "react-bootstrap";
import {
  shorten,
  outerHtmlToReadableString,
  djangoParserDeFecha,
} from "../../utils/ComponentUtils";
import "./Inicio.css";
import BadgeComponent from "../../components/elements/BadgeComponent";
import { useDispatch, useSelector } from "react-redux";
import * as PropuestasActions from "../../redux/actions/propuestaActions";
import * as PropuestasSelector from "../../redux/selectors/propuestaSelector";
import * as ProyectosActions from "../../redux/actions/proyectoActions";
import * as ProyectosSelector from "../../redux/selectors/proyectoSelectors";
import { Link, useHistory } from "react-router-dom";
import * as GAservices from "../../redux/services/GoogleAnalyticsServices";
import IconoCalendarioBlanco from "../../assets/icons/calendario-blanco.svg";
import IconoUsuarioBlanco from "../../assets/icons/usuario-blanco.svg";
import IconoExternalLinkBlanco from "../../assets/icons/external-link-blanco.svg";
import imagenDefault from "../../assets/images/PWA-imagen-default.jpg";

const columns = ["NOMBRE", "ESTATUS", "ÁREA", "FECHA", "ENLACE"];

const Inicio = () => {
  const [value, setValue] = useState("");
  const history = useHistory();
  const [desplegarTexto, setDesplegarTexto] = useState(false);
  const dispatch = useDispatch();
  const urlBack =
    "https://www.kienyke.com/sites/default/files/styles/amp_1200x675_16_9/public/2020-06/Di%CC%81a_de_campesino%20%281%29.jpg?itok=a7BwJG8U";

  const tendenciasPropuestas = useSelector((state) =>
    PropuestasSelector.tendenciasPropuestas(state)
  );
  const tendenciasProyectos = useSelector((state) =>
    ProyectosSelector.proyectos(state)
  );

  useEffect(() => {
    dispatch(PropuestasActions.getTendenciasPropuestas());
    dispatch(ProyectosActions.getProyectos());
    GAservices.GApageView("Inicio");
  }, []);

  return (
    <div className="section" style={{ backgroundColor: "#f7f7f9" }}>
      <Helmet>
        <title>Participación Ciudadana | Inicio</title>
      </Helmet>
      <Row>
        <Container fluid className="container-information contenedor-inicio">
          <div className="breadcrumb-section">
            <p style={{ fontSize: "14px", color: "#1C1861" }}>Inicio</p>
            <h4 style={{ color: "#0F69B4" }}>Proyectos de Gobierno</h4>
          </div>

          <Row>
            <Col sm={12}>
              <Carousel className="carousel ">
                {tendenciasProyectos
                  ? tendenciasProyectos
                      .filter((proyecto) => proyecto.anclar_inicio && proyecto.disponibilidad==="1")
                      .map((item, key) => {
                        return (
                          <CarouselItem style={{ height: "inherit" }} key={key}>
                            <div
                              style={{
                                backgroundImage: "url(" + item.img + ")",
                              }}
                              className="bg-cover-image d-block w-100 img-carousel "
                            />
                            <Carousel.Caption className="carousel-gradient">
                              <div className="div-titulo-proyecto-tendencia">
                                <h3>{shorten(item.nombre, 150)}</h3>
                                <div
                                  className="div-icono-link"
                                  onClick={() =>
                                    history.push(`/proyectos/${item.id}`)
                                  }
                                >
                                  <img
                                    src={IconoExternalLinkBlanco}
                                    alt="link de proyecto"
                                  />{" "}
                                </div>
                              </div>
                              <div className="div-tags-fecha-area-proyecto">
                                <div className="div-tags-proyecto">
                                  {item.etiquetas.map((tag, key) => {
                                    return (
                                      <Badge
                                        pill
                                        key={key}
                                        className="badge-style"
                                      >
                                        {tag.nombre}
                                      </Badge>
                                    );
                                  })}
                                </div>
                                <div className="div-fecha-proyecto">
                                  <img
                                    src={IconoCalendarioBlanco}
                                    alt="fecha"
                                  />
                                  <p>
                                    {djangoParserDeFecha(item.fecha_creacion)}
                                  </p>
                                </div>
                                <div className="div-area-proyecto">
                                  <img src={IconoUsuarioBlanco} alt="area" />
                                  <p>{"Ayuntamiento"}</p>
                                </div>
                              </div>
                              <div
                                style={{ marginBottom: "1.5em" }}
                                // dangerouslySetInnerHTML={{
                                //   __html: shorten(item.proyecto.descripcion, 200),
                                // }}
                              >
                                <p
                                  id={
                                    desplegarTexto &&
                                    "texto-descriptivo-tarjeta-proyecto-tendencias"
                                  }
                                >
                                  {shorten(
                                    outerHtmlToReadableString(item.descripcion),
                                    desplegarTexto ? undefined : 50
                                  )}
                                  {"    "}
                                  {outerHtmlToReadableString(item.descripcion)
                                    .length > 50 && (
                                    <span
                                      className="ver-mas-desplegar-texto"
                                      onClick={() =>
                                        setDesplegarTexto(!desplegarTexto)
                                      }
                                    >
                                      {desplegarTexto ? "Ver menos" : "Ver más"}
                                    </span>
                                  )}
                                </p>
                              </div>
                            </Carousel.Caption>
                          </CarouselItem>
                        );
                      })
                  : null}
              </Carousel>
            </Col>

            <Col sm={12} style={{ marginTop: 41, marginBottom: 20 }}>
              <div className="breadcrumb-section">
                <h4 style={{ color: "#0F69B4" }}>
                  Propuestas Ciudadanas más activas
                </h4>
              </div>
              <Row>
                {tendenciasPropuestas
                  ? tendenciasPropuestas.map((card, key) => {
                      return (
                        <Col md={4} key={key} style={{ marginTop: 20 }}>
                          <Card className="cardItem">
                            <Card.Body>
                              <div className="imgContainer-card">
                                {/* <Card.Img variant="top" src={card.img} /> */}
                                <div
                                  className="bg-cover-image card-img-top h-100 "
                                  style={{
                                    backgroundImage:
                                      "url(" +
                                      (card.propuesta.imagen
                                        ? card.propuesta.imagen
                                        : imagenDefault) +
                                      ")",
                                  }}
                                >
                                  <div
                                    className="imagen-tarjetas-tendencias"
                                    style={{
                                      backgroundImage:
                                        "url(" +
                                        (card.propuesta.imagen
                                          ? card.propuesta.imagen
                                          : imagenDefault) +
                                        ")",
                                    }}
                                  ></div>
                                </div>
                              </div>
                              <Card.Title className="cards-title">
                                <Link to={"/propuestas/" + card.propuesta.id}>
                                  <h3
                                    style={{
                                      color: "#2475B9",
                                      fontSize: "18px",
                                      height: "44px",
                                    }}
                                  >
                                    {card.propuesta.titulo}
                                  </h3>
                                </Link>
                              </Card.Title>
                              <Card.Text
                                className="cards-text"
                                style={{ fontSize: "14px", height: "88px" }}
                                dangerouslySetInnerHTML={{
                                  __html: shorten(
                                    outerHtmlToReadableString(
                                      card.propuesta.contenido
                                    ),
                                    150
                                  ),
                                }}
                              ></Card.Text>
                              {card.propuesta.etiquetas.map((tag, key) => {
                                return (
                                  // <div key={key} className="tag cards-tags" style={{ color: "#2046D3", fontWeight: 700 }}>
                                  //     {tag}
                                  // </div>
                                  <BadgeComponent
                                    className="badge-component-style"
                                    text={tag.nombre}
                                  />
                                );
                              })}
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })
                  : null}
              </Row>
            </Col>
          </Row>
        </Container>
      </Row>
    </div>
  );
};

export default Inicio;
