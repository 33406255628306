import React, { useCallback, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { FormControl, Button } from "@material-ui/core";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { setMessages } from "../../redux/actions/messages";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Undo } from "@material-ui/icons/";
import clsx from "clsx";

import MyDropzone from "./Uploader";

import "../styles/variables.css";

import { FormatBold, FormatItalic, FormatUnderlined } from "@material-ui/icons";

import * as Actions from "../../redux/actions/conversacionesActions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  placeholder: {
    color: "var(--description-color)",
    fontSize: "14px",
  },
  button: {
    margin: theme.spacing(1),
    width: "175px",
    height: "40px",
    padding: 14,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  input: {
    padding: "14px 14px",
    backgroundColor: "#f9f9fb",
  },
  messageInputWrapper: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    background: "#F9FAFC",
    border: "1px solid #1387DA",
    borderRadius: "5px",
    height: "40px",
  },
  messageInput: {
    width: "100%",
    height: "100%",
    paddingRight: "28px",
    wordBreak: "break-word",
    maxHeight: "110px",
    overflowY: "auto",
    "& > .ql-container": {
      border: "none",
    },
    background: "none",
    border: "none",
  },
  toolbar: {
    right: "5px",
    color: "#0F69B4",
    border: "none!important",
    "& > button": {
      display: "none",
      width: "auto !important",
      height: "auto !important",
      padding: "revert !important",
    },
  },
}));
const icons = ReactQuill.Quill.import("ui/icons");
icons["bold"] = ReactDOMServer.renderToString(
  <FormatBold
    htmlColor="#828282"
    aria-label="toggle visibility"
    edge="end"
  ></FormatBold>
);
icons["italic"] = ReactDOMServer.renderToString(
  <FormatItalic
    htmlColor="#828282"
    aria-label="toggle visibility"
    edge="end"
  ></FormatItalic>
);
icons["underline"] = ReactDOMServer.renderToString(
  <FormatUnderlined
    htmlColor="#828282"
    aria-label="toggle visibility"
    edge="end"
  ></FormatUnderlined>
);
const CustomToolbar = ({ id }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div id="toolbar" className={classes.toolbar}>
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <MyDropzone id={id} />
    </div>
  );
};

const InputForm = ({ sendMessage }) => {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [text, setText] = useState("");

  // Get conversation State
  const conversationsState = useSelector((state) => state.ConversacionReducer);

  const { attachment } = conversationsState;

  const handleChange = (value) => {
    console.log(value);
    setText(value);
  };

  const handleSend = useCallback(() => {
    if (text.length > 0) {
      const file = attachment && attachment.id;
      if (file) {
        dispatch(
          Actions.sendMessage({
            content: text,
            conversation: id,
            file,
            active: true,
            encuesta: null,
          })
        );
      } else {
        sendMessage(text);
      }

      setText("");
    }
  }, [dispatch, id, text, attachment]);

  return (
    <div className={classes.root}>
      <FormControl
        className={clsx(classes.margin, classes.textField)}
        variant="outlined"
        fullWidth
      >
        <div className={classes.messageInputWrapper}>
          {/* <ReactQuill
            className={classes.messageInput + " quill-chat"}
            placeholder="Escribe un mensaje..."
            value={text}
            onChange={handleChange}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSend();
              }
            }}
            modules={{
              toolbar: {
                container: "#toolbar",
              },
            }}
          /> */}
          <textarea
            className={classes.messageInput + " quill-chat"}
            placeholder="Escribe un mensaje..."
            value={text}
            onChange={(event) => handleChange(event.target.value)}
            // onKeyDown={(event) => {
            //   console.log(event.key)
            //   if (event.key === "Shift" && event.key === "Enter") {
            //     handleSend();
            //   }
            // }}
          />
          <CustomToolbar id={id} />
        </div>
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        id="boton-enviar-chat"
        endIcon={<Undo>Enviar</Undo>}
        onClick={handleSend}
        type="submit"
      >
        Enviar
      </Button>
    </div>
  );
};

export default InputForm;
