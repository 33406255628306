import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import * as GAservices from "../../redux/services/GoogleAnalyticsServices";

//Icons
import CerrarCirculoBlanco from "../../assets/icons/cerrar-x-circulo-blanco.svg";

//Styles
import "./Configuracion.css";

//Actions
import { logOut } from "../../redux/actions/userActions";
import * as ConfigActions from "../../redux/actions/configuracionActions";
import * as userActions from "../../redux/actions/userActions";

//Selectors
import * as ConfigSelectors from "../../redux/selectors/configuracionSelectors";
import { user } from "../../redux/selectors/rootSelectors";

//Components
import ModalResRequest from "../../components/ModalResRequest/ModalResRequest";

//ApiLink
import baseUrl from "../../consts/GeneralUrl";


const Configuracion = () => {
  const dispatch = useDispatch();
  const successFlag = useSelector((state) =>
    ConfigSelectors.successFlagResult(state)
  );
  const avatares = useSelector((state) =>
    ConfigSelectors.avataresResult(state)
  );
  const usuario = useSelector((state) => user(state));
  const [
    recibirNotificacionesAlCorreo,
    setRecibirNotificacionesAlCorreo,
  ] = useState(true);
  const [avatar, setAvatar] = useState(0);
  const [pass1, setPass1] = useState();
  const [pass2, setPass2] = useState();
  const [email1, setEmail1] = useState();
  const [email2, setEmail2] = useState();
  const [modalResReq, setModalResReq] = useState(false);
  const [modalResReqLoading, setModalResReqLoading] = useState(false);
  const [modalResReqExito, setModalResReqExito] = useState(undefined);
  const [modalResReqMensajeEspera, setModalResReqMensajeEspera] = useState("");
  const [modalResReqMensajeExito, setModalResReqMensajeExito] = useState("");
  const [modalResReqMensajeError, setModalResReqMensajeError] = useState("");

  const handleCambiarEmail = (event) => {
    event.preventDefault();
    let form = document.getElementById("form-email-configuracion");
    if (form.checkValidity()) {
      if (email1 === email2) {
        dispatch(
          ConfigActions.cambiarEmail({
            id: usuario.user.id,
            body: {
              email: email1,
              first_name: usuario.user.first_name,
              last_name: usuario.user.last_name,
              clave_ciudadano: usuario.user.clave_ciudadano,
            },
          })
        );
        setModalResReqLoading(true);
        setModalResReqMensajeEspera("Actualizando email");
        setModalResReqMensajeExito("Su email ha sido actualizada");
        setModalResReqMensajeError(
          "Surgió un error al intentar actualizar su email"
        );
        setModalResReq(true);
      } else {
        let input = document.getElementById(
          "input-confirmar-email-configuracion"
        );
        input.setCustomValidity("Los correos no coinciden");
        input.reportValidity();
      }
    } else {
      form.reportValidity();
    }
  };

  const handleCambiarContraseña = (event) => {
    event.preventDefault();
    let form = document.getElementById("form-password-configuracion");
    if (form.checkValidity()) {
      if (pass1 !== pass2) {
        let input = document.getElementById(
          "input-confirma-contrasena-configuracion"
        );
        input.setCustomValidity("Las contraseñas no coinciden");
        input.reportValidity();
      } else {
        dispatch(
          ConfigActions.cambiarPassword({
            new_password1: pass1,
            new_password2: pass2,
          })
        );
        setModalResReqLoading(true);
        setModalResReqMensajeEspera("Actualizando contraseña");
        setModalResReqMensajeExito("La contraseña ha sido actualizada");
        setModalResReqMensajeError(
          "Surgió un error al intentar actualizar la contraseña"
        );
        setModalResReq(true);
      }
    } else {
      form.reportValidity();
    }
  };

  const handleCambiarAvatar = () => {
    dispatch(ConfigActions.actualizarAvatar({ avatar: avatar.img }));
  };

  console.log(successFlag);

  useEffect(() => {
    if (modalResReq) {
      setModalResReqLoading(false);
      if (successFlag === true) {
        setModalResReqExito(true);
        dispatch(ConfigActions.resetSuccessFlag());
      } else if (successFlag === false) {
        setModalResReqExito(false);
        dispatch(ConfigActions.resetSuccessFlag());
      }
    } else {
      setModalResReqExito(undefined);
    }
    if (successFlag) {
      dispatch(userActions.refreshUser());
      dispatch(ConfigActions.resetSuccessFlag());
    }
  }, [successFlag]);

  useEffect(() => {
    if (usuario) {
      setEmail1(usuario.user.email);
      setEmail2(usuario.user.email);
    }
  }, [usuario]);

  // useEffect(() => {
  //   dispatch(
  //     ConfigActions.toggleNotificacionesEmail(recibirNotificacionesAlCorreo)
  //   );
  // }, [recibirNotificacionesAlCorreo]);

  useEffect(() => {
    dispatch(ConfigActions.getAvatares());
    GAservices.GApageView("Configuración");
  }, []);

  return (
    <div>
      <Helmet>
        <title>Participación Ciudadana | Configuración</title>
      </Helmet>
      <div className="div-titulo-navegacion-configuracion">
        <p className="p-navegacion-configuracion">Inicio</p>
        <p className="p-titulo-configuracion">Configuración</p>
      </div>
      <div className="contenedor-configuracion">
        <form id="form-email-configuracion" className="forms-configuracion">
          <section className="seccion-3-configuracion">
            <label className="labels-configuracion">
              Nuevo correo electrónico
            </label>
            <input
              className="inputs-configuracion"
              type="email"
              required
              placeholder="Ej. luisperez@mail.com"
              defaultValue={email1}
              onChange={(event) => setEmail1(event.target.value)}
            />
          </section>
          <section className="seccion-3-configuracion">
            <label className="labels-configuracion">
              Confirme su nuevo correo electrónico
            </label>
            <input
              id="input-confirmar-email-configuracion"
              className="inputs-configuracion"
              type="email"
              required
              placeholder="Ej. luisperez@mail.com"
              defaultValue={email2}
              onChange={(event) => setEmail2(event.target.value)}
            />
          </section>
          <section className="seccion-3-configuracion">
            <button
              className="botones-guardar-configuracion"
              onClick={(event) => handleCambiarEmail(event)}
            >
              Guardar
            </button>
          </section>
        </form>
        <form id="form-password-configuracion" className="forms-configuracion">
          <section className="seccion-3-configuracion">
            <label className="labels-configuracion">Nueva contraseña</label>
            <input
              className="inputs-configuracion"
              type="password"
              required
              minLength="8"
              onChange={(event) => setPass1(event.target.value)}
            />
          </section>
          <section className="seccion-3-configuracion">
            <label className="labels-configuracion">
              Confirme su nueva contraseña
            </label>
            <input
              id="input-confirma-contrasena-configuracion"
              className="inputs-configuracion"
              type="password"
              required
              minLength="8"
              onChange={(event) => setPass2(event.target.value)}
            />
          </section>
          <section className="seccion-3-configuracion">
            <button
              className="botones-guardar-configuracion"
              onClick={(event) => handleCambiarContraseña(event)}
            >
              Guardar
            </button>
          </section>
        </form>
        <div className="div-avatares-configuracion">
          <section className="seccion-6-configuracion">
            <label className="labels-configuracion">Nuevo avatar</label>
            <div className="div-div-avatares-configuracion">
              {avatares &&
                avatares.map((avatarImg, index) => (
                  <img
                    className={
                      avatar.index === index
                        ? "icono-avatar-seleccionado-configuracion"
                        : "icono-avatar-configuracion"
                    }
                    src={
                      "https://static-pc.nyc3.digitaloceanspaces.com/static-pc/static/avatar/" +
                      avatarImg
                    }
                    alt={`Avatar: ${avatarImg}`}
                    onClick={() => setAvatar({ img: avatarImg, index: index })}
                  />
                ))}
            </div>
          </section>
          <section className="seccion-3-configuracion">
            <button
              className="botones-guardar-configuracion boton-guardar-avatar-configuracion"
              onClick={handleCambiarAvatar}
            >
              Guardar
            </button>
          </section>
        </div>
        {/* <div className="div-recibir-notificaciones-correo-configuracion">
          <label className="labels-configuracion">
            Recibir notificaciones por correo electrónico
          </label>
          <button
            className="toggle-button-configuracion"
            onClick={() =>
              setRecibirNotificacionesAlCorreo(!recibirNotificacionesAlCorreo)
            }
            style={{
              backgroundColor: recibirNotificacionesAlCorreo
                ? "#0f69b4"
                : "#c7c7c7",
            }}
          >
            <div
              className="bolita-blanca-toggle-button-configuracion"
              style={{
                left: recibirNotificacionesAlCorreo ? "22px" : "0",
              }}
            ></div>
          </button>
        </div> */}
        <div>
          <label className="labels-configuracion">
            Cerrar Todas las Sesiones
          </label>
          <button
            className="botones-guardar-configuracion"
            onClick={() => dispatch(logOut())}
          >
            <p>Cerrar Sesion</p>
            <img
              src={CerrarCirculoBlanco}
              alt="cerrar"
              style={{ marginLeft: "10px" }}
            />
          </button>
        </div>
      </div>
      <ModalResRequest
        visibilidad={modalResReq}
        setModalResRequest={setModalResReq}
        loading={modalResReqLoading}
        exito={modalResReqExito}
        mensajeDeEspera={modalResReqMensajeEspera}
        mensajeExito={modalResReqMensajeExito}
        mensajeError={modalResReqMensajeError}
      />
    </div>
  );
};

export default Configuracion;
