import React from "react";
import Header from "../header/header";
import {
  Drawer,
  List,
  makeStyles,
  Hidden,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  Divider,
} from "@material-ui/core";
import logo from "./M&R SIGLAS SIN FONDO (1).jpg";
// import eslogan from "./Tarapacá Nuestra.png";
import routes from "../../routes";
import { useSelector, useDispatch } from "react-redux";
import { toogleSideBarShow } from "../../redux/actions/sideBarActions";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./sidebar.css";

export const drawerWidth = 256;

const styles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  appBar: {
    width: "100%",
    position: "fixed",
    zIndex: "4",
    top: "0",
    // [theme.breakpoints.up("sm")]: {
    //   width: `calc(100% - ${drawerWidth}px)`,
    // },
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  logoContainer: {
    height: "64px",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  listToolbar: {
    marginTop: "1em",
  },
  toolbar: theme.mixins.toolbar,
  titleToolbar: {
    color: "#546E7A",
    fontWeight: "bolder",
    marginLeft: "1em",
  },
  icon: {
    minWidth: "2.5em",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const SideBar = (props) => {
  const { window } = props;
  const classes = styles();
  const theme = useTheme();
  const sidebarToogle = useSelector((state) => state.sideBar);
  const dispatch = useDispatch();

  const drawer = (
    <div className={classes.toolbar}>
      <List>
        <div className={classes.logoContainer}>
          <img src={logo} style={{height:"64px"}} alt="M&R" />
          {/* <div className="linea-gris-logo"></div>
          <img src={eslogan} alt="tarapaca Nuestra" /> */}
        </div>
        <Divider />
        {routes.map((rt, key) => {
          return (
            <div className={classes.listToolbar}>
              <ListItemText className={classes.titleToolbar}>
                {rt.name}
              </ListItemText>
              {rt.children.map((ch, key) => {
                if (ch.name === "Tutoriales") {
                  return (
                    <a
                      target="_blank"
                      href="https://www.youtube.com/channel/UC3x8zopIUEbUJ9KgySbhbVQ"
                    >
                      <ListItem button>
                        <ListItemIcon className={classes.icon}>
                          <FontAwesomeIcon icon={ch.icon} color="#2475B9" />
                        </ListItemIcon>
                        <ListItemText primary={ch.name} className="texto-sidebar"/>
                      </ListItem>
                    </a>
                  );
                }
                return (
                  <ListItem button component={Link} to={ch.path}>
                    <ListItemIcon className={classes.icon}>
                      <FontAwesomeIcon icon={ch.icon} color="#2475B9" />
                    </ListItemIcon>
                    <ListItemText primary={ch.name} className="texto-sidebar"/>
                  </ListItem>
                );
              })}
            </div>
          );
        })}

        {/* <Divider /> */}
        {/* <div>
          <ListItemText className={classes.titleToolbar}>
            Conversaciones Recientes
          </ListItemText>
          <div>
            <ListItem>
              <ListItemIcon>
                <div className="green_ball" />
              </ListItemIcon>
              <ListItemText primary="9038-3248-2341" />
            </ListItem>
          </div>
        </div> */}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <div className={classes.appBar}>
        <Header styleClasses={classes} />
      </div>

      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={sidebarToogle}
            onClose={() => dispatch(toogleSideBarShow())}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
};

export default SideBar;
