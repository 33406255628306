import React, { Component, useState } from "react";
import { Helmet } from "react-helmet";


import ProposalsNavigation from "../../components/proposals-navigation/ProposalsNavigation";
import CardInfoComponent from "../../components/card-proposals/CardInfoComponent";
import BadgeComponent from "../../components/elements/BadgeComponent";
import ProgressBarComponent from "../../components/elements/ProgressBarComponent";
import WriteCommentComponent from "../../components/elements/WriteCommentComponent";
import CommentComponent from "../../components/elements/CommentComponent";
import SocialMediaComponent from "../../components/elements/SocialMediaComponent";

import { BADGES } from "../../components/constants/ProposalsConstants";

import userIcon from "../../assets/images/user-icon.png";
import pointsVertical from "../../assets/icons/points-vertical.png";
import infoImage from "../../assets/images/Rectangle 153.png";
import commentPurpleIcon from "../../assets/icons/comment-purple.png";
import commentWhite from "../../assets/icons/comment-white.png";
import DialogModalComponent from "../../components/elements/DialogModalComponent";
import ConfirmationContent from "../../components/elements/form-elements/ConfirmationContent";
import { useParams } from "react-router-dom";
import * as PropuestaActions from "../../redux/actions/propuestaActions";
import * as Acciones from "../../redux/actions/accionActions";
import * as Selectors from "../../redux/selectors/acccionSelector";
import * as PropuestaSelectors from "../../redux/selectors/propuestaSelector";
import * as UserSelectors from "../../redux/selectors/rootSelectors";
import * as AccionesComentario from "../../redux/actions/comentarioActions";
import * as SelectorsComentario from "../../redux/selectors/comentarioSelector";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { outerHtmlToReadableString } from "../../utils/ComponentUtils";
import GeneralUrlStatic from "../../consts/GeneralUrlStatic";

import Swal from "sweetalert2";

// import "./propuestasInfo.css";

const AccionesInfo = () => {
  const { idAccion } = useParams();
  const [comment, setComment] = useState();
  const [refreshCommentsStates, setRefreshCommentsStates] = useState(false);
  // constructor(props) {
  //     super(props);
  //     this.state = {
  //         votes: 990,
  //         voted: false,
  //         show: false,
  //     };
  // }
  const dispatch = useDispatch();
  const accionInfo = useSelector((state) => Selectors.accion(state));
  const apoyosMaximos = useSelector((state) =>
    PropuestaSelectors.apoyosMaximosResult(state)
  );
  const user = useSelector((state) => UserSelectors.user(state));
  const comentarioSuccessFlag = useSelector((state) =>
    SelectorsComentario.comentarioSuccessFlag(state)
  );
  const accionSuccessFlag = useSelector((state) =>
    Selectors.accionSuccessFlag(state)
  );

  useEffect(() => {
    dispatch(Acciones.getOneAccion(idAccion));
  }, []);

  useEffect(() => {
    dispatch(PropuestaActions.getApoyosMaximos());
  }, [accionInfo]);

  useEffect(() => {
    if (accionSuccessFlag) {
      dispatch(Acciones.getOneAccion(idAccion));
      setRefreshCommentsStates(comentarioSuccessFlag);
      dispatch(AccionesComentario.ComentarioSuccessFlagToFalse());
    }
  }, [accionSuccessFlag]);

  useEffect(() => {
    if (comentarioSuccessFlag) {
      dispatch(Acciones.getOneAccion(idAccion));
      dispatch(Acciones.accionSuccessFlagToFalse());
      dispatch(AccionesComentario.ComentarioSuccessFlagToFalse());
    }
  }, [comentarioSuccessFlag]);

  console.log(accionInfo);

  const renderBadges = (etiquetas) => {
    return etiquetas.map((badge) => {
      return (
        <BadgeComponent className="badge-component-style" text={badge.nombre} />
      );
    });
  };

  const renderProgressBar = (apoyos, max) => {
    return <ProgressBarComponent now={apoyos} max={max} />;
  };

  const renderWriteComment = () => {
    return (
      <WriteCommentComponent
        className="write-comment-div"
        rows="1"
        placeholder="Enscribe un comentario ..."
        classNameButton="button-vote"
        buttonText="Comentar"
        srcAfter={commentWhite}
        classNameImgAfter="proposals-image-after-style custom-icon-style"
        classNameLink="custom-write-comment-style"
        // to=""
      />
    );
  };

  const renderComment = () => {
    return (
      <CommentComponent
        src={userIcon}
        iconSrc={pointsVertical}
        user="ASDF12345678"
        date="13 de junio de 2020 a las 16:05"
        content="Ciudadanos abogan por la creación de un centro de reciclaje en Camiña. Ciudadanos abogan por la creación de un centro de reciclaje en Camiña. Ciudadanos abogan por la creación de un centro de reciclaje en Camiña."
      />
    );
  };

  const renderSocialMediaComponent = () => {
    let hashtags = "";
    if (accionInfo && Array.isArray(accionInfo.etiquetas)) {
      accionInfo.etiquetas.forEach((etiqueta, index) => {
        if (index === 0) {
          hashtags = hashtags + etiqueta.nombre;
        } else {
          hashtags = hashtags + "," + etiqueta.nombre.trim();
        }
      });
    }
    return (
      <SocialMediaComponent
        classNameSocialMediaDiv="social-media-div"
        classNameSocialMediaIcons="social-media-icons"
        twitterHashtags={hashtags}
        titulo={
          accionInfo
            ? "Ven y apoya la acción de Participación Ciudadana: " +
              accionInfo.titulo
            : "Ven y apoya la acción de Participación Ciudadana"
        }
      />
    );
  };

  const renderNavigation = () => {
    return (
      <ProposalsNavigation
        paragraph="Regresar a Acciones"
        title={`Acción: ${accionInfo && accionInfo.titulo}`}
        isHaveSearchInput={false}
        isHaveButton={false}
        textButton="Nueva Propuesta"
        goBackRoute={"/acciones"}
      />
    );
  };

  const renderSubmitModal = () => {
    return (
      <DialogModalComponent
        classNameShowButton="button-vote custom-style-new-proposal-button"
        classNameBody="confirmation-style-modal"
        showButtonText="Continuar"
        type="Submit"
        show={false}
        // onClickHide={()=>{this.setState({show:false})}}
      >
        <ConfirmationContent
          classNameDiv="confirmation-div-style"
          classNamePar1="confirmation-par1-style"
          par1="Propuesta Ciudadana"
          par2="Propuesta apoyada con éxito"
          // onClickAccept={() => this.setState({ show: false })}
        />
        {/* <DialogModalComponent
                showButtonText="Aceptar"
              	onClickShow={this.onClickShowSimilar}
				show={this.state.showSimilar}
				onClickHide={this.onClickHideSimilar}>
                <p>Second Modal</p>
            </DialogModalComponent> */}
      </DialogModalComponent>
    );
  };

  const renderProposalsInfoContent = () => {
    return (
      <div className="proposals-info-content">
        {accionInfo ? (
          <CardInfoComponent
            classNameTitle="card-big-title"
            title={accionInfo.titulo}
            classNameSubtitle="subtitle-style"
            subtitle={accionInfo.fecha}
            isHaveVoteSection={accionInfo.estado === "A"}
            badges={renderBadges(accionInfo.etiquetas)}
            classNameText="card-text-style"
            text={accionInfo.quill_html}
            src={accionInfo.img ? GeneralUrlStatic+accionInfo.img : false}
            isHaveCommentsSection={true}
            tieneVotacionActiva={
              accionInfo.encuesta && accionInfo.encuesta.estado === "1"
                ? true
                : false
            }
            votacion={accionInfo.encuesta}
            secondTitle="Progreso"
            progressBar={renderProgressBar(accionInfo.apoyos, apoyosMaximos)}
            numberOfVotes={
              apoyosMaximos
                ? `${accionInfo.apoyos}/${apoyosMaximos} apoyos`
                : ""
            }
            classNameButton={!false ? "button-vote" : "button-vote-disable"}
            buttonText="Apoyar"
            writeComment={renderWriteComment()}
            classNameCommentTitle="comment-title-style"
            iconCommentSrc={commentPurpleIcon}
            commentTitle="Comentarios"
            comentarios={accionInfo.comentarios ? accionInfo.comentarios : []}
            // comment={renderComment()}
            socialMedia={renderSocialMediaComponent()}
            idUsuarioLogged={user ? user.user.id : null}
            comment={comment}
            setComments={setComment}
            buttonDisable={accionInfo.apoyo_ciudadano}
            classNameNumberOfVotes="progress-bar-container"
            classNameButton={
              !accionInfo.apoyo_ciudadano
                ? "button-vote"
                : "button-vote-disable"
            }
            buttonText={
              !accionInfo.apoyo_ciudadano ? "Apoyar" : "Acción apoyada"
            }
            buttonAccion={() => {
              new Promise((resolve, reject) => {
                dispatch(Acciones.apoyarAccion(idAccion));
                resolve("Accion apoyada");
              }).then(() => {
                dispatch(Acciones.getOneAccion(idAccion));
              });
            }}
            buttonCommentAction={() => {
              dispatch(
                AccionesComentario.createComentarioComunicado({
                  comunicado: idAccion,
                  comentario: comment,
                })
              );
              setComment("");
            }}
            likeAction={(idComment) =>
              dispatch(AccionesComentario.voteComentarioComunicado(idComment))
            }
            unlikeAction={(idComment) =>
              dispatch(AccionesComentario.dislikeComentarioAccion(idComment))
            }
            responseAction={(padre) => {
              dispatch(
                AccionesComentario.responderComentarioAccion({
                  comunicado: Number(idAccion),
                  comentario: comment,
                  padre: padre,
                })
              );
              setComment("");
            }}
            editAction={(idComment, comentario) => {
              dispatch(
                AccionesComentario.editarComentarioAccion({
                  id: idComment,
                  body: { comentario: comentario, comunicado: idAccion },
                })
              );
            }}
            deleteAction={(idComment) => {
              dispatch(AccionesComentario.borrarComentarioAccion(idComment));
            }}
            refreshCommentsStates={refreshCommentsStates}
            setRefreshCommentsStates={setRefreshCommentsStates}
          />
        ) : null}
      </div>
    );
  };

  return (
    <div>
      {accionInfo && (
        <Helmet>
          <title>
          Participación Ciudadana |{" "}
            {`${
              accionInfo.titulo
                ? accionInfo.titulo
                : "Acciones del Participación Ciudadana"
            }`}
          </title>
          <meta property="url" content={window.location.href} />
          <meta property="type" content="article" />
          <meta
            property="title"
            content={
              accionInfo.titulo
                ? accionInfo.titulo
                : "Acciones del Participación Ciudadana"
            }
          />
          <meta
            property="description"
            content={
              accionInfo.quill_html
                ? outerHtmlToReadableString(accionInfo.quill_html)
                : "Participa y forma parte de una ciudadanía más activa"
            }
          />
          <meta
            property="image"
            content={
              GeneralUrlStatic+accionInfo.img
                ? GeneralUrlStatic+accionInfo.img
                : "https://app01.pwa.expandit.io/media/propuesta/9/image_brainstorming.jpg"
            }
          />
        </Helmet>
      )}
      {renderNavigation()}
      {renderProposalsInfoContent()}
      {/* {renderSubmitModal()} */}
    </div>
  );
};

export default AccionesInfo;
