export const shorten = (text, maxLength) => {
  if (text && text.length > maxLength) {
    return text.substr(0, maxLength) + "...";
  } else {
    return text;
  }
};


export const outerHtmlToReadableString = (htmlString) => {
  let workingHtmlString = htmlString;
  let i = 0;
  do {
    let inicioDeCorte = workingHtmlString.indexOf("<");
    let finDeCorte = workingHtmlString.indexOf(">", inicioDeCorte) + 1;
    let esTagCierre =
      workingHtmlString.slice(inicioDeCorte, finDeCorte)[1] === "/";
    workingHtmlString = workingHtmlString.replace(
      workingHtmlString.slice(inicioDeCorte, finDeCorte),
      esTagCierre ? " " : ""
    );
    i++;
  } while (workingHtmlString.indexOf("<") !== -1);
  return workingHtmlString;
};

export const checkBackground = (status) => {
  switch (status) {
    case "ER":
      return "#f7c137"; //elemento("amarillo", "En Revisión")
    case "B":
      return "#1387da"; //elemento("azul", "Borrador");
    case "A":
      return "#3bd278"; //elemento("verde", "Aprobado");
    case "I":
      return "#FF780B"; //elemento("naranja", "Inhabilitada");
    case "U":
      return "#f42449"; //elemento("rojo", "Urgente");
    case "T":
      return "#49505d"; //elemento("negro", "Terminado");
    case "NP":
      return "#d5deff"; //elemento("gris", "No Público");
    case "NL":
      return "#85ccff"; //elemento("azul-claro", "No Leido");
    default:
      return null;
  }
};

export const setStatus = (status) => {
  switch (status) {
    case "ER":
      return "En Revisión";
    case "B":
      return "Borrador";
    case "A":
      return "Aprobado";
    case "I":
      return "Inhabilitada";
    case "U":
      return "Urgente";
    case "T":
      return "Terminado";
    case "NP":
      return "No Público";
    case "NL":
      return "No Leido";
    default:
      return null;
  }
};

export const djangoParserDeFecha = (string) => {
  if (string) {
    let fecha = new Date(string.slice(0, 19));
    const nombreDeMes = (num) => {
      switch (num) {
        case 0:
          return "enero";
        case 1:
          return "febrero";
        case 2:
          return "marzo";
        case 3:
          return "abril";
        case 4:
          return "mayo";
        case 5:
          return "junio";
        case 6:
          return "julio";
        case 7:
          return "agosto";
        case 8:
          return "septiembre";
        case 9:
          return "octubre";
        case 10:
          return "noviembre";
        case 11:
          return "diciembre";
        default:
      }
    };
    return `${fecha.getDate()} de ${nombreDeMes(
      fecha.getMonth()
    )} de ${fecha.getFullYear()}`;
  }
};

// String con formato AAAA-MM-DD
export const fechaStringBasicoAFechaJS = (string) => {
  return new Date(
    Number(string.slice(0, 4)),
    Number(string.slice(5, 7)) - 1,
    Number(string.slice(8, 10))
  );
};

export const mesNumericoAMesNombre = (num) => {
  switch (num) {
    case 0:
      return "enero";
    case 1:
      return "febrero";
    case 2:
      return "marzo";
    case 3:
      return "abril";
    case 4:
      return "mayo";
    case 5:
      return "junio";
    case 6:
      return "julio";
    case 7:
      return "agosto";
    case 8:
      return "septiembre";
    case 9:
      return "octubre";
    case 10:
      return "noviembre";
    case 11:
      return "diciembre";
    default:
  }
};

export const fechaMilisegundosAFechaString = (milisegundos) => {
  let fecha = new Date(milisegundos);
  return `${fecha.getDate()} de ${mesNumericoAMesNombre(
    fecha.getMonth()
  )} del ${fecha.getFullYear()}`;
};

export const clicksInside = (arrayDeIds, callback) => {
  if ((arrayDeIds, callback)) {
    document.addEventListener("click", (event) => {
      if (arrayDeIds.every((id) => document.getElementById(id))) {
        if (
          arrayDeIds.every(
            (id) =>
              event.target.id !== id &&
              !document.getElementById(id).contains(event.target)
          )
        ) {
          callback();
        }
      }
    });
  }
};



