import React from "react";

import { InputGroup, FormControl } from "react-bootstrap";

const InputComponent = (props) => {
  return (
    <InputGroup>
      <InputGroup.Prepend>
        <InputGroup.Text className={props.classNameInputIcon} id="basic-addon1">
          <img src={props.srcIcon} />
        </InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        className={props.classNameInput}
        placeholder={props.placeholder}
        onChange={(event) => {
          if (event.target.value === "") {
            props.handleSearch(event.target.value);
          }
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            props.handleSearch(event.target.value);
          }
        }}
      />
    </InputGroup>
  );
};

export default InputComponent;
