import * as types from "../../consts/actionTypes";

export const getAcciones = (payload) => {
  return {
    type: types.GET_ACCIONES,
    payload,
  };
};

export const getOneAccion = (payload) => {
  console.log("Solicitando accion...");
  return {
    type: types.GET_ONE_ACCION,
    payload,
  };
};

export const apoyarAccion = (payload) => {
  console.log("Apoyando accion...");
  return {
    type: types.APOYAR_ACCION,
    payload,
  };
};

export const accionSuccessFlagToFalse = () => {
  return {
    type: types.ACCION_SUCCESS_FLAG_TO_FALSE,
  };
};



