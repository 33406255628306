import React from "react";
import { Form } from "react-bootstrap";

import ButtonComponent from "./ButtonComponent";

const WriteCommentComponent = (props) => {
    return (
        <div className={props.className}>
            <Form.Control
                as="textarea"
                placeholder={props.placeholder}
                rows={props.rows}
                value={props.value ? props.value : ""}
                onChange={props.changeAction ? (event) => props.changeAction(event.target.value) : ""}
            />
            <ButtonComponent
                classNameImgAfter={props.classNameImgAfter}
                classNameButton={props.classNameButton}
                isHaveIconAfter={true}
                srcAfter={props.srcAfter}
                buttonText={props.buttonText}
                classNameLink={props.classNameLink}
                to={props.to}
                onClick={ props.value ? props.buttonAccion ? props.buttonAccion : () => console.log("se envia el comentario") : null}
            />
        </div>
    );
};

export default WriteCommentComponent;
