import React, { useState } from "react";
import { Helmet } from "react-helmet";
import CardFullImage from "../../components/card-proposals/CardFullImage/CardFullImage";
import ProposalsNavigation from "../../components/proposals-navigation/ProposalsNavigation";
import { Container, Pagination, Col } from "react-bootstrap";
// import { fakeProyectos } from "./fakeProyectos";
import { useEffect } from "react";
import * as Actions from "../../redux/actions/proyectoActions";
import * as Selector from "../../redux/selectors/proyectoSelectors";
import { useDispatch, useSelector } from "react-redux";
import * as GAservices from "../../redux/services/GoogleAnalyticsServices";
import { djangoParserDeFecha, shorten } from "../../utils/ComponentUtils";

const Proyectos = () => {
  const dispatch = useDispatch();
  const proyectos = useSelector((state) => Selector.proyectos(state));
  const [arrayIndicesFiltrados, setArrayIndicesFiltrados] = useState(false);

  //tamaño de la pagina
  const [tamanoPagina] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const handleSearch = (stringDeBusqueda) => {
    let busqueda = stringDeBusqueda;
    setPage(1);
    if (busqueda) {
      busqueda = busqueda.toLowerCase();
      busqueda = busqueda.trimEnd();
      let palabrasDeBusqueda = busqueda.split(" ");
      let proyectosMatch = [];
      let proyectosNoMatch = [];
      palabrasDeBusqueda.forEach((palabra, index1) => {
        proyectos.forEach((proyecto, index2) => {
          if (
            proyecto.nombre.toLowerCase().match(palabra) ||
            djangoParserDeFecha(proyecto.fecha_creacion).toLowerCase().match(palabra) ||
            proyecto.etiquetas.some((etiqueta) =>
              etiqueta.nombre.toLowerCase().match(palabra)
            )
          ) {
            if (
              !proyectosMatch.includes(index2) &&
              !proyectosNoMatch.includes(index2)
            ) {
              proyectosMatch.push(index2);
            }
          } else {
            proyectosNoMatch.push(index2);
            if (proyectosMatch.includes(index2)) {
              proyectosMatch.splice(proyectosMatch.indexOf(index2), 1);
            }
          }
        });
      });
      setArrayIndicesFiltrados(proyectosMatch);
    } else {
      setArrayIndicesFiltrados(false);
    }
  };

  console.log(proyectos);
  const renderProposalNavigation = () => {
    return (
      <ProposalsNavigation
        paragraph="Inicio"
        title="Proyectos"
        goBackRoute="/"
        isHaveSearchInput={true}
        handleSearch={handleSearch}
      />
    );
  };

  useEffect(() => {
    dispatch(Actions.getProyectos());
    GAservices.GApageView("Proyectos");
  }, []);

  return (
    <div>
      <Helmet>
        <title>Participación Ciudadana | Proyectos</title>
      </Helmet>
      {renderProposalNavigation()}
      <Container fluid>
        {proyectos
          ? proyectos.filter((proyecto) => proyecto.disponibilidad==="1")
              .sort(
                (a, b) =>
                  new Date(b.fecha_creacion) - new Date(a.fecha_creacion)
              )
              .slice((page - 1) * tamanoPagina, tamanoPagina * page)
              .map((proy, key) => {
                if (
                  Array.isArray(arrayIndicesFiltrados)
                    ? arrayIndicesFiltrados.includes(key)
                    : true
                ) {
                  return (
                    <CardFullImage
                      idProy={proy.id}
                      img={proy.img}
                      title={shorten(proy.nombre, 150)}
                      tags={proy.etiquetas}
                      date={proy.fecha_creacion}
                      area={"Ayuntamiento"}
                      description={proy.descripcion}
                      cardHeight={"23em"}
                      key={key}
                    />
                  );
                }
              })
              .filter((proyecto) => proyecto !== undefined)
          : null}

        {proyectos.length > 0 ? (
          <Col style={{ justifyContent: "flex-end", display: "flex" }}>
            <Pagination>
              <Pagination.Prev
                disabled={page === 1}
                onClick={() => setPage(page - 1)}
              />
              <Pagination.Item>{page}</Pagination.Item>
              <Pagination.Next
                disabled={page * tamanoPagina >= proyectos.length}
                onClick={() => setPage(page + 1)}
              />
            </Pagination>
          </Col>
        ) : null}
      </Container>
    </div>
  );
};

export default Proyectos;
