export default function (mensaje) {
  let string = mensaje.join(". ");
  string = string.replace(
    "This field is required.",
    "Este campo es necesario."
  );
  string = string.replace(
    "Given token not valid for any token type",
    "La sesión ha expirado"
  );
  string = string.replace(
    "Token is invalid or expired",
    "La sesión ha expirado"
  );
  string = string.replace(
    "This password is too common.",
    "Esta contraseña es muy común"
  );
  string = string.replace(
    "This password is entirely numeric.",
    "Esta contraseña solo contiene números"
  );
  string = string.replace(
    "A user is already registered with that username",
    "Un usuario ya se encuentra registrado con ese RUN"
  );
  string = string.replace(
    "A user is already registered with this e-mail address.",
    "Un usuario ya se encuentra registrado con ese correo electronico"
  );
  return string;
}

