import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall, token } from "../api/pwa_api";
import * as Services from "../services/authServices";
import * as types from "../../consts/actionTypes";

export function* cambiarPasswordSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "sso-client/password-change/",
      payload,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Contraseña cambiada satisfactoriamente");
    yield put({ type: types.CAMBIAR_PASSWORD_SUCCESS, results });
  } catch (e) {
    console.log("Error al cambiar contraseña: ", e);
    yield put({ type: types.CAMBIAR_PASSWORD_ERROR, payload });
  }
}

export function* getAvataresSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "sso-client/users-avatar/",
      payload,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );
    console.log("Avatares solicitados satisfactoriamente");
    yield put({ type: types.GET_AVATARES_SUCCESS, results });
  } catch (e) {
    console.log("Error al solicitar avatares: ", e);
    yield put({ type: types.GET_AVATARES_ERROR, payload });
  }
}

export function* actualizarAvatarSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "sso-client/info/",
      payload,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Avatar actualizado satisfactoriamente");
    yield put({ type: types.ACTUALIZAR_AVATAR_SUCCESS, results });
  } catch (e) {
    console.log("Error al actualizar avatar: ", e);
    yield put({ type: types.ACTUALIZAR_AVATAR_ERROR, payload });
  }
}

export function* cambiarEmailSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `sso-client/users-catalog/${payload.id}/`,
      payload.body,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "PUT"
    );
    console.log("Email actualizado satisfactoriamente");
    yield put({ type: types.CAMBIAR_EMAIL_SUCCESS, results });
  } catch (e) {
    console.log("Error al actualizar email: ", e);
    yield put({ type: types.CAMBIAR_EMAIL_ERROR, payload });
  }
}


export function* toggleNotificacionesEmailSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "sso-client/info/",
      payload,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "PUT"
    );
    console.log("Notificaciones de email cambiadas satisfactoriamente");
    yield put({ type: types.TOGGLE_NOTIFICACIONES_EMAIL_SUCCESS, results });
  } catch (e) {
    console.log("Error al cambiar notificaciones de email: ", e);
    yield put({ type: types.TOGGLE_NOTIFICACIONES_EMAIL_ERROR, payload });
  }
}

export default function* accionesSaga() {
  yield takeLatest(types.CAMBIAR_PASSWORD, cambiarPasswordSaga);
  yield takeLatest(types.GET_AVATARES, getAvataresSaga);
  yield takeLatest(types.ACTUALIZAR_AVATAR, actualizarAvatarSaga);
  yield takeLatest(types.CAMBIAR_EMAIL, cambiarEmailSaga);
  yield takeLatest(types.TOGGLE_NOTIFICACIONES_EMAIL, toggleNotificacionesEmailSaga);
}
