import React, { useState, useCallback } from "react";
import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/core";
import {
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
} from "@material-ui/core/";
import { useDispatch } from "react-redux";
import { setMessages } from "../../../redux/actions/messages";

const useStyles = makeStyles(() => ({
  label: {
    marginLeft: 0,
    marginRight: 0,
    border: "1px solid  var(--searchForm-section-color)",
    borderRadius: "5px",
    marginBottom: "20px",
    width: "127px",
    boxShadow: "0px 5px 25px rgba(0, 0, 0, 0.1)",
  },
  button: {
    width: "127px",
    marginBottom: "20px",
  },
}));

const Questionnaire = ({ personInfo, onAnswer }) => {
  const [value, setValue] = useState("excelente");
  const dispatch = useDispatch();
  const classes = useStyles();

  const { id } = personInfo;

  const handleSendAnswer = useCallback(() => {
    dispatch(setMessages({ message: value, id: String(id) }));
    if (onAnswer) {
      onAnswer();
    }
  }, [dispatch, id, onAnswer, value]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend"></FormLabel>
      <RadioGroup
        aria-label="gender"
        name="gender1"
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel
          className={classes.label}
          value="Rojo"
          control={<Radio color="primary" />}
          label="Rojo"
        />
        <FormControlLabel
          className={classes.label}
          value="Azul"
          control={<Radio color="primary" />}
          label="Azul"
        />
        <FormControlLabel
          className={classes.label}
          value="Amarillo"
          control={<Radio color="primary" />}
          label="Amarillo"
        />
      </RadioGroup>

      <Button
        style={{ textTransform: "none" }}
        onClick={handleSendAnswer}
        className={classes.button}
        variant="contained"
        color="primary"
      >
        Continuar
      </Button>
    </FormControl>
  );
};

export default Questionnaire;
