import React from "react";

import { InputGroup, FormControl } from "react-bootstrap";

const InputComponent = ({ etiquetas, setEtiquetas}) => {
  const handleTags = (string, key) => {
    let contenidoInput = document.getElementById(
      "input-etiquetas-crear-propuesta"
    ).value;
    if (
      (key === " " || key === "Enter") &&
      contenidoInput != "" &&
      etiquetas.length < 10
    ) {
      let arr = [...etiquetas];
      string.trim();
      arr.push(string);
      setEtiquetas(arr);
      document.getElementById("input-etiquetas-crear-propuesta").value = "";
    } else if (string === "" && key === "Backspace" && etiquetas.length > 0) {
      let arr = [...etiquetas];
      arr.pop();
      setEtiquetas(arr);
    } else if (etiquetas.length >= 10) {
      document.getElementById("input-etiquetas-crear-propuesta").value = "";
    }
  };
  return (
    <div style={{padding: "0 15px"}}>  {/*MUST FIX solo estilo*/}
      <label htmlFor="input-nombre-de-encuesta" className="label-filtros">
        Tags
      </label>
      <div id="div-etiquetas-crear-propuesta" className="input-filtros">
        <div id="div-solo-etiquetas-crear-propuesta">
          {etiquetas &&
            etiquetas.map((etiqueta) => (
              <p className="etiquetas-propuesta">{etiqueta}</p>
            ))}
        </div>
        <InputGroup>
          <FormControl
            type="text"
            name="input-nombre-de-encuesta"
            id="input-etiquetas-crear-propuesta"
            onKeyDown={(event) => handleTags(event.target.value, event.key)}
          />
        </InputGroup>
      </div>
    </div>
  );
};

export default InputComponent;
