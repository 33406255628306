import axios from "axios";
import Swal from "sweetalert2";
import { store } from "../store";
import { noLoadingUrls, noErrorAlertUrls } from "./exceptionsUrl";
import baseUrl from "../../consts/GeneralUrl"
import TraductorDeErrores from "./TraductorDeErrores"

export const token = localStorage.getItem("access");


axios.interceptors.request.use(
  (request) => {
    for (let eUrl of noLoadingUrls) {
      if (request.url === baseUrl + eUrl) {
        return request;
      }
    }

    store.dispatch({
      type: "GET_LOADING",
      payload: true,
    });
    return request;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  function (response) {
    store.dispatch({
      type: "GET_LOADING",
      payload: false,
    });
    return response;
  },
  function (error) {
    store.dispatch({
      type: "GET_LOADING",
      payload: false,
    });
    if (
      !noErrorAlertUrls.some((eUrl) => error.request.config.url === baseUrl + eUrl)
    ) {
      Swal.fire({
        title: "Error!",
        text: TraductorDeErrores(Object.values(error.response.data)[0]),
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }

    return Promise.reject(error);
  }
);

export const apiCall = (url, data, headers, method) =>
  axios({ method, url: baseUrl + url, data, headers });
