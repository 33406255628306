import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall, token } from "../api/pwa_api";
import * as Services from "../services/authServices";
import * as types from "../../consts/actionTypes";

export function* createPropuestasComentarioSaga({ payload }) {
  try {
    const comentarioPropuesta = yield call(
      apiCall,
      "propuesta/comentarios/",
      payload,
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "POST"
    );
    console.log("Comentario enviado", payload);
    yield put({
      type: types.POST_CREATE_PROPUESTAS_COMMENTS_SUCCESS,
      comentarioPropuesta,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: types.POST_REQUEST_ERROR, error });
  }
}

export function* votePropuestasCommentSaga({ payload }) {
  try {
    const voteComentarioPropuesta = yield call(
      apiCall,
      "propuesta/votos-comentarios/",
      { voto: "U", comentario: payload },
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "POST"
    );
    yield put({
      type: types.POST_VOTE_PROPUESTAS_COMMENTS_SUCCESS,
      voteComentarioPropuesta,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: types.POST_REQUEST_ERROR, error });
  }
}

export function* eliminatePropuestasVoteCommentSaga({ payload }) {
  try {
    const deleteVotePropuesta = yield call(
      apiCall,
      "propuesta/votos-comentarios/",
      payload,
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "DELETE"
    );
    yield put({
      type: types.DELETE_VOTE_PROPUESTAS_COMMENTS_SUCCESS,
      deleteVotePropuesta,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: types.DELETE_REQUEST_ERROR, error });
  }
}

export function* updatePropuestasVoteCommentSaga({ payload }) {
  try {
    const updateVotePropuesta = yield call(
      apiCall,
      "propuesta/votos-comentarios/",
      payload,
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "PUT"
    );
    yield put({
      type: types.PUT_VOTE_PROPUESTAS_COMMENTS_SUCCESS,
      updateVotePropuesta,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: types.PUT_REQUEST_ERROR, error });
  }
}

export function* createPropuestasRespuestaComentarioSaga({ payload }) {
  try {
    const respuestaComentarioPropuesta = yield call(
      apiCall,
      "propuesta/comentarios/",
      payload,
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "POST"
    );
    yield put({
      type: types.POST_CREATE_PROPUESTAS_RESPUESTA_SUCCESS,
      respuestaComentarioPropuesta,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_REQUEST_ERROR, error });
  }
}

export function* dislikeComentarioPropuestaSaga({ payload }) {
  try {
    const result = yield call(
      apiCall,
      "propuesta/votos-comentarios/",
      { voto: "D", comentario: payload },
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "POST"
    );
    yield put({ type: types.DISLIKE_COMENTARIO_PROPUESTA_SUCCESS, result });
  } catch (error) {
    console.log(error);
    yield put({ type: types.DISLIKE_COMENTARIO_PROPUESTA_ERROR, error });
  }
}

export function* reponderComentarioPropuestaSaga({ payload }) {
  try {
    const result = yield call(
      apiCall,
      "propuesta/comentarios/",
      payload,
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "POST"
    );
    console.log("Comentario respondido");
    yield put({ type: types.RESPONDER_COMENTARIO_PROPUESTA_SUCCESS, result });
  } catch (error) {
    console.log("Error al responder comentario", error);
    yield put({ type: types.RESPONDER_COMENTARIO_PROPUESTA_ERROR, error });
  }
}

export function* editComentarioPropuestaSaga({ payload }) {
  try {
    const result = yield call(
      apiCall,
      `propuesta/comentarios/${payload.id}/`,
      payload.body,
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "PUT"
    );
    yield put({ type: types.EDIT_COMENTARIO_PROPUESTA_SUCCESS, result });
  } catch (error) {
    console.log(error);
    yield put({ type: types.EDIT_COMENTARIO_PROPUESTA_ERROR, error });
  }
}

export function* borrarComentarioPropuestaSaga({ payload }) {
  try {
    const result = yield call(
      apiCall,
      `propuesta/comentarios/${payload}/`,
      null,
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "DELETE"
    );
    yield put({ type: types.DELETE_COMENTARIO_PROPUESTA_SUCCESS, result });
  } catch (error) {
    console.log(error);
    yield put({ type: types.DELETE_COMENTARIO_PROPUESTA_ERROR, error });
  }
}

//COMUNICADOS

export function* createComunicadosComentarioSaga({ payload }) {
  try {
    const comentarioComunicado = yield call(
      apiCall,
      "comunicados/comentario/",
      payload,
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "POST"
    );
    yield put({
      type: types.POST_CREATE_COMUNICADOS_COMMENTS_SUCCESS,
      comentarioComunicado,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: types.POST_REQUEST_ERROR, error });
  }
}

export function* voteComunicadosCommentSaga({ payload }) {
  try {
    const votoComentarioComunicado = yield call(
      apiCall,
      "comunicados/votos-comentarios/",
      { voto: "U", comentario: payload },
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "POST"
    );
    yield put({
      type: types.POST_VOTE_COMUNICADOS_COMMENTS_SUCCESS,
      votoComentarioComunicado,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: types.POST_REQUEST_ERROR, error });
  }
}

export function* eliminateComunicadosVoteCommentSaga({ payload }) {
  try {
    const votoEliminadoComunicado = yield call(
      apiCall,
      "comunicados/votos-comentarios/",
      payload,
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "DELETE"
    );
    yield put({
      type: types.DELETE_VOTE_COMUNICADOS_COMMENTS_SUCCESS,
      votoEliminadoComunicado,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: types.DELETE_REQUEST_ERROR, error });
  }
}

export function* updateComunicadosVoteCommentSaga({ payload }) {
  try {
    const votoActualizadoComunicado = yield call(
      apiCall,
      "comunicados/votos-comentarios/",
      payload,
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "PUT"
    );
    yield put({
      type: types.PUT_VOTE_COMUNICADOS_COMMENTS_SUCCESS,
      votoActualizadoComunicado,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: types.PUT_REQUEST_ERROR, error });
  }
}

export function* createComunicadosRespuestaComentarioSaga({ payload }) {
  try {
    const respuestaComentarioComunicado = yield call(
      apiCall,
      "comunicados/comentario/",
      payload,
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "POST"
    );
    yield put({
      type: types.POST_CREATE_COMUNICADOS_RESPUESTA_SUCCESS,
      respuestaComentarioComunicado,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_REQUEST_ERROR, error });
  }
}

export function* dislikeComentarioAccionSaga({ payload }) {
  try {
    const result = yield call(
      apiCall,
      "comunicados/votos-comentarios/",
      { voto: "D", comentario: payload },
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "POST"
    );
    yield put({ type: types.DISLIKE_COMENTARIO_ACCION_SUCCESS, result });
  } catch (error) {
    console.log(error);
    yield put({ type: types.DISLIKE_COMENTARIO_ACCION_ERROR, error });
  }
}

export function* reponderComentarioAccionSaga({ payload }) {
  try {
    const result = yield call(
      apiCall,
      "comunicados/comentario/",
      payload,
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "POST"
    );
    yield put({ type: types.RESPONDER_COMENTARIO_ACCION_SUCCESS, result });
  } catch (error) {
    console.log(error);
    yield put({ type: types.RESPONDER_COMENTARIO_ACCION_ERROR, error });
  }
}

export function* editComentarioAccionSaga({ payload }) {
  try {
    const result = yield call(
      apiCall,
      `comunicados/comentario/${payload.id}/`,
      payload.body,
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "PUT"
    );
    yield put({ type: types.EDIT_COMENTARIO_ACCION_SUCCESS, result });
  } catch (error) {
    console.log(error);
    yield put({ type: types.EDIT_COMENTARIO_ACCION_ERROR, error });
  }
}

export function* borrarComentarioAccionSaga({ payload }) {
  try {
    const result = yield call(
      apiCall,
      `comunicados/comentario/${payload}/`,
      null,
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "DELETE"
    );
    yield put({ type: types.DELETE_COMENTARIO_PROPUESTA_SUCCESS, result });
  } catch (error) {
    console.log(error);
    yield put({ type: types.DELETE_COMENTARIO_PROPUESTA_ERROR, error });
  }
}

export default function* comentarioSaga() {
  yield takeLatest(
    types.POST_CREATE_PROPUESTAS_COMMENTS,
    createPropuestasComentarioSaga
  );
  yield takeLatest(
    types.POST_VOTE_PROPUESTAS_COMMENTS,
    votePropuestasCommentSaga
  );
  yield takeLatest(
    types.DELETE_VOTE_PROPUESTAS_COMMENTS,
    eliminatePropuestasVoteCommentSaga
  );
  yield takeLatest(
    types.PUT_VOTE_PROPUESTAS_COMMENTS,
    updatePropuestasVoteCommentSaga
  );

  yield takeLatest(
    types.POST_CREATE_COMUNICADOS_COMMENTS,
    createComunicadosComentarioSaga
  );
  yield takeLatest(
    types.POST_VOTE_COMUNICADOS_COMMENTS,
    voteComunicadosCommentSaga
  );
  yield takeLatest(
    types.PUT_VOTE_COMUNICADOS_COMMENTS,
    updateComunicadosVoteCommentSaga
  );
  yield takeLatest(
    types.DELETE_VOTE_COMUNICADOS_COMMENTS,
    eliminateComunicadosVoteCommentSaga
  );
  yield takeLatest(
    types.DISLIKE_COMENTARIO_PROPUESTA,
    dislikeComentarioPropuestaSaga
  );
  yield takeLatest(
    types.DISLIKE_COMENTARIO_ACCION,
    dislikeComentarioAccionSaga
  );
  yield takeLatest(
    types.RESPONDER_COMENTARIO_PROPUESTA,
    reponderComentarioPropuestaSaga
  );
  yield takeLatest(
    types.RESPONDER_COMENTARIO_ACCION,
    reponderComentarioAccionSaga
  );
  yield takeLatest(
    types.EDIT_COMENTARIO_PROPUESTA,
    editComentarioPropuestaSaga
  );
  yield takeLatest(
    types.EDIT_COMENTARIO_ACCION,
    editComentarioAccionSaga
  );
  yield takeLatest(
    types.DELETE_COMENTARIO_PROPUESTA,
    borrarComentarioPropuestaSaga
  );
  yield takeLatest(
    types.DELETE_COMENTARIO_ACCION,
    borrarComentarioAccionSaga
  );
}
