import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from "redux-devtools-extension";

// Reducers que seran los encargados de manejar la data de la aplicación
import rootReducer from './reducers/rootReducers';
// en cargado de controlar los side effects o api calls
import rootSaga from './sagas/rootSagas';
import {REDUCER_EXCEPTIONS} from '../consts/actionTypes'

const validateToken = (store) => (next) => (action) => {
    const isReducerInExcepts = REDUCER_EXCEPTIONS.includes(action.type);
    if (isReducerInExcepts) {
        next(action)
    } else {
        console.log("Ingresando al Middleware...")
        new Promise((resolve, reject) =>{
            store.dispatch({
                type: "VERIFY_TOKEN",
                payload: {
                    store, next, action,
                    from: `intercept redux call: ${JSON.stringify(next)}`
                }
            })
            setTimeout(function(){
                resolve(next(action));
            }, 1500)
        })
        
    }
}

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(validateToken),
        applyMiddleware(sagaMiddleware)
        
    )
);

// if (localStorage.getItem('access')) {

//     store.dispatch({
//       type: 'LOGIN_SUCCESS', tokens: {
//         data:{
//             access: localStorage.access,
//             refresh: localStorage.refresh
//         }
//       }
//     });
//   }

const configureStore = () => {

    // crea el  redux-saga middleware
    // const sagaMiddleware = createSagaMiddleware();
    return {
        ...store,
    //     ...createStore(
    //         rootReducer,
    //         applyMiddleware(sagaMiddleware)
    //     ),
        // run index sagas saga: Function: a Generator function
        runSaga: sagaMiddleware.run(rootSaga, {})
    };
};

export default configureStore; 