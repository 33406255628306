import React, { useEffect, useState } from "react";

import { Formik } from "formik";
import { Form } from "react-bootstrap";

import {
  AREA_CONSTANTS,
  ANTIQUITY_CONSTANTS,
} from "../constants/DropdownConstants";

import {
  FORM_FILTERS_SCHEMA,
  FORM_FILTERS_INITIAL_VALUES,
} from "../../validations/FormValidations";

import TagInputComponent from "./TagInputComponent";
import LabelAndDropdown from "./form-elements/LabelAndDropdown";
import DateComponent from "./form-elements/DateComponent";

const FiltersContentDialog = (props) => {
  const [area, setArea] = useState(
    props.filtros ? (props.filtros.area ? props.filtros.area : null) : null
  );
  const [etiquetas, setEtiquetas] = useState(
    props.filtros
      ? props.filtros.etiquetas
        ? props.filtros.etiquetas
        : []
      : []
  );
  const [antiguedad, setAntiguedad] = useState(
    props.filtros
      ? props.filtros.antiguedad
        ? props.filtros.antiguedad
        : 0
      : 0
  );
  const [inicio, setInicio] = useState(
    props.filtros
      ? props.filtros.inicio
        ? props.filtros.inicio
        : new Date(Date.now())
      : null
  );
  const [fin, setFin] = useState(
    props.filtros
      ? props.filtros.fin
        ? props.filtros.fin
        : new Date(Date.now())
      : null
  );

  useEffect(()=>{
    if(props.filtros === false){
      setArea(null);
      setEtiquetas([]);
      setAntiguedad(0);
      setInicio(null);
      setFin(null);
    }
  },[props.filtros])

  const onDateChange = (date, setFieldValue, values) => {
    setFieldValue("publicationDate", date);
  };

  return (
    <div>
      <Formik
        validationSchema={FORM_FILTERS_SCHEMA}
        initialValues={FORM_FILTERS_INITIAL_VALUES()}
        onSubmit={(e) => {
          e.preventDefault()
          props.setFiltros({
            area: area,
            etiquetas: etiquetas,
            antiguedad: antiguedad,
            inicio: inicio,
            fin: fin,
          });
        }}
      >
        {({ handleSubmit, handleChange, values, setFieldValue }) => (
          <Form className="" onSubmit={handleSubmit}>
            {props.filterHasArea && (
              <LabelAndDropdown
                classNameLabel="filters-label"
                labelName="Área"
                placeholder={
                  props.filtros.area ? props.filtros.area : "Selecciones área"
                }
                setExternalState={setArea}
                searchWord="área"
                options={AREA_CONSTANTS}
              />
            )}
            <TagInputComponent
              classNameLabel="filters-label"
              labelName="Etiquetas"
              placeholder="Presione la tecla Enter para separar las etiquetas"
              etiquetas={etiquetas}
              setEtiquetas={setEtiquetas}
              searchWord="etiquetas"
            />
            <LabelAndDropdown
              classNameLabel="filters-label"
              labelName="Antigüedad"
              placeholder={
                props.filtros.antiguedad
                  ? props.filtros.antiguedad + " días"
                  : "Más recientes"
              }
              setExternalState={setAntiguedad}
              searchWord="antiquity"
              options={ANTIQUITY_CONSTANTS}
              // value={antiguedad}  MUST FIX
            />
            {antiguedad === "personalizado" && (
              <div>
                <DateComponent
                  classNameLabel="filters-label"
                  controlId="publicationDate"
                  labelName="Fecha de inicio"
                  selected={inicio}
                  name="publicationDate"
                  onDateChange={setInicio}
                />
                <DateComponent
                  classNameLabel="filters-label"
                  controlId="publicationDate"
                  labelName="Fecha fin"
                  selected={fin}
                  name="publicationDate"
                  onDateChange={setFin}
                />
              </div>
            )}

            {/* MUST FIX */}
            <div className="div-botones-filtros">
              <button
                className="boton-borrar-filtros"
                onClick={(e) => {
                  e.preventDefault()
                  props.setFiltros(false);
                  // props.onClickHide()
                }}
              >
                Borrar filtros
              </button>
              <button
                className="boton-aplicar-filtros"
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  props.setFiltros({
                    area: area,
                    etiquetas: etiquetas,
                    antiguedad: antiguedad,
                    inicio: inicio,
                    fin: fin,
                  });
                }}
              >
                Filtrar
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default FiltersContentDialog;
