import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ProposalsNavigation from "../../components/proposals-navigation/ProposalsNavigation";
import CardVotaciones from "../../components/card-proposals/CardVotaciones/CardVotaciones";
import { Tabs, Tab, Pagination, Col } from "react-bootstrap";
import { fakeVotaciones } from "./fakeVotaciones";
import { useDispatch, useSelector } from "react-redux";
import * as Selector from "../../redux/selectors/votacionSelector";
import * as Actions from "../../redux/actions/votacionActions";
import * as GAservices from "../../redux/services/GoogleAnalyticsServices";
import moment from "moment";

const Votaciones = () => {
  const dispatch = useDispatch();
  let encuestas = useSelector((state) => Selector.encuestas(state));
  const [filtroEncuestas, setFiltroEncuestas] = React.useState(encuestas);

  //tamaño de la pagina
  const [tamanoPagina, setTamanoPagina] = React.useState(10);
  const [page, setPage] = React.useState(1);

  useEffect(() => {
    dispatch(Actions.getEncuestas());
    GAservices.GApageView("Votaciones");
  }, []);

  useEffect(() => {
    setFiltroEncuestas(encuestas);
  }, [encuestas, setFiltroEncuestas]);

  const handleSearch = (stringDeBusqueda) => {
    setPage(1);
    let busqueda = stringDeBusqueda;
    setFiltroEncuestas(
      encuestas.filter((req) =>
        busqueda
          .toLowerCase()
          .split(" ")
          .every((v) => conditionalFilter(v, req))
      )
    );
  };

  const conditionalFilter = (v, req) => {
    for (const prop in req) {
      if (req[prop] !== null && typeof req[prop] !== []) {
        if (req[prop].toString().toLowerCase().includes(v)) {
          return true;
        }
      }
    }
  };

  const renderProposalNavigation = () => {
    return (
      <ProposalsNavigation
        paragraph="Inicio"
        title="Votaciones Ciudadanas"
        isHaveFilterModal={false}
        isHaveSearchInput={true}
        handleSearch={handleSearch}
        goBackRoute="/"
      />
    );
  };

  const calculaDiasRestantes = (stringFechaFin) => {
    // let fechaFin = Date.parse(
    //   new Date(
    //     stringFechaFin.slice(0, 4),
    //     stringFechaFin.slice(5, 7),
    //     stringFechaFin.slice(8, 10)
    //   )
    // );
    // let fechaActual = new Date(Date.now());
    // if (fechaFin > fechaActual) {
    //   console.log({PRUEBA:Math.ceil((fechaFin - fechaActual) / 1000 / 60 / 60 / 24)})
    //   return Math.ceil((fechaFin - fechaActual) / 1000 / 60 / 60 / 24);
    // } else {
    //   return 0;
    // }
    let fechaFin = moment(stringFechaFin, "YYYY-MM-DD")
    let fechaActual=moment().format("YYYY-MM-DD")
    if(fechaFin.isAfter(fechaActual)){
      return fechaFin.diff(fechaActual,"days")

    }else{
      return 0
    }
  };

  console.log(
    filtroEncuestas.slice((page - 1) * tamanoPagina, tamanoPagina * page),
    filtroEncuestas.filter(
      (votacion) =>
        calculaDiasRestantes(votacion.fecha_finalizacion) === 0 &&
        votacion.preguntas.length > 0 &&
        votacion.estado === "Activa"
    )
  );

  return (
    <div>
      {" "}
      <Helmet>
        <title>Participación Ciudadana | Votaciones</title>
      </Helmet>
      {renderProposalNavigation()}
      <div style={{ margin: "0em 1em" }}>
        <Tabs defaultActiveKey="open" className="tabs-votaciones">
          <Tab eventKey="open" title="Abiertas">
            <div style={{ backgroundColor: "white" }}>
              {filtroEncuestas
                .slice((page - 1) * tamanoPagina, tamanoPagina * page)
                .map((votacion, key) => {
                  if (
                    calculaDiasRestantes(votacion.fecha_finalizacion) > 0 &&
                    votacion.preguntas.length > 0 &&
                    votacion.estado === "Activa"
                  ) {
                    return (
                      <CardVotaciones
                        key={key}
                        image={"https://"+votacion.imagen}
                        title={votacion.nombre}
                        questions={votacion.preguntas}
                        scope={"Ayuntamiento"}
                        idEncuesta={votacion.id}
                        timeRemaining={calculaDiasRestantes(
                          votacion.fecha_finalizacion
                        )}
                        answered={votacion.contestada}
                      />
                    );
                  }
                })
                .filter((votacion) => votacion !== undefined)}
            </div>
            {filtroEncuestas.length > 0 ? (
              <Col style={{ justifyContent: "flex-end", display: "flex" }}>
                <Pagination>
                  <Pagination.Prev
                    disabled={page === 1}
                    onClick={() => setPage(page - 1)}
                  />
                  <Pagination.Item>{page}</Pagination.Item>
                  <Pagination.Next
                    disabled={
                      page * tamanoPagina >=
                      filtroEncuestas.filter(
                        (votacion) =>
                          calculaDiasRestantes(votacion.fecha_finalizacion) >
                            0 &&
                          votacion.preguntas.length > 0 &&
                          votacion.estado === "Activa"
                      ).length
                    }
                    onClick={() => setPage(page + 1)}
                  />
                </Pagination>
              </Col>
            ) : null}
          </Tab>
          <Tab eventKey="finish" title="Terminadas">
            <div style={{ backgroundColor: "white" }}>
              {filtroEncuestas
                .slice((page - 1) * tamanoPagina, tamanoPagina * page)
                .map((votacion, key) => {
                  if (
                    calculaDiasRestantes(votacion.fecha_finalizacion) === 0 &&
                    votacion.preguntas.length > 0 &&
                    votacion.estado === "Activa"
                  ) {
                    return (
                      <CardVotaciones
                        key={key}
                        image={"https://"+votacion.imagen}
                        title={votacion.nombre}
                        questions={votacion.preguntas}
                        scope={"Ayuntamiento"}
                        idEncuesta={votacion.id}
                        timeRemaining={null}
                        answered={votacion.contestada}
                        terminada={true}
                      />
                    );
                  }
                })
                .filter((votacion) => votacion !== undefined)}
            </div>
            {encuestas.length > 0 ? (
              <Col style={{ justifyContent: "flex-end", display: "flex" }}>
                <Pagination>
                  <Pagination.Prev
                    disabled={page === 1}
                    onClick={() => setPage(page - 1)}
                  />
                  <Pagination.Item>{page}</Pagination.Item>
                  <Pagination.Next
                    disabled={
                      page * tamanoPagina >=
                      filtroEncuestas.filter(
                        (votacion) =>
                          calculaDiasRestantes(votacion.fecha_finalizacion) ===
                            0 &&
                          votacion.preguntas.length > 0 &&
                          votacion.estado === "Activa"
                      ).length
                    }
                    onClick={() => setPage(page + 1)}
                  />
                </Pagination>
              </Col>
            ) : null}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Votaciones;
