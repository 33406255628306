import {
  faThLarge,
  faChartLine,
  faClone,
  faCommentAlt,
  faUserMd,
  faHandPaper,
  faInbox,
  faNewspaper,
  faBullhorn,
  faExchangeAlt,
  faCheckCircle,
  faUsers,
  faClipboardCheck,
  faUniversity,
  faImages,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

const routes = [
  {
    name: "MENÚ",
    children: [
      {
        name: "Inicio",
        path: "/",
        icon: faThLarge,
      },
      {
        name: "Conversaciones",
        path: "/conversaciones",
        icon: faCommentAlt,
      },
      {
        name: "Propuestas ciudadanas",
        path: "/propuestas",
        icon: faHandPaper,
      },
      {
        name: "Mis propuestas",
        path: "/mis-propuestas",
        icon: faImages,
      },
      {
        // name: 'Campañas',
        name: "Proyectos",
        path: "/proyectos",
        icon: faNewspaper,
      },

      {
        // name: 'Comunicados',
        name: "Acciones",
        path: "/acciones",
        icon: faBullhorn,
      },
      {
        name: "Votaciones",
        path: "/votaciones",
        icon: faClipboardCheck,
      },
    //   {
    //     name: "Contacta con SIAC",
    //     path: "/mis-solicitudes",
    //     icon: faInbox,
	  // },
	  // {
    //     name: "Tutoriales",
    //     path: "",
    //     icon: faQuestionCircle,
    //   },
    ],
  },
];

export default routes;
