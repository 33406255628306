import React, { Component, useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";

import ProposalsNavigation from "../components/proposals-navigation/ProposalsNavigation";
import CardInfoComponent from "../components/card-proposals/CardInfoComponent";
import BadgeComponent from "../components/elements/BadgeComponent";
import ProgressBarComponent from "../components/elements/ProgressBarComponent";
import WriteCommentComponent from "../components/elements/WriteCommentComponent";
import CommentComponent from "../components/elements/CommentComponent";
import SocialMediaComponent from "../components/elements/SocialMediaComponent";

import { BADGES } from "../components/constants/ProposalsConstants";

import userIcon from "../assets/images/user-icon.png";
import pointsVertical from "../assets/icons/points-vertical.png";
import infoImage from "../assets/images/Rectangle 153.png";
import { COMENTARIOS } from "../components/constants/ProposalsConstants";
import commentPurpleIcon from "../assets/icons/comment-purple.png";
import commentWhite from "../assets/icons/comment-white.png";
import IconoCheckCirculoRojo from "../assets/icons/check-circulo-rojo.svg";
import IconoCheckBlanco from "../assets/icons/check-blanco.svg";
import IconoLikeBlanco from "../assets/icons/like-blanco.svg";
import Xroja from "../assets/icons/x-roja.svg";
import DialogModalComponent from "../components/elements/DialogModalComponent";
import ConfirmationContent from "../components/elements/form-elements/ConfirmationContent";

import * as Actions from "../redux/actions/propuestaActions";
import * as Selector from "../redux/selectors/propuestaSelector";
import * as UserSelectors from "../redux/selectors/rootSelectors";
import * as ComentariosSelectors from "../redux/selectors/comentarioSelector";

import "./propuestasInfo.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as ActionsComment from "../redux/actions/comentarioActions";
import { outerHtmlToReadableString } from "../utils/ComponentUtils";
import GeneralUrlStatic from "../consts/GeneralUrl";

import Swal from "sweetalert2";

const MyProposalsInfo = () => {
  const { idPropuesta } = useParams();
  const dispatch = useDispatch();
  const propuestaResult = useSelector((state) => Selector.onePropuesta(state));
  const apoyosMaximos = useSelector((state) =>
    Selector.apoyosMaximosResult(state)
  );
  const user = useSelector((state) => UserSelectors.user(state));
  const comentarioSuccessFlag = useSelector((state) =>
    ComentariosSelectors.comentarioSuccessFlag(state)
  );

  const successFlag = useSelector((state) => Selector.successFlagResult(state));

  const [comment, setComment] = useState();
  const [refreshCommentsStates, setRefreshCommentsStates] = useState(false);
  const [showBanner, setShowBanner] = useState(true);

  useEffect(() => {
    dispatch(Actions.getOnePropuesta(idPropuesta));
  }, [idPropuesta]);

  useEffect(() => {
    dispatch(Actions.getApoyosMaximos());
  }, [propuestaResult]);

  useEffect(() => {
    if (comentarioSuccessFlag) {
      dispatch(Actions.getOnePropuesta(idPropuesta));
      setRefreshCommentsStates(comentarioSuccessFlag);
      dispatch(ActionsComment.ComentarioSuccessFlagToFalse());
    }
  }, [comentarioSuccessFlag]);

  useEffect(() => {
    if (successFlag) {
      dispatch(Actions.getOnePropuesta(idPropuesta));
      dispatch(Actions.successFlagToFalse());
    }
  }, [successFlag]);

  const renderBadges = (badges) => {
    return badges.map((badge) => {
      return (
        <BadgeComponent className="badge-component-style" text={badge.nombre} />
      );
    });
  };

  const renderProgressBar = (apoyos, max) => {
    return <ProgressBarComponent now={apoyos} max={max} />;
  };

  const renderWriteComment = () => {
    return (
      <WriteCommentComponent
        className="write-comment-div"
        rows="1"
        placeholder="Enscribe un comentario ..."
        classNameButton="button-vote"
        buttonText="Comentar"
        srcAfter={commentWhite}
        classNameImgAfter="proposals-image-after-style custom-icon-style"
        classNameLink="custom-write-comment-style"
        // to=""
      />
    );
  };

  const renderComment = (comentarios) => {
    console.log(comentarios);
    comentarios.map((cm, key) => {
      return (
        <CommentComponent
          src={userIcon}
          iconSrc={pointsVertical}
          user="ASDF12345678"
          date={cm.fecha}
          content={cm.comentario}
        />
      );
    });
  };

  const renderSocialMediaComponent = () => {
    let hashtags = "";
    if (propuestaResult && Array.isArray(propuestaResult.etiquetas)) {
      propuestaResult.etiquetas.forEach((etiqueta, index) => {
        if (index === 0) {
          hashtags = hashtags + etiqueta.nombre;
        } else {
          hashtags = hashtags + "," + etiqueta.nombre.trim();
        }
      });
    }
    return (
      <SocialMediaComponent
        classNameSocialMediaDiv="social-media-div"
        classNameSocialMediaIcons="social-media-icons"
        twitterHashtags={hashtags}
        titulo={
          propuestaResult
            ? "Ven y apoya la propuesta ciudadana: " + propuestaResult.titulo
            : "Ven y apoya la propuesta ciudadana"
        }
      />
    );
  };

  const renderNavigation = () => {
    return (
      <ProposalsNavigation
        paragraph="Regresar a mis propuestas"
        title={"Propuesta: " + idPropuesta}
        isHaveSearchInput={false}
        isHaveButton={
          propuestaResult && propuestaResult.status === "B" ? true : false
        }
        textButton="Editar Propuesta"
        routeForButton={`/mis-propuestas-editar/${idPropuesta}`}
        goBackRoute={"/mis-propuestas"}
      />
    );
  };

  const renderSubmitModal = () => {
    return (
      <DialogModalComponent
        classNameShowButton="button-vote custom-style-new-proposal-button"
        classNameBody="confirmation-style-modal"
        showButtonText="Continuar"
        type="Submit"
        hidden={true}
        show={false}
        onClickHide={() => {
          // setState({ show: false })
        }}
      >
        <ConfirmationContent
          classNameDiv="confirmation-div-style"
          classNamePar1="confirmation-par1-style"
          par1="Propuesta Ciudadana"
          par2="Propuesta apoyada con éxito"
          onClickAccept={() => {
            // setState({ show: false }
          }}
        />
        {/* <DialogModalComponent
                showButtonText="Aceptar"
              	onClickShow={onClickShowSimilar}
				show={state.showSimilar}
				onClickHide={onClickHideSimilar}>
                <p>Second Modal</p>
            </DialogModalComponent> */}
      </DialogModalComponent>
    );
  };

  const likeComment = (idComment) => {
    // const payload = {propuesta: idPropuesta, comentario: comment}
    // new Promise((resolve, reject) =>{
    // 	dispatch(ActionsComment.createComentarioPropuesta(payload))
    // 	resolve(resolve(dispatch(Actions.getOnePropuesta(idPropuesta))))
    // })
  };

  const renderBanner = () => {
    return (
      <div className="banner-rojo-propuestas">
        <img src={IconoCheckCirculoRojo} alt="check rojo" />
        <div>
          <p>
            Estimada ciudadanía, hemos procedido al cierre de esta propuesta. Lo
            que origina el cierre de la misma corresponde a las siguientes
            circunstancias o a alguna de ellas por sí sola:
          </p>
          <br />
          <ul>
            <li>
              <p>
                La propuesta no es competencia de..... Haremos todo lo que esté en nuestra mano para
                derivarla a las Instituciones u Organismos competentes.
              </p>
            </li>
            <li>
              <p>
                La propuesta es contraria a la ley y, por tanto, no podemos
                atenderla.
              </p>
            </li>
          </ul>
          <br />
          <p>
            Lamentamos los inconvenientes que esto haya podido ocasionar,
            invitándoles a que sigan participando con nosotros.
          </p>
        </div>
        <img
          src={Xroja}
          alt="cerrar banner"
          onClick={() => setShowBanner(false)}
        />
      </div>
    );
  };

  const renderProposalsInfoContent = () => {
    return (
      <div className="proposals-info-content">
        <CardInfoComponent
          idUsuarioLogged={user ? user.user.id : null}
          classNameTitle="card-big-title"
          title={propuestaResult.titulo}
          classNameSubtitle="subtitle-style"
          subtitle={propuestaResult.fecha_publicacion}
          comuna={propuestaResult.comuna_destino}
          juntaVecinal={propuestaResult.junta_vecinal}
          isHaveVoteSection={propuestaResult.status === "A"}
          badges={renderBadges(
            propuestaResult.etiquetas !== undefined
              ? propuestaResult.etiquetas
              : []
          )}
          classNameText="card-text-style"
          text={propuestaResult.contenido}
          src={
            propuestaResult.imagen
              ? GeneralUrlStatic + propuestaResult.imagen.substring(1)
              : false
          }
          // src={
          //   propuestaResult.imagen
          //     ?  propuestaResult.imagen
          //     : false
          // }
          isHaveCommentsSection={true}
          secondTitle="Progreso"
          progressBar={renderProgressBar(propuestaResult.apoyos, apoyosMaximos)}
          classNameNumberOfVotes="progress-bar-container"
          numberOfVotes={
            apoyosMaximos
              ? `${propuestaResult.apoyos}/${apoyosMaximos} apoyos`
              : ""
          }
          classNameButton={
            !propuestaResult.apoyo_ciudadano
              ? "button-vote"
              : "button-vote-disable"
          }
          buttonText={
            !propuestaResult.apoyo_ciudadano ? "Apoyar" : "Propuesta Apoyada"
          }
          supportIcon={
            !propuestaResult.apoyo_ciudadano
              ? IconoCheckBlanco
              : IconoLikeBlanco
          }
          buttonDisable={propuestaResult.apoyo_ciudadano}
          writeComment={renderWriteComment()}
          classNameCommentTitle="comment-title-style"
          iconCommentSrc={commentPurpleIcon}
          commentTitle="Comentarios"
          comentarios={
            propuestaResult.comentarios ? propuestaResult.comentarios : []
          }
          socialMedia={renderSocialMediaComponent()}
          buttonAccion={() => {
            new Promise((resolve, reject) => {
              dispatch(Actions.supportPropuesta(idPropuesta));
              resolve("Accion apoyada");
            });
          }}
          comment={comment}
          setComments={setComment}
          buttonCommentAction={() => {
            dispatch(
              ActionsComment.createComentarioPropuesta({
                propuesta: idPropuesta,
                comentario: comment,
              })
            );
            setComment("");
          }}
          likeAction={(idComment) =>
            dispatch(ActionsComment.voteComentarioPropuesta(idComment))
          }
          unlikeAction={(idComment) =>
            dispatch(ActionsComment.dislikeComentarioPropuesta(idComment))
          }
          responseAction={(padre) => {
            dispatch(
              ActionsComment.responderComentarioPropuesta({
                propuesta: Number(idPropuesta),
                comentario: comment,
                padre: padre,
              })
            );
            setComment("");
          }}
          editAction={(idComment, comentario) => {
            dispatch(
              ActionsComment.editarComentarioPropuesta({
                id: idComment,
                body: { comentario: comentario, propuesta: idPropuesta },
              })
            );
          }}
          deleteAction={(idComment) => {
            dispatch(ActionsComment.borrarComentarioPropuesta(idComment));
          }}
          refreshCommentsStates={refreshCommentsStates}
          setRefreshCommentsStates={setRefreshCommentsStates}
        />
      </div>
    );
  };

  return (
    <div>
      {propuestaResult && (
        <Helmet>
          <title>
            Participación Ciudadana |{" "}
            {`${
              propuestaResult
                ? propuestaResult.titulo
                : "Propuestas ciudadanas de Participación Ciudadana"
            }`}
          </title>
          <meta property="url" content={window.location.href} />
          <meta property="type" content="article" />
          <meta
            property="title"
            content={
              propuestaResult
                ? propuestaResult.titulo
                  ? propuestaResult.titulo
                  : "Propuestas ciudadanas de Participación Ciudadana"
                : "Propuestas ciudadanas de Participación Ciudadana"
            }
          />
          <meta
            property="description"
            content={
              propuestaResult.contenido
                ? outerHtmlToReadableString(propuestaResult.contenido)
                : "Participa y forma parte de una ciudadanía más activa"
            }
          />
          <meta
            property="image"
            content={
              propuestaResult.imagen
                ? propuestaResult.imagen
                : "https://app01.pwa.expandit.io/media/propuesta/9/image_brainstorming.jpg"
            }
          />
        </Helmet>
      )}
      {user.user.id === propuestaResult.usuario ? renderNavigation() : null}
      {showBanner &&
        (propuestaResult.status === "NP" || propuestaResult.status === "I") &&
        renderBanner()}
      {renderProposalsInfoContent()}
      {renderSubmitModal()}
    </div>
  );
};

export default MyProposalsInfo;
