import * as types from "../../consts/actionTypes";

// buscar y obtener la informacion

export const login = (payload) => {
  return {
    type: types.LOGIN,
    payload: payload,
  };
};

export const loginSuccess = (payload) => {
  return {
    type: types.LOGIN_SUCCESS,
    payload,
  };
};

export const loginError = (payload) => {
  return {
    type: types.LOGIN_ERROR,
    payload: payload,
  };
};

export const verifyToken = (payload) => {
  return {
    type: types.VERIFY_TOKEN,
    payload,
  };
};

export const validToken = (payload) => {
  return {
    type: types.VALID_TOKEN,
    payload,
  };
};

export const refreshToken = (payload) => {
  return {
    type: types.REFRESH_TOKEN,
    payload,
  };
};

export const newAccessToken = (payload) => {
  return {
    type: types.REFRESH_TOKEN_SUCCESS,
    payload,
  };
};

export const registerUser = (payload) => {
  return {
    type: types.REGISTER,
    payload,
  };
};

export const registerReset = (payload) => {
  return {
    type: types.REGISTER_STATE_RESET,
    payload,
  };
};

export const verifyEmail = (payload) => {
  return {
    type: types.VERIFY_EMAIL,
    payload,
  };
}

export const logOut = () => {
  return { type: types.LOGOUT, payload: {} };
};

export const refreshUser = () => {
  console.log("Refrescando usuario");
  return { type: types.REFRESH_USER };
};

export const getPoliticasPrivacidad = () => {
  return {
    type: types.GET_POLITICAS_PRIVACIDAD,
  };
};

export const postAceptarPoliticas = (payload) => {
  console.log("Aceptando politicas...");
  return {
    type: types.POST_ACEPTA_POLITICAS_PRIVACIDAD,
    payload,
  };
};

export const postAceptarVideoTutorial = (payload) => {
  console.log("Aceptando video...");
  return {
    type: types.POST_ACEPTA_VIDEO_TUTORIAL,
    payload,
  };
};
