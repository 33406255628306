import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../api/pwa_api";
import * as Services from '../services/authServices'
import * as types from "../../consts/actionTypes";

export function* getTendenciasProyectoSaga({payload}){

    try {

    const tendenciasProyecto = yield call(
        apiCall,
        "tendencias/proyectos/",
        {},
        {
            Authorization: "Bearer " + Services.getAccessToken()
        },
        "GET"
    )
    yield put({ type: types.GET_TENDENCIAS_PROYECTOS_SUCCESS, tendenciasProyecto})
        
    } catch (error) {
        yield put({ type: types.GET_REQUEST_ERROR, error });
        console.log(error)
    }
}

export function* getProyectosSaga({payload}){
    try{
        const proyectos = yield call(
            apiCall, 
            "comunicados/campaigns/",
            {},
            {
                Authorization: "Bearer " + Services.getAccessToken()
            }
        )

        yield put({type: types.GET_PROYECTOS_SUCCESS, proyectos})

    }
    catch(error){
        console.log(error)
        yield put({ type: types.GET_REQUEST_ERROR, error });
    }
}

export function* getOneProyectoSaga({payload}){
    try{
        const proyecto = yield call(
            apiCall, 
            "comunicados/campaigns/" + payload,
            {},
            {
                Authorization: "Bearer " + Services.getAccessToken()
            }
        )

        yield put({type: types.GET_ONE_PROYECTO_SUCCESS, proyecto})
    }
    catch(error){
        console.log(error)
        yield put({ type: types.GET_REQUEST_ERROR, error });
    }
}

export default function* proyectoSagas(){
    yield takeLatest(types.GET_TENDENCIAS_PROYECTOS, getTendenciasProyectoSaga);
    yield takeLatest(types.GET_PROYECTOS, getProyectosSaga);
    yield takeLatest(types.GET_ONE_PROYECTO, getOneProyectoSaga);
}