import {
  put,
  call,
  takeLatest,
  takeEvery,
  fork,
} from "redux-saga/effects";
import { apiCall } from "../api/pwa_api";
import * as types from "../../consts/actionTypes";
import * as Services from "../services/authServices";
// import jwt_decode from 'jwt-decode'

// function* authFlowSaga() {

//   let accessToken = yield call(Services.getAccessToken)
//   // let userInfo = yield call()
//   let expires_in = 0

//   // try {
//   while (true) {
//     if (!accessToken) {
//       const { payload } = yield take(types.LOGIN)
//       accessToken = yield call(authorize, payload)
//       let userInfo = yield call(getUser,Services.getAccessToken())
//       // console.log(accessToken)
//     }

//     let userSignedOut
//     let currentTime = Date.now()/1000
//     console.log(jwt_decode(Services.getAccessToken()).exp > currentTime )
//     while (!userSignedOut && accessToken !== undefined) {
//       let { expired } = yield race({
//         expired: call(verifyToken, accessToken),
//         signout: take(types.LOGOUT)
//       })

//       if (expired === "INVALIDO") {
//         let refreshToken = yield call(Services.getRefreshToken)
//         accessToken = yield call(refresh, refreshToken)

//         if (!accessToken) {
//           userSignedOut = true
//           yield call(signout)
//         }
//       }
//       if (expired === "LOGOUT") {
//         userSignedOut = true
//         yield call(signout)
//       }
//     }
//   }
// }

// function* authorize(credentials) {
//   try {
//     const tokens = yield call(
//       apiCall,
//       "sso-client/login/",
//       {
//         username: credentials.username,
//         password: credentials.password,
//       },
//       {
//         "Content-Type": "application/json",
//       },
//       "POST"
//     );

//     console.log(tokens)
//     yield call(Services.setAccessToken, tokens.data.access)
//     yield call(Services.setRefreshToken, tokens.data.refresh)
//     yield put({ type: types.LOGIN_SUCCESS, tokens })
//     return tokens.data.access;
//   } catch (error) {
//     yield put({ type: types.LOGIN_ERROR, error });
//     console.log(error)
//   }
// }

// function* verifyToken(accessToken) {
//   const verify = yield call(
//     apiCall,
//     "sso-client/verify-token/",
//     {
//       token: accessToken
//     },
//     {
//       "Content-Type": "application/json",
//     },
//     "POST"
//   )

//   if (verify.data.token === "is valid") {
//     return "VALIDO"
//   }
//   else {
//     return "INVALIDO"
//   }

// }

// function* refresh(refreshToken) {
//   const newToken = yield call(
//     apiCall,
//     "sso-client/refresh/",
//     {
//       refresh_token: refreshToken
//     },
//     {
//       "Content-Type": "application/json",
//     },
//     "POST"
//   );
//   yield call(Services.setAccessToken, newToken.data.access)
//   yield put({ type: types.NEW_ACCESS_TOKEN, newToken });
//   return newToken.data.access
// }

// function* signout(error) {
//   yield call(Services.removeLocalStorageInfo)
//   yield put({ type: types.LOGIN_ERROR, error })
// }

// function* getUser(accessToken) {
//   console.log(accessToken)
//   try {
//     const userInfo = yield call(
//       apiCall,
//       "sso-client/info",
//       {},
//       {
//         "Authorization": "Bearer " + accessToken
//       },
//       "GET"
//     )

//     console.log(userInfo)

//     yield call(Services.setUser, userInfo.data[0])
//     yield put({type: types.GET_USER_INFO_SUCCESS, userInfo})
//     return userInfo.data

//   } catch (error) {
//     yield put({type: types.GET_REQUEST_ERROR, error})
//     console.log(error)
//   }

// }

// export default authFlowSaga

export function* login({ payload }) {
  try {
    const tokens = yield call(
      apiCall,
      "sso-client/login/",
      {
        username: payload.username,
        password: payload.password,
      },
      {
        "Content-Type": "application/json",
      },
      "POST"
    );

    yield call(Services.setAccessToken, tokens.data.access);
    yield call(Services.setRefreshToken, tokens.data.refresh);
    yield call(getUser, tokens.data.access);
    yield put({ type: types.LOGIN_SUCCESS, tokens });
  } catch (e) {
    yield put({ type: types.LOGIN_ERROR, e });
    console.log(e);
  }
}

export function* verifyToken({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "sso-client/verify-token/",
      {
        token: Services.getAccessToken(),
      },
      {
        "Content-Type": "application/json",
      },
      "POST"
    );
    console.log("Verficando ...");
    if (results.data.token === "is valid") {
      yield put({ type: types.VALID_TOKEN, results });
    } else {
      let refreshToken = yield call(Services.getRefreshToken);
      yield fork(refreshNewToken, refreshToken);
    }
  } catch (e) {
    yield put({ type: types.INVALID_TOKEN, e });
    console.log(e);
  }
}

export function* refreshNewToken(tokenRefresh) {
  console.log("refrescando...");
  try {
    const newToken = yield call(
      apiCall,
      "sso-client/refresh/",
      {
        refresh_token: Services.getRefreshToken(),
      },
      {
        "Content-Type": "application/json",
      },
      "POST",
      types.REFRESH_TOKEN
    );
    console.log(newToken.data.access);
    yield call(Services.setAccessToken, newToken.data.access);
    yield put({
      type: types.REFRESH_TOKEN_SUCCESS,
      access: newToken.data.access,
    });
  } catch (e) {
    yield put({ type: types.LOGOUT, e });
    console.log(e);
  }
}

function* getUser(accessToken) {
  console.log(accessToken);
  try {
    const userInfo = yield call(
      apiCall,
      "sso-client/info/",
      {},
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "GET"
    );

    console.log({userInfo});

    yield call(Services.setUser, userInfo.data[0]);
    yield put({ type: types.GET_USER_INFO_SUCCESS, userInfo });
  } catch (error) {
    yield put({ type: types.GET_REQUEST_ERROR, error });
    console.log(error);
  }
}

export function* updateUser({ payload }) {}

export function* registerUserSaga({ payload }) {
  try {
    const register = yield call(
      apiCall,
      "sso-client/register/",
      payload,
      {},
      "POST"
    );

    yield put({ type: types.REGISTER_SUCCESS, register });
  } catch (error) {
    yield put({ type: types.REGISTER_FAIL, error });
    console.log(error);
  }
}

function* refreshUserSaga() {
  try {
    const userInfo = yield call(
      apiCall,
      "sso-client/info/",
      {},
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "GET"
    );
    yield call(Services.setUser, userInfo.data[0]);
    yield put({ type: types.GET_USER_INFO_SUCCESS, userInfo });
  } catch (error) {
    yield put({ type: types.GET_REQUEST_ERROR, error });
    console.log(error);
  }
}

function* getPoliticasPrivacidadSaga() {
  try {
    const results = yield call(
      apiCall,
      "politicas/1/",
      null,
      {
        // Authorization: "Bearer " + Services.getAccessToken(),
      },
      "GET"
    );
    yield put({ type: types.GET_POLITICAS_PRIVACIDAD_SUCCESS, results });
  } catch (error) {
    yield put({ type: types.GET_POLITICAS_PRIVACIDAD_ERROR, error });
    console.log(error);
  }
}

export function* postAceptarPoliticasSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "sso-client/info/",
      { privacy_policy: "True" },
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Politicas de privacidad aceptadas satisfactoriamente");
    yield put({
      type: types.POST_ACEPTA_POLITICAS_PRIVACIDAD_SUCCESS,
      results,
    });
  } catch (e) {
    console.log("Error al aceptar las politicas de privacidad:", e);
    yield put({ type: types.POST_ACEPTA_POLITICAS_PRIVACIDAD_ERROR, payload });
  }
}

export function* postAceptarVideoTutorialSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "sso-client/info/",
      { video_tutorial: "True" },
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Video tutorial aceptado satisfactoriamente");
    yield put({
      type: types.POST_ACEPTA_VIDEO_TUTORIAL_SUCCESS,
      results,
    });
  } catch (e) {
    console.log("Error al aceptar video tutorial:", e);
    yield put({ type: types.POST_ACEPTA_VIDEO_TUTORIAL_ERROR, payload });
  }
}

export function* verifyEmailSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `sso-client/verify-email/`,
      { key: payload },
      {
        "Content-Type": "application/json",
      },
      "POST"
    );
    console.log("Email verificado satisfactoriamente");
    yield put({
      type: types.VERIFY_EMAIL_SUCCESS,
      results,
    });
  } catch (e) {
    console.log("Error al verificar email:", e);
    yield put({ type: types.VERIFY_EMAIL_ERROR, payload });
  }
}

export default function* authSaga() {
  yield takeLatest(types.LOGIN, login);
  yield takeEvery(types.VERIFY_TOKEN, verifyToken);
  yield takeLatest(types.REFRESH_TOKEN, refreshNewToken);
  yield takeLatest(types.REGISTER, registerUserSaga);
  yield takeLatest(types.REFRESH_USER, refreshUserSaga);
  yield takeLatest(types.GET_POLITICAS_PRIVACIDAD, getPoliticasPrivacidadSaga);
  yield takeLatest(
    types.POST_ACEPTA_POLITICAS_PRIVACIDAD,
    postAceptarPoliticasSaga
  );
  yield takeLatest(
    types.POST_ACEPTA_VIDEO_TUTORIAL,
    postAceptarVideoTutorialSaga
  );
  yield takeLatest(types.VERIFY_EMAIL, verifyEmailSaga);
}
