import React, { useContext, useEffect, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";

import { PrivateRoute } from "../routers/PrivateRoute";
//import { PublicRoute } from "../routers/PublicRoute";
import Login from "../pages/Login/Login";
import ConversationsPage from "../pages/conversations";

import * as Selector from "../redux/selectors/rootSelectors";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";
import { drawerWidth } from "../components/sidebar/sidebar";

import { verifyToken, refreshToken } from "../redux/actions/userActions";

// const Conversations = React.lazy(() =>
//   import("../components/Conversations/Conversations")
// );
// const Propuestas = React.lazy(() =>
//   import("../pages/propuestas")
// );
// const ProposalsInfo = React.lazy(() =>
//   import("../pages/propuestasInfo")
// );
// const MyProposals = React.lazy(() =>
//   import("../pages/misPropusestas")
// );
// const MyProposalsInfo = React.lazy(() =>
//   import("../pages/misPropuestasInfo")
// );
// const CreateProposal = React.lazy(() =>
//   import("../pages/nuevaPropuesta")
// );
// const MyRequests = React.lazy(() =>
//   import("../pages/misSolicitudes")
// );
// const MyRequestsInfo = React.lazy(() =>
//   import("../pages/misSolicitudesInfo")
// );
// const solicitudesPage = React.lazy(() =>
//   import("../pages/solicitudes")
// );
// const Inicio = React.lazy(() =>
//   import("../pages/Inicio/Inicio")
// );
// const Registro = React.lazy(() =>
//   import("../pages/Registro/Registro")
// );
// const Acciones = React.lazy(() =>
//   import("../pages/Acciones/Acciones")
// );
// const AccionesInfo = React.lazy(() =>
//   import("../pages/Acciones/AccionesInfo")
// );
// const Proyectos = React.lazy(() =>
//   import("../pages/Proyectos/Proyectos")
// );
// const DetalleProyectos = React.lazy(() =>
//   import("../pages/Proyectos/DetalleProyectos")
// );
// const Votaciones = React.lazy(() =>
//   import("../pages/Votaciones/Votaciones")
// );
// const VotacionQuiz = React.lazy(() =>
//   import("../pages/Votaciones/VotacionQuiz/VotacionQuiz")
// );
// const Estadisticas = React.lazy(() =>
//   import("../pages/Votaciones/Estadisticas/index")
// );
// const SuccessVote = React.lazy(() =>
//   import("../pages/Votaciones/VotacionQuiz/SuccessVote")
// );
// const Configuracion = React.lazy(() =>
//   import("../pages/Configuracion/Configuracion")
// );
// const LoadingGeneral = React.lazy(() =>
//   import("../components/LoadingGeneral")
// );
// const Notificaciones = React.lazy(() =>
//   import("../pages/Notifications/Notificaciones")
// );
// const Busqueda = React.lazy(() =>
//   import("../pages/Busqueda/index")
// );
import ScrollToTop from "./ScrollToTop";
import Conversations from "../components/Conversations/Conversations";
import Propuestas from "../pages/propuestas";
import ProposalsInfo from "../pages/propuestasInfo";
import MyProposals from "../pages/misPropusestas";
import MyProposalsInfo from "../pages/misPropuestasInfo";
import CreateProposal from "../pages/nuevaPropuesta";
import MyRequests from "../pages/misSolicitudes";
import MyRequestsInfo from "../pages/misSolicitudesInfo";
import solicitudesPage from "../pages/solicitudes";
import Inicio from "../pages/Inicio/Inicio";
import Registro from "../pages/Registro/Registro";
import Acciones from "../pages/Acciones/Acciones";
import AccionesInfo from "../pages/Acciones/AccionesInfo";
import Proyectos from "../pages/Proyectos/Proyectos";
import DetalleProyectos from "../pages/Proyectos/DetalleProyectos";
import Votaciones from "../pages/Votaciones/Votaciones";
import VotacionQuiz from "../pages/Votaciones/VotacionQuiz/VotacionQuiz";
import Estadisticas from "../pages/Votaciones/Estadisticas/index";
import SuccessVote from "../pages/Votaciones/VotacionQuiz/SuccessVote";
import Configuracion from "../pages/Configuracion/Configuracion";
import LoadingGeneral from "../components/LoadingGeneral";
import Notificaciones from "../pages/Notifications/Notificaciones";
import Busqueda from "../pages/Busqueda/index";
import Recuperacion from "../pages/Recuperacion/Recuperacion";
import Restablecer from "../pages/Recuperacion/Restablecer";
import NotFound from "../pages/NotFound";
import VerificarCorreo from "../pages/VerificarCorreo/VerificarCorreo";

const appStyles = makeStyles((theme) => ({
  drawerContent: {
    minHeight: "100vh",
    width: "100%",
    height: "auto",
    paddingTop: "72px",
    paddingLeft: drawerWidth,
    backgroundColor: "#fbfbff",
    paddingBottom: "3em",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
      marginLeft: 0,
    },
  },
  footer: {
    zIndex: 5,
    backgroundColor: "#011C41",
    color: "#FFFFFF",
    padding: "1em",
    textAlign: "center",
  },
}));

export const AppRouter = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => Selector.user(state));
  const token = localStorage.getItem("access");
  const tokenRefresh = localStorage.getItem("refresh");
  const isValid = useSelector((state) => Selector.isValid(state));

  const classes = appStyles();

  const verifyTokenTrue = async () => {
    dispatch(verifyToken(token));
    if (!isValid) {
      dispatch(refreshToken(tokenRefresh));
    }
  };

  const loading = () => (
    <div className="animated fadeIn pt-3 text-center">Loading...</div>
  );

  useEffect(() => {
    // if(token !== "" && token !== null )
    // verifyTokenTrue()
  });

  return (
    <Router>
      <Suspense fallback={loading}>
        <LoadingGeneral />
        <ScrollToTop />
        <Switch>
          <Route exact path="/login" name="Login" component={Login} />
          <Route
            exact
            path="/registro"
            name="El señor Registro"
            component={Registro}
          />
          <Route
            exact
            path="/recuperacion"
            name="Recuperacion"
            component={Recuperacion}
          />
          <Route
            exact
            path="/restablecer/:noUse/:uid/:token"
            name="Restablecer"
            component={Restablecer}
          />
          <Route
            exact
            path="/verificar-correo/:verificationCode"
            name="Verificar Correo"
            component={VerificarCorreo}
          />
          <PrivateRoute
            exact
            path="/busqueda/:query"
            name="Busqueda"
            component={Busqueda}
          />
          <PrivateRoute
            path="/"
            exact
            name="Propuestas"
            component={Inicio}
            isAuthenticated={true}
          />
          <PrivateRoute
            path="/propuestas"
            exact
            name="Propuestas"
            component={Propuestas}
            isAuthenticated={true}
          />
          <PrivateRoute
            path="/propuestas/:idPropuesta"
            exact
            name="Propuesta"
            component={ProposalsInfo}
            isAuthenticated={true}
          />
          <PrivateRoute
            path="/mis-propuestas"
            exact
            name="Mis Propuestas"
            component={MyProposals}
            isAuthenticated={true}
          />
          <PrivateRoute
            path="/mis-propuestas/:idPropuesta"
            exact
            name="Mi Propuesta"
            component={MyProposalsInfo}
            isAuthenticated={true}
          />
          <PrivateRoute
            path="/mis-propuestas-crear"
            exact
            name="Crear Propuesta"
            component={CreateProposal}
            isAuthenticated={true}
          />
          <PrivateRoute
            path="/mis-propuestas-editar/:idPropuesta"
            exact
            name="Editar Propuesta"
            component={CreateProposal}
            isAuthenticated={true}
          />
          <PrivateRoute
            path="/mis-solicitudes"
            exact
            name="Mis Solicitudes"
            component={MyRequests}
            isAuthenticated={true}
          />
          <PrivateRoute
            path="/mis-solicitudes/1"
            exact
            name="Mi Solicitud"
            component={MyRequestsInfo}
            isAuthenticated={true}
          />
          <PrivateRoute
            path="/solicitudes"
            exactname="Solicitudes"
            component={solicitudesPage}
            isAuthenticated={true}
          />
          <PrivateRoute
            path="/conversaciones/:id"
            name="Conversaciones"
            isAuthenticated={true}
            component={Conversations}
          >
            {/* <Conversations /> */}
          </PrivateRoute>
          <PrivateRoute
            path="/conversaciones"
            exact
            name="Conversaciones"
            isAuthenticated={true}
            component={Conversations}
          >
            {/* <Conversations /> */}
          </PrivateRoute>
          <PrivateRoute
            path="/acciones"
            exact
            name="Acciones"
            component={Acciones}
            isAuthenticated={true}
          />
          <PrivateRoute
            path="/acciones/:idAccion"
            exact
            name="Acciones"
            component={AccionesInfo}
            isAuthenticated={true}
          />
          <PrivateRoute
            path="/proyectos"
            exact
            name="Proyectos"
            component={Proyectos}
            isAuthenticated={true}
          />
          <PrivateRoute
            path="/proyectos/:idProyecto"
            exact
            name="Proyectos"
            component={DetalleProyectos}
            isAuthenticated={true}
          />
          <PrivateRoute
            path="/votaciones"
            exact
            name="Votaciones"
            component={Votaciones}
            isAuthenticated={true}
          />
          <PrivateRoute
            path="/votaciones/:idEncuesta"
            exact
            name="Votaciones"
            component={VotacionQuiz}
            isAuthenticated={true}
          />
          <PrivateRoute
            path="/votaciones/estadisticas/:idEncuesta"
            exact
            name="Votaciones"
            component={Estadisticas}
            isAuthenticated={true}
          />
          <PrivateRoute
            path="/vote/success/:idEncuesta"
            exact
            name="Votaciones"
            component={SuccessVote}
            isAuthenticated={true}
          />
          <PrivateRoute
            path="/configuracion"
            exact
            name="Configuracion"
            component={Configuracion}
            isAuthenticated={true}
          />
          <PrivateRoute
            path="/notificaciones"
            exact
            name="Notificaciones"
            component={Notificaciones}
            isAuthenticated={true}
          />
          {user && isValid && (
            <PrivateRoute
              component={NotFound}
              name="404"
              isAuthenticated={true}
            />
          )}
          <Redirect to="/login" />
        </Switch>
      </Suspense>
    </Router>
  );
};
