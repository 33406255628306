import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Container, Pagination, Col } from "react-bootstrap";
import "./acciones.css";
import "../Proyectos/Proyectos.css";
//Components
import ProposalsNavigation from "../../components/proposals-navigation/ProposalsNavigation";
import CardComunicados from "../../components/card-proposals/CardComunicados";
import BadgeComponent from "../../components/elements/BadgeComponent";
import SocialMediaComponent from "../../components/elements/SocialMediaComponent";
//Constants
import { BADGES } from "../../components/constants/ProposalsConstants";
import { fakeActions } from "./fakeAcciones";
import { useDispatch, useSelector } from "react-redux";
import * as AccionesDispatch from "../../redux/actions/accionActions";
import * as Selector from "../../redux/selectors/acccionSelector";
import * as GAservices from "../../redux/services/GoogleAnalyticsServices";
import baseUrl from "../../consts/GeneralUrlStatic";

import {
  shorten,
  outerHtmlToReadableString,
  djangoParserDeFecha,
} from "../../utils/ComponentUtils";

const renderBadges = (etiquetas) => {
  return etiquetas.map((badge) => {
    return (
      <BadgeComponent className="badge-component-style" text={badge.nombre} />
    );
  });
};

const renderSocialMediaComponent = (paramForPath, accion) => {
  let hashtags = "";
  accion.etiquetas.forEach((etiqueta, index) => {
    if (index === 0) {
      hashtags = hashtags + etiqueta.nombre;
    } else {
      hashtags = hashtags + "," + etiqueta.nombre.trim();
    }
  });
  return (
    <SocialMediaComponent
      classNameSocialMediaDiv="social-media-div social-media-centered-text"
      classNameSocialMediaIcons="social-media-icons social-media-margin"
      paramForPath={paramForPath ? paramForPath : false}
      twitterHashtags={hashtags}
      titulo={
        accion.titulo
          ? "Ven y apoya la acción de Participación Ciudadana: " + accion.titulo
          : "Ven y apoya la acción de Participación Ciudadana"
      }
    />
  );
};

const renderComunicadosCard = (item) => {
  return (
    <CardComunicados
      to={`/acciones/${item.id}`}
      classnameLink="link-styles"
      title={item.titulo}
      subtitle={item.fecha}
      area={item.usuario.area !== "Sn" && item.usuario.area}
      numComentarios={item.comentarios.length}
      encuesta={
        item.encuesta && item.encuesta.estado === "1" ? item.encuesta : null
      }
      description={shorten(outerHtmlToReadableString(item.quill_html), 500)}
      imageCard={item.img ? baseUrl+ item.img : false}
      badges={renderBadges(item.etiquetas)}
      classNameSocialMedia="socialMedia"
      socialMedia={renderSocialMediaComponent(`/${item.id}`, item)}
    />
  );
};

const Acciones = () => {
  const dispatch = useDispatch();
  const [arrayIndicesFiltrados, setArrayIndicesFiltrados] = useState(false);
  const [filtros, setFiltros] = useState(false);

  const acciones = useSelector((state) => Selector.acciones(state));

  //tamaño de la pagina
  const [tamanoPagina, setTamanoPagina] = useState(12);
  const [page, setPage] = useState(1);

  console.log(filtros);

  const filterFunction = (elemento) => {
    let area = true;
    let etiquetas = true;
    let antiguedad = true;
    if (filtros === false) {
      return true;
    }
    if (elemento) {
      if (filtros.area) {
        if (filtros.area === elemento.usuario.area) {
          area = true;
        } else {
          area = false;
        }
      }
      if (
        filtros.etiquetas && elemento.etiquetas
          ? filtros.etiquetas.length > 0
          : false
      ) {
        if (
          filtros.etiquetas.every((etiq) =>
            elemento.etiquetas.some(
              (etiq2) =>
                etiq2.nombre.toLowerCase().trim() === etiq.toLowerCase().trim() //MUST FIX
            )
          )
        ) {
          etiquetas = true;
        } else {
          etiquetas = false;
        }
      }
      if (filtros.antiguedad > 0 && typeof filtros.antiguedad === "number") {
        if (
          new Date(elemento.fecha) >
          new Date(Date.now() - filtros.antiguedad * 24 * 60 * 60 * 1000)
        ) {
          antiguedad = true;
        }
      } else if (filtros.antiguedad === 0) {
        antiguedad = true;
      } else if (filtros.antiguedad === "personalizado") {
        if (
          new Date(elemento.fecha) >= filtros.inicio && //MUST FIX
          new Date(elemento.fecha) <= filtros.fin
        ) {
          antiguedad = true;
        } else {
          antiguedad = false;
        }
      } else {
        antiguedad = false;
      }
      if (area && etiquetas && antiguedad) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleSearch = (stringDeBusqueda) => {
    let busqueda = stringDeBusqueda;
    if (busqueda) {
      setPage(1);
      busqueda = busqueda.toLowerCase();
      busqueda = busqueda.trimEnd();
      let palabrasDeBusqueda = busqueda.split(" ");
      let propuestasMatch = [];
      let propuestasNoMatch = [];
      palabrasDeBusqueda.forEach((palabra, index1) => {
        acciones.forEach((propuesta, index2) => {
          if (
            propuesta.titulo.toLowerCase().match(palabra) ||
            djangoParserDeFecha(propuesta.fecha).toLowerCase().match(palabra) ||
            propuesta.etiquetas.some((etiqueta) =>
              etiqueta.nombre.toLowerCase().match(palabra)
            )
          ) {
            if (
              !propuestasMatch.includes(index2) &&
              !propuestasNoMatch.includes(index2)
            ) {
              propuestasMatch.push(index2);
            }
          } else {
            propuestasNoMatch.push(index2);
            if (propuestasMatch.includes(index2)) {
              propuestasMatch.splice(propuestasMatch.indexOf(index2), 1);
            }
          }
        });
      });
      setArrayIndicesFiltrados(propuestasMatch);
    } else {
      setArrayIndicesFiltrados(false);
    }
  };

  useEffect(() => {
    dispatch(AccionesDispatch.getAcciones());
    GAservices.GApageView("Acciones");
  }, []);

  useEffect(() => {
    if (filtros) {
      setPage(1);
    }
  }, [filtros]);

  console.log(acciones);

  const renderComunicadosNavigation = () => {
    return (
      <ProposalsNavigation
        paragraph="Inicio"
        title="Acciones"
        isHaveFilterModal={true}
        isHaveSearchInput={true}
        filterHasArea={true}
        handleSearch={handleSearch}
        setFiltros={setFiltros}
        filtros={filtros}
        goBackRoute={"/"}
      />
    );
  };

  const disabledNext = () => {
    let num = Array.isArray(arrayIndicesFiltrados)
      ? arrayIndicesFiltrados.length
      : acciones.length;
    console.log(page * tamanoPagina);
    console.log(num);
    return page * tamanoPagina >= num;
  };

  return (
    <div>
      <Helmet>
        <title>Participación Ciudadana | Acciones</title>
      </Helmet>
      {renderComunicadosNavigation()}
      <Container fluid>
        {acciones
          ? acciones
              .sort((a, b) => new Date(b.fecha) - new Date(a.fecha))
              .slice((page - 1) * tamanoPagina, tamanoPagina * page)
              .map((item, key) => {
                if (
                  item.status !== "I" &&
                  item.status !== "B" &&
                  item.status !== "I" &&
                  item.status !== "NP" &&
                  item.status !== "ER" &&
                  filterFunction(item) &&
                  (Array.isArray(arrayIndicesFiltrados)
                    ? arrayIndicesFiltrados.includes(key)
                    : true)
                ) {
                  return renderComunicadosCard(item);
                }
              })
          : null}
        {acciones.length > 0 ? (
          <Col style={{ justifyContent: "flex-end", display: "flex" }}>
            <Pagination>
              <Pagination.Prev
                disabled={page === 1}
                onClick={() => setPage(page - 1)}
              />
              <Pagination.Item>{page}</Pagination.Item>
              <Pagination.Next
                disabled={disabledNext()}
                onClick={() => setPage(page + 1)}
              />
            </Pagination>
          </Col>
        ) : null}
      </Container>
    </div>
  );
};

export default Acciones;
