import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall, token } from "../api/pwa_api";
import * as Services from "../services/authServices";
import * as types from "../../consts/actionTypes";
import Swal from "sweetalert2";

export function* getPropuestasSaga({ payload }) {
  console.log("pidiendo info...");
  try {
    const results = yield call(
      apiCall,
      "propuesta/propuesta/",
      null,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${Services.getAccessToken()}`,
      },
      "GET"
    );
    console.log("info obtenida");
    yield put({ type: types.GET_PROPUESTAS_SUCCESS, results });
  } catch (error) {
    yield put({ type: types.GET_REQUEST_ERROR, error });
  }
}

export function* getMyPropuestasSaga({ payload }) {
  console.log("pidiendo info...");
  try {
    const results = yield call(
      apiCall,
      "propuesta/mispropuestas/",
      null,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${Services.getAccessToken()}`,
      },
      "GET"
    );
    console.log("info obtenida");
    yield put({ type: types.GET_MY_PROPUESTAS_SUCCESS, results });
  } catch (error) {
    yield put({ type: types.GET_REQUEST_ERROR, error });
  }
}

export function* getOnePropuestaSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "propuesta/propuesta/" + payload + "/",
      null,
      {
        "Content-Type": "application/json",
        Authorization: `Bearer  ${Services.getAccessToken()}`,
      },
      "GET"
    );
    console.log("Propuesta Individual solicitada");
    yield put({ type: types.GET_ONE_PROPUESTA_SUCCESS, results });
  } catch (error) {
    yield put({ type: types.GET_REQUEST_ERROR, error });
  }
}

export function* supportPropuesta({ payload }) {
  console.log(payload);
  try {
    const support = yield call(
      apiCall,
      "propuesta/apoyar/",
      {
        propuesta: payload,
      },
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "POST"
    );
    console.log(support);
    yield put({ type: types.POST_SUPPORT_PROPUESTA_SUCCESS, support });
  } catch (error) {
    console.log(error);
    yield put({ type: types.POST_REQUEST_ERROR, error });
  }
}

export function* createComentario({ payload }) {
  try {
    // const
  } catch (error) {
    yield put({ type: types.POST_REQUEST_ERROR, error });
    console.log(error);
  }
}

export function* getTendenciasPropuestas({ payload }) {
  try {
    const tendencias = yield call(
      apiCall,
      "tendencias/propuestas/",
      {},
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "GET"
    );

    yield put({ type: types.GET_TENDENCIAS_PROPUESTAS_SUCCESS, tendencias });
  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_REQUEST_ERROR, error });
  }
}

export function* postPropuestaSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "propuesta/propuesta/",
      payload.body,
      {
        Authorization: "Bearer " + Services.getAccessToken(),
        Host: "localhost:3000",
      },
      "POST"
    );
    console.log("Propuesta creada satisfactoriamente");
    yield put({ type: types.POST_PROPUESTA_SUCCESS, results });
    payload.setModal(true)
  } catch (error) {
    console.log("Error al crear propuestas: ", error.message);
    yield put({ type: types.POST_PROPUESTA_ERROR, error });
  }
}

export function* putPropuestaSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `propuesta/propuesta/${payload.id}/`,
      payload.body,
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "PUT"
    );
    console.log("Propuesta actualizada satisfactoriamente");
    yield put({ type: types.PUT_PROPUESTA_SUCCESS, results });
    payload.setModal(true)
  } catch (error) {
    console.log("Error al actualizar propuestas: ", error.message);
    yield put({ type: types.PUT_PROPUESTA_ERROR, error });
  }
}

export function* getApoyosMaximosSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "nlp/value-percentage/",
      payload,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );
    yield put({ type: types.GET_APOYOS_MAXIMOS_SUCCESS, results });
  } catch (e) {
    yield put({ type: types.GET_APOYOS_MAXIMOS_ERROR, payload });
  }
}

export function* deletePropuestaSaga({ payload }) {
  try {
    const results = yield call(
      apiCall,
      `propuesta/propuesta/${payload}/`,
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "DELETE"
    );
    yield put({ type: types.DELETE_PROPUESTA_SUCCESS, results });
    Swal.fire("Propuesta eliminada!", "", "success");
  } catch (e) {
    Swal.fire("Error!", "No fue posible eliminar la propuesta", "error");
    yield put({ type: types.DELETE_PROPUESTA_ERROR, payload });
  }
}

export default function* propuestaSaga() {
  yield takeLatest(types.GET_PROPUESTAS, getPropuestasSaga);
  yield takeLatest(types.GET_MY_PROPUESTAS, getMyPropuestasSaga);
  yield takeLatest(types.GET_ONE_PROPUESTA, getOnePropuestaSaga);
  yield takeLatest(types.POST_SUPPORT_PROPUESTA, supportPropuesta);
  yield takeLatest(types.GET_TENDENCIAS_PROPUESTAS, getTendenciasPropuestas);
  yield takeLatest(types.POST_PROPUESTA, postPropuestaSaga);
  yield takeLatest(types.PUT_PROPUESTA, putPropuestaSaga);
  yield takeLatest(types.GET_APOYOS_MAXIMOS, getApoyosMaximosSaga);
  yield takeLatest(types.DELETE_PROPUESTA, deletePropuestaSaga);
}
