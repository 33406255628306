import React, { useState, useEffect } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import "./questionQuiz.css";

const QuestionQuiz = (props) => {
  const [buttonStates, setButtonStates] = useState([]);

  const initStateMatrix = () => {
    let matrixStates = [];
    let answersInitialState = [];
    props.quiz.map((qas, key) => {
      matrixStates[key] = [];
      qas.respuestas.map((ans, idx) => {
        matrixStates[key][idx] = false;
      });
      const sendAnswersObject = { pregunta: qas.id, respuestas: [] };
      answersInitialState.push(sendAnswersObject);
    });
    setButtonStates(matrixStates);
    props.setAnswers(answersInitialState);
  };

  const handleButton = (e) => {
    const maxAnswers = e.currentTarget.getAttribute("data-max-answers");
    const row = e.currentTarget.getAttribute("data-pregunta");
    const column = e.currentTarget.getAttribute("data-respuesta");
    const preguntaId = e.currentTarget.getAttribute("data-pregunta-id");
    const idAnswer = e.currentTarget.id;

    let copyMatrix = [...buttonStates];
    let copyAnswers = [...props.answers];
    let myAnswer = copyAnswers.find(
      (obj) => obj.pregunta === parseInt(preguntaId)
    );

    console.log(copyAnswers[row].respuestas);

    if (copyMatrix[row].includes(true)) {
      if (maxAnswers === "2" && copyMatrix[row][column] === false) {
        console.log("No más");
      } else {
        let myIdAnswerButton = copyAnswers[row].respuestas.findIndex(
          (obj) => obj === parseInt(idAnswer)
        );
        if (myIdAnswerButton !== -1) {
          copyMatrix[row][column] = !copyMatrix[row][column];
          setButtonStates(copyMatrix);
          myAnswer.respuestas.splice(myIdAnswerButton, 1);
          props.setAnswers(copyAnswers);
        } else {
          copyMatrix[row][column] = !copyMatrix[row][column];
          setButtonStates(copyMatrix);
          myAnswer.respuestas.push(parseInt(idAnswer));
          props.setAnswers(copyAnswers);
        }
      }
    } else {
      copyMatrix[row][column] = !copyMatrix[row][column];
      setButtonStates(copyMatrix);
      myAnswer.respuestas.push(parseInt(idAnswer));
      props.setAnswers(copyAnswers);
    }
  };

  useEffect(() => {
    initStateMatrix();
  }, []);

  return (
    <div>
      {props.quiz.map((qas, key) => {
        return (
          <Container fluid className="container-question-quiz" key={key}>
            <h6>{qas.pregunta}</h6>
            <p>
              {qas.tipo === "2"
                ? "Seleccione solo una opción"
                : "Seleccione una o más opciones"}
            </p>
            <Container fluid>
              <Row>
                {qas.respuestas.map((ans, idx) => {
                  return (
                    <Col
                      md={3}
                      className="container-buttons-question-answers"
                      key={idx}
                    >
                      <Button
                        size="sm"
                        className="button-question-answers"
                        variant="outline-primary"
                        data-pregunta={key}
                        data-respuesta={idx}
                        data-pregunta-id={qas.id}
                        data-max-answers={qas.tipo}
                        onClick={handleButton}
                        active={
                          buttonStates.length > 0
                            ? buttonStates[key][idx]
                            : false
                        }
                        id={ans.id}
                      >
                        {ans.etiqueta}
                      </Button>
                    </Col>
                  );
                })}
              </Row>
            </Container>
          </Container>
        );
      })}
    </div>
  );
};

export default QuestionQuiz;
