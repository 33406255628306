import * as types from "../../consts/actionTypes";

const initialState = {
  encuestas: [],
  encuestas_detail: [],
  result: undefined,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_ENCUESTAS:
      return {
        ...state,
      };

    case types.GET_ENCUESTAS_SUCCESS:
      Object.assign(state.encuestas, action);
      return {
        ...state,
        encuestas: action.encuestas,
        result: undefined,
      };

    case types.GET_ENCUESTAS_DETAILS:
      return {
        ...state,
      };

    case types.GET_ENCUESTAS_DETAILS_SUCCESS:
      return {
        ...state,
        encuestas_detail: action.result.data,
        result: undefined,
      };

    case types.POST_VOTACION_USER:
      return {
        ...state,
      };

    case types.POST_VOTACION_USER_SUCCESS:
      return {
        ...state,
        result: action.result,
      };
    case types.POST_VOTACION_USER_ERROR:
      return {
        ...state,
        result: false,
      };
    default:
      return {
        ...state,
      };
  }
}
