import React, { useState } from "react";
import { ThumbsUp, ThumbsDown } from "react-feather";

import "./CommentComponent.css";
import { Button } from "react-bootstrap";
import moment from "moment";
import { clicksInside } from "../../utils/ComponentUtils";
import { AlertTriangle } from "react-feather";

import baseUrl from "../../consts/GeneralUrl"

const CommentComponent = (props) => {
  const [nuevoContenido, setNuevoContenido] = useState();
  const localTime = moment(props.date);
  moment.locale("es");
  localTime.locale(false);
  console.log(props.idShowOpciones);
  return (
    <div className="comment-content">
      <div className="comment-image">
        <img
          src={"https://static-pc.nyc3.digitaloceanspaces.com/static-pc/static/avatar/" + props.src}
        />
      </div>
      <div className="comment-style">
        <div className="span-style">
          <div className="name-date-comments">
            <span className="user-style">
              {props.user.first_name + " " + props.user.last_name}
            </span>
            <span className="date-style">{localTime.format("LLL")}</span>
          </div>
          {(props.user
            ? props.idUsuarioLogged === props.user.id
              ? true
              : false
            : props.idUsuarioLogged === props.usuario.id
            ? true
            : false) && (
            <div className="div-div-opciones-comentarios">
              <span
                className="points-icon"
                onClick={() => {
                  props.setIdShowOpciones(props.idComment);
                }}
              >
                <img src={props.iconSrc} />
              </span>
              {props.idShowOpciones === props.idComment && (
                <div className="div-opciones-comentarios">
                  <div
                    onClick={() =>
                      props.setIdComentarioAEditar(props.idComment)
                    }
                  >
                    <p className="p-opcion-editar-comentarios">Editar</p>
                  </div>

                  <p
                    className="p-opcion-eliminar-comentarios"
                    onClick={() => props.deleteAction(props.idComment)}
                  >
                    Eliminar
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
        {props.moderated === "M" && (
          <div className="div-banner-comentario-rojo">
            <AlertTriangle />
            <p>
              Este comentario infringe las normas de la plataforma o contiene
              información sensible.
            </p>
          </div>
        )}
        <div>
          {props.idComment === props.idComentarioAEditar ? (
            <div className="div-edicion-comentario">
              <textarea
                className="textarea-edit-commment"
                onChange={(event) => setNuevoContenido(event.target.value)}
              >
                {props.content}
              </textarea>
              <button
                className="button-guardar-editar-comentario"
                onClick={() => {
                  props.editAction(props.idComment, nuevoContenido);
                  props.setIdComentarioAEditar(null);
                }}
              >
                Guardar
              </button>
            </div>
          ) : (
            <p>{props.moderated === "N" && props.content}</p>
          )}
          {props.moderated !== "M" && !props.ofensive && (
            <p
              className="p-responder-comentario"
              onClick={props.responseAction}
            >
              Responder
            </p>
          )}
        </div>
        {props.moderated !== "M" && !props.ofensive && (
          <div className="comment-actions-container">
            <div className="comment-action agree">
              <div
                onClick={() => {
                  props.likeAction(props.idComment);
                }}
              >
                <ThumbsUp size={"1.4rem"} color="#0F69B4" />
              </div>
              <div className="agree-counter">{props.votos_up}</div>
            </div>
            <div className="comment-action disagree">
              <div
                onClick={() => {
                  props.unlikeAction(props.idComment);
                }}
              >
                <ThumbsDown size={"1.4rem"} color="#A6ACB3" />
              </div>
              <div className="disagree-counter">{props.votos_down}</div>
            </div>
            <div className="comment-action total-likes">
              {props.votos_up + props.votos_down} Votos
            </div>
          </div>
        )}
        {props.respuestas.length > 0 &&
          props.respuestas.map((respuesta) => {
            return (
              <div className="comment-content" style={{ marginTop: "50px" }}>
                <div className="comment-image">
                  <img
                    src={
                      "https://static-pc.nyc3.digitaloceanspaces.com/static-pc/static/avatar/" +
                      (respuesta.user
                        ? respuesta.user.avatar
                        : respuesta.usuario.avatar)
                    }
                  />
                </div>
                <div className="comment-style">
                  <div className="span-style">
                    <div className="name-date-comments">
                      <span className="user-style">
                        {respuesta.user
                          ? respuesta.user.first_name +
                            " " +
                            respuesta.user.last_name
                          : respuesta.usuario.first_name +
                            " " +
                            respuesta.usuario.last_name}
                      </span>
                      <span className="date-style">
                        {moment(respuesta.fecha).format("LLL")}
                      </span>
                    </div>
                    {(respuesta.user
                      ? props.idUsuarioLogged === respuesta.user.id
                        ? true
                        : false
                      : props.idUsuarioLogged === respuesta.usuario.id
                      ? true
                      : false) && (
                      <span
                        className="points-icon"
                        onClick={() => props.setIdShowOpciones(respuesta.id)}
                      >
                        <img src={props.iconSrc} />
                        {props.idShowOpciones === respuesta.id && (
                          <div
                            className="div-opciones-comentarios"
                            style={{ float: "inherit" }}
                          >
                            <div
                              onClick={() =>
                                props.setIdComentarioAEditar(respuesta.id)
                              }
                            >
                              <p className="p-opcion-editar-comentarios">
                                Editar
                              </p>
                            </div>

                            <p
                              className="p-opcion-eliminar-comentarios"
                              onClick={() => props.deleteAction(respuesta.id)}
                            >
                              Eliminar
                            </p>
                          </div>
                        )}
                      </span>
                    )}
                  </div>
                  {respuesta.moderated === "M" && (
                    <div className="div-banner-comentario-rojo">
                      <AlertTriangle />
                      <p>
                        Este comentario infringe las normas de la plataforma o
                        contiene información sensible.
                      </p>
                    </div>
                  )}
                  <div>
                    {respuesta.id === props.idComentarioAEditar ? (
                      <div className="div-edicion-comentario">
                        <textarea
                          className="textarea-edit-commment"
                          onChange={(event) =>
                            setNuevoContenido(event.target.value)
                          }
                        >
                          {respuesta.comentario}
                        </textarea>
                        <button
                          className="button-guardar-editar-comentario"
                          onClick={() => {
                            props.editAction(respuesta.id, nuevoContenido);
                            props.setIdComentarioAEditar(null);
                          }}
                        >
                          Guardar
                        </button>
                      </div>
                    ) : (
                      <p>
                        {respuesta.moderated === "N" && respuesta.comentario}
                      </p>
                    )}
                  </div>
                  <div className="comment-actions-container">
                    <div className="comment-action agree">
                      <div
                        onClick={() => {
                          props.likeAction(respuesta.id);
                        }}
                      >
                        <ThumbsUp size={"1.4rem"} color="#0F69B4" />
                      </div>
                      <div className="agree-counter">{respuesta.votos_up}</div>
                    </div>
                    <div className="comment-action disagree">
                      <div
                        onClick={() => {
                          props.unlikeAction(respuesta.id);
                        }}
                      >
                        <ThumbsDown size={"1.4rem"} color="#A6ACB3" />
                      </div>
                      <div className="disagree-counter">
                        {respuesta.votos_down}
                      </div>
                    </div>
                    <div className="comment-action total-likes">
                      {respuesta.votos_up + respuesta.votos_down} Votos
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CommentComponent;
