import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import './DialogModalComponent.css';

const DialogModalComponent = (props) => {
	return (
		<div className={props.classNameDivModal}>
			<Button
				className={props.classNameShowButton}
				type={props.type}
				variant="primary"
				hidden={props.hidden}
				onClick={props.onClickShow}>
				{props.isHaveIconBefore ? <img className={props.classNameImgBefore} src={props.srcBefore} /> : ''}
				{props.showButtonText}
			</Button>

			<Modal show={props.show} onHide={props.onClickHide} className="modal-filtros">
				{props.isHaveModalHeader ? (
					<Modal.Header closeButton>
						<Modal.Title>{props.modalTitle}</Modal.Title>
					</Modal.Header>
				) : (
					''
				)}

				<Modal.Body className={props.classNameBody}>{props.children}</Modal.Body>
				{props.isHaveModalFooter ? (
					<Modal.Footer className={props.classNameFooter}>
						{props.isHaveCloseButton ? (
							<Button
								className={props.classNameCloseButton}
								variant="secondary"
								onClick={props.onClickClose}>
								{props.closeButtonText}
							</Button>
						) : (
							''
						)}
						{props.isHaveSaveButton ? (
							<Button className={props.classNameSaveButton} variant="primary" onClick={props.onClickSave}>
								{props.saveButtonText}
							</Button>
						) : (
							''
						)}
					</Modal.Footer>
				) : (
					''
				)}
			</Modal>
		</div>
	);
};

export default DialogModalComponent;
