import React from "react";

import { Link } from "react-router-dom";

import { Button } from "react-bootstrap";

const ButtonComponent = (props) => {
  return (
    <Link to={props.to} className={props.classNameLink}>
      <Button
        className={props.classNameButton}
        variant="primary"
        onClick={props.onClick}
        disabled={props.disabled}
        size={props.size}
      >
        {props.isHaveIconBefore ? (
          <img className={props.classNameImgBefore} src={props.srcBefore} />
        ) : (
          ""
        )}
        {props.buttonText}
        {props.isHaveIconAfter ? (
          <img className={props.classNameImgAfter} src={props.srcAfter} />
        ) : (
          ""
        )}
      </Button>
    </Link>
  );
};

export default ButtonComponent;
