import React from "react";
import Button from "react-bootstrap/Button";
import { useDispatch } from "react-redux";
import "./ConfirmationContent.css";
import { contactaSiac } from "../../../redux/actions/siacActions";
import { useHistory } from "react-router-dom";

const ConfirmationContent = (props) => {
  const dispatch = useDispatch();
  const history = useHistory()
  return (
    <div className={props.classNameDiv}>
      <p className={props.classNamePar1}>{props.par1}</p>
      <p className={props.classNamePar2}>{props.par2}</p>
      <Button
          variant="secondary"
          onClick={()=>history.push('/')}
          style={{marginRight:5}}
        >
          Rechazar
        </Button>
      <a
        target="_blank"
        href="https://siac.interior.gob.cl/formulario.gov?accion=ingresa"
      >
        <Button
          variant="primary"
          className="custom-style-similar-proposal-button"
          onClick={() => dispatch(contactaSiac())}
        >
          Aceptar
        </Button>
      </a>
    </div>
  );
};

export default ConfirmationContent;
