import { all } from "redux-saga/effects";
import AuthSaga from "./authSagas";
import VotacionSaga from "./votacionSagas";
import ConversacionSaga from "./conversacionSaga";
import PropuestaSaga from "./propuestaSagas";
import ProyectoSaga from "./proyectoSaga";
import ComentarioSaga from "./comentarioSagas";
import AccionSaga from "./accionSaga";
import ConfiguracionSaga from "./configuracionSaga";
import NotificationsSaga from './notificationsSaga';
import SiacSaga from './siacSaga'
import BusquedaSagas from './busquedaSaga'
import RecuperacionSaga from './recuperacionSaga'

/**
 * Redux Sagas sit between the Actions and Reducers listening for "messages"
 */

export default function* rootSaga(params) {
  console.log(" <---------  Sagas index --------->");
  // console.log(params)
  yield all([
    AuthSaga(),
    VotacionSaga(),
    ConfiguracionSaga(),
    ConversacionSaga(),
    NotificationsSaga(),
    PropuestaSaga(),
    ProyectoSaga(),
    ComentarioSaga(),
    AccionSaga(),
    SiacSaga(),
    BusquedaSagas(),
    RecuperacionSaga(),
  ]);
}
