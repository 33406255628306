import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory, Link } from "react-router-dom";
import List from "./List.js";
import SearchForm from "./SearchForm";
import Chat from "./Chat";
import InputForm from "./InputForm";
import { makeStyles } from "@material-ui/core";
import DraftsIcon from "@material-ui/icons/Drafts";
import IconoChatVacio from "../../assets/icons/chat-vacio.svg";
import { ChevronLeft } from "react-feather";
import "../styles/variables.css";
import * as Actions from "../../redux/actions/conversacionesActions";
import IconoDrawerDosLineas from "../../assets/icons/drawer-dos-lineas.svg";
import DefaultAvatar from "../../assets/images/conversations/avatar.png";
import * as GAservices from "../../redux/services/GoogleAnalyticsServices";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { clicksInside } from "../../utils/ComponentUtils";

const drawerWidth = "12%";

const personInfo = [
  {
    id: 1,
    name: "Placeholder",
    avatar: DefaultAvatar,
    time: "21:29",
    message:
      "Buenas tardes Victor, Agradecemos tu solicitud. Te informamos que ha sido enviada a SIAC. Me gustaría invitarte a conocer las propuestas que existen dentro de la plataforma relacionadas con instalación de internet en las plazas.  ¡Deseo que tengas un excelente día!",
    position: "Lorem ipsum dolor",
    online: true,
    archive: false,
  },
  {
    id: 2,
    name: "Robert",
    avatar: DefaultAvatar,
    time: "21:29",
    message: "¿Qué color le gustaría?",
    position: "Resolución de problema",
    questionnaire: true,
    questionnaireMultiply: true,
    multilineField: true,
    archive: false,
  },
  {
    id: 3,
    name: "Richard",
    avatar: DefaultAvatar,
    time: "11:15",
    message: "Buen día, ¿en qué podemos ayudarte?",
    position: "Resolución de problema",
    online: true,
    questionnaireMultiply: true,
    archive: false,
  },
  {
    id: 4,
    name: "Leonardo",
    avatar: DefaultAvatar,
    time: "01:15",
    message: "Buen día, ¿en qué podemos ayudarte?",
    position: "Resolución de problema",
    archive: false,
    multilineField: true,
  },
  {
    id: 5,
    name: "Jacob",
    avatar: DefaultAvatar,
    time: "10:55",
    message:
      "Esa es la información relacionada con las becas Taparacá. Espero que te sea de utilidad.",
    position: "Resolución de problema",
    archive: true,
  },
  {
    id: 6,
    name: "Olivia",
    avatar: DefaultAvatar,
    time: "07:13",
    message:
      "Esa es la información relacionada con las becas Taparacá. Espero que te sea de utilidad.",
    position: "Resolución de problema",
    archive: true,
  },
];

const styles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  wrapper: {
    backgroundColor: "#f7f7f9",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    // [theme.breakpoints.up("sm")]: {
    //   width: `calc(100% - ${drawerWidth}px)`,
    //   marginLeft: drawerWidth,
    // },
  },
  textWrapper: {
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inicio: {
    color: "#0F69B4",
    opacity: "0.5",
    fontSize: "14px",
    marginBottom: "0",
    display: "flex",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "-0.03em",
    color: "#0F69B4",
    margin: "0",
    cursor: "pointer",
  },
  boxWrapper: {
    backgroundColor: "#feffff",
    height: "calc(100vh - 132px - 91px)",
    margin: "0 20px 20px 20px",
    display: "flex",
  },
  mainButton: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "10%",
    backgroundColor: "white",
  },
  letterIcon: {
    width: "50px",
    height: "50px",
    color: "#2475B9",
    marginBottom: "20px",
  },
  checkIcon: {},
  titleBoxText: {
    color: "var(--box-text-color)",
    fontSize: "36px",
    margin: 0,
  },
  descriptionBoxText: {
    fontSize: "16px",
    color: "var(--box-text-color)",
  },
  conversationBox: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
  },
  noConversations: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
  noConversationsMessage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const Conversations = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = styles();
  const [isListShown, setIsListShow] = useState(false);

  // Import dispatch from react-redux
  const dispatch = useDispatch();

  // Get Auth State
  const authState = useSelector((state) => state.AuthReducer);
  const { tokenAccess } = authState;

  // Get conversations state
  const conversationsState = useSelector((state) => state.ConversacionReducer);
  const { conversations, messages } = conversationsState;
  const [filterMessages, setFilterMessages] = useState(messages);
  const [conversacion, setConversacion] = useState();

  // Find the conversation index
  const indx = personInfo.findIndex((info) => info.id === parseInt(id, 10));

  //Ocultar y mostrar lista
  const toggleLista = () => {
    if (
      document.getElementById("lista-chats-conversaciones").style.right ===
      "0px"
    ) {
      document.getElementById("lista-chats-conversaciones").style.right =
        "-325px";
      setIsListShow(false);
    } else {
      document.getElementById("lista-chats-conversaciones").style.right = "0px";
      setIsListShow(true);
    }
  };

  console.log(messages);

  // Call getConversaciones action
  useEffect(() => {
    // Get initial conversations
    dispatch(Actions.getConversaciones());
    clicksInside(
      ["boton-drawer-lista-chat", "lista-chats-conversaciones"],
      () =>
        (document.getElementById("lista-chats-conversaciones").style.right =
          "-325px")
    );
    GAservices.GApageView("Conversaciones");
  }, []);

  // Rerun only if id changes
  useEffect(() => {
    // If there is a conversation id fetch the messages
    if (id) {
      dispatch(Actions.getConversationMessages({ conversation_id: id }));
    }
  }, [id]);

  // Listen to socket events

  // Initialize the websocket reference
  let conversation_channel = useRef(null);

  // Initialize chat
  useEffect(() => {
    conversation_channel.current = new W3CWebSocket(
      `wss://api.participacion.management-r.com/ws/chat/${id}/?token=${tokenAccess}`
    );

    // Handle conversation error connection
    conversation_channel.current.onerror = () => {
      console.log("No se pudo establecer la conexión con el servidor");
    };

    // Handle socket open connection
    conversation_channel.current.onopen = () => {
      console.log("Conexión establecida exítosamente");
      dispatch(Actions.openConversationChannel());
    };

    conversation_channel.current.onclose = () => {
      console.log("Closing connection");
      dispatch(Actions.closeConversationChannel());
    };

    conversation_channel.current.onmessage = (event) => {
      const { data } = event;
      console.log(data);
      const message = JSON.parse(data);
      // console.log(message);
      // message.content = JSON.parse(
      //   message.content
      //     .slice(0, message.content.length)
      //     .replace(/'/g, '"')
      //     .toLowerCase()
      // ).texto;
      dispatch(Actions.receiveMessage(message));
    };

    return () => {
      conversation_channel.current.close();
    };
  }, [id, tokenAccess]);

  const sendMessage = (message) => {
    conversation_channel.current.send(
      JSON.stringify({
        message,
      })
    );
  };

  const [isArchive, setIsArchive] = useState(false);

  const handleArchive = () => {
    setIsArchive(!isArchive);
  };

  // If there isn't a specific id use the first
  if (!id) {
    const conversation_id =
      conversations && conversations.length > 0 && conversations[0].id;
    if (conversation_id) {
      history.push(`/conversaciones/${conversation_id}`);
    }
  }

  useEffect(() => {
    if (conversations) {
      setConversacion(
        conversations[conversations.findIndex((conv) => conv.id === id)]
      );
    }
  }, [conversations, id]);

  console.log(conversacion)

  return (
    <div className={classes.wrapper}>
      <Helmet>
        <title>Participación Ciudadana | Conversaciones</title>
      </Helmet>
      <div className={classes.textWrapper}>
        <div>
          <Link to="/" className={classes.inicio}>
            <ChevronLeft />
            <p>Inicio</p>
          </Link>
          <h6
            onClick={() => {
              const conversation_id =
                conversations && conversations.length > 0
                  ? `/${conversations[0].id}`
                  : "";
              history.push(`/conversaciones${conversation_id}`);
            }}
            role="button"
            className={classes.title}
          >
            Conversaciones
          </h6>
        </div>
        <div>
          <button id={"boton-drawer-lista-chat"} onClick={toggleLista}>
            <img src={IconoDrawerDosLineas} atl="desplegar listas de chat" />
          </button>
        </div>
      </div>

      <div className={classes.boxWrapper}>
        {conversations && conversations.length > 0 ? (
          <>
            {id && (
              <List
                personInfo={personInfo}
                conversation_id={id}
                handleArchive={handleArchive}
                isArchive={isArchive}
                conversations={conversations}
              />
            )}
            <div className={classes.conversationBox}>
              <SearchForm
                personInfo={
                  conversacion
                    ? conversacion.owners[0]
                    : { id: "", first_name: "", last_name: "", avatar: "" }
                }
                messages={messages}
                setFilterMessages={setFilterMessages}
              />
              {messages.length > 0 && id ? (
                <Chat
                  messages={filterMessages}
                  personInfo={personInfo[indx]}
                  isArchive={isArchive}
                />
              ) : (
                <div className={classes.mainButton} type="button">
                  <img src={IconoChatVacio} className={classes.letterIcon} />
                  <h3 className={classes.titleBoxText}>Aún no hay mensajes</h3>
                  <p className={classes.descriptionBoxText}>
                    Comienza una nueva conversación
                  </p>
                </div>
              )}
              {id && (
                <InputForm
                  personInfo={personInfo[indx]}
                  sendMessage={sendMessage}
                />
              )}
            </div>
          </>
        ) : (
          <>
            <div className={classes.noConversations}>
              <div className={classes.noConversationsMessage}>
                <img src={IconoChatVacio} className={classes.letterIcon} />
                <h3 className={classes.titleBoxText}>
                  Aún no hay conversaciones
                </h3>
                <p className={classes.descriptionBoxText}>
                  Comienza una nueva conversación
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default Conversations;
