import { get } from 'lodash';
export const user = (state) => get(state, "AuthReducer.user");
export const logged = (state) => get(state, "AuthReducer.logged");
export const error = (state) => get(state, "AuthReducer.error");
export const tokenAccess = (state) => get(state, "AuthReducer.tokenAccess");
export const tokenRefresh = (state) => get(state, "AuthReducer.tokenRefresh");
export const isValid = (state) => get(state, "AuthReducer.isValid");
export const registerSuccess = (state) => get(state, "AuthReducer.registerSuccess");
export const politicasPrivacidadResult = (state) => get(state, "AuthReducer.politicasPrivacidad");
export const politicasAceptadasResult = (state) => get(state, "AuthReducer.politicasAceptadas");
export const videoAceptadoResult = (state) => get(state, "AuthReducer.videoAceptado");
export const emailVerificadoResult = (state) => get(state, "AuthReducer.emailVerificado");