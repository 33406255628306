import * as types from "../../consts/actionTypes";

export const ComentarioSuccessFlagToFalse = () => {
  return {
    type: types.COMENTARIOS_SUCCESS_FLAG_TO_FALSE,
  };
};

export const createComentarioPropuesta = (payload) => {
  return {
    type: types.POST_CREATE_PROPUESTAS_COMMENTS,
    payload,
  };
};

export const voteComentarioPropuesta = (payload) => {
  return {
    type: types.POST_VOTE_PROPUESTAS_COMMENTS,
    payload,
  };
};

export const deleteVoteComentarioPropuesta = (payload) => {
  return {
    type: types.DELETE_VOTE_PROPUESTAS_COMMENTS,
    payload,
  };
};

export const updateVoteComentarioPropuesta = (payload) => {
  return {
    type: types.PUT_VOTE_PROPUESTAS_COMMENTS,
    payload,
  };
};

export const dislikeComentarioPropuesta = (payload) => {
  return {
    type: types.DISLIKE_COMENTARIO_PROPUESTA,
    payload,
  };
};

export const responderComentarioPropuesta = (payload) => {
  console.log("Respondiendo a comentario...")
  return {
    type: types.RESPONDER_COMENTARIO_PROPUESTA,
    payload,
  };
};

export const editarComentarioPropuesta = (payload) => {
  return {
    type: types.EDIT_COMENTARIO_PROPUESTA,
    payload,
  };
};

export const borrarComentarioPropuesta = (payload) => {
  return {
    type: types.DELETE_COMENTARIO_PROPUESTA,
    payload,
  };
};

//Comunicados

export const createComentarioComunicado = (payload) => {
  return {
    type: types.POST_CREATE_COMUNICADOS_COMMENTS,
    payload,
  };
};

export const voteComentarioComunicado = (payload) => {
  return {
    type: types.POST_VOTE_COMUNICADOS_COMMENTS,
    payload,
  };
};

export const updateVoteComentarioComunicado = (payload) => {
  return {
    type: types.PUT_VOTE_COMUNICADOS_COMMENTS,
    payload,
  };
};

export const deleteVoteComentarioComunicado = (payload) => {
  return {
    type: types.DELETE_VOTE_COMUNICADOS_COMMENTS,
    payload,
  };
};

export const dislikeComentarioAccion = (payload) => {
  return {
    type: types.DISLIKE_COMENTARIO_ACCION,
    payload,
  };
};

export const responderComentarioAccion = (payload) => {
  return {
    type: types.RESPONDER_COMENTARIO_ACCION,
    payload,
  };
};

export const editarComentarioAccion = (payload) => {
  return {
    type: types.EDIT_COMENTARIO_ACCION,
    payload,
  };
};

export const borrarComentarioAccion = (payload) => {
  return {
    type: types.DELETE_COMENTARIO_ACCION,
    payload,
  };
};
