import waterImage from '../../assets/images/Rectangle 184.png';
import flowerImage from '../../assets/images/Rectangle 151.png';

export const fakeProyectos = [
    {
        img: waterImage,
        title: "Lorem ipsum dolor sit amet",
        tags: ["Tecnología", "Innovación", "Ciudad"],
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nunc arcu arcu, elementum orci. In consequat eget nunc massa id suspendisse. Congue sed sed pellentesque adipiscing. Egestas et felis tellus proin nisl est ipsum. Pharetra consectetur risus risus viverra nec. Amet, at quis nisl ornare in ac. www.google.com <br/><br/>Pharetra consectetur risus risus viverra nec.  Congue sed sed pellentesque adipiscing. Egestas et felis tellus proin nisl est ipsum.  Amet, at quis consectetur risus et felis tellus proin nisl nisl..."
    },
    {
        img: flowerImage,
        title: "Lorem ipsum dolor sit amet",
        tags: ["Tecnología", "Innovación", "Ciudad"],
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nunc arcu arcu, elementum orci. In consequat eget nunc massa id suspendisse. Congue sed sed pellentesque adipiscing. Egestas et felis tellus proin nisl est ipsum. Pharetra consectetur risus risus viverra nec. Amet, at quis nisl ornare in ac. www.google.com <br/><br/>Pharetra consectetur risus risus viverra nec.  Congue sed sed pellentesque adipiscing. Egestas et felis tellus proin nisl est ipsum.  Amet, at quis consectetur risus et felis tellus proin nisl nisl..."
    },
    {
        img: waterImage,
        title: "Lorem ipsum dolor sit amet",
        tags: ["Tecnología", "Innovación", "Ciudad"],
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nunc arcu arcu, elementum orci. In consequat eget nunc massa id suspendisse. Congue sed sed pellentesque adipiscing. Egestas et felis tellus proin nisl est ipsum. Pharetra consectetur risus risus viverra nec. Amet, at quis nisl ornare in ac. www.google.com <br/><br/>Pharetra consectetur risus risus viverra nec.  Congue sed sed pellentesque adipiscing. Egestas et felis tellus proin nisl est ipsum.  Amet, at quis consectetur risus et felis tellus proin nisl nisl..."
    },
    {
        img: flowerImage,
        title: "Lorem ipsum dolor sit amet",
        tags: ["Tecnología", "Innovación", "Ciudad"],
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nunc arcu arcu, elementum orci. In consequat eget nunc massa id suspendisse. Congue sed sed pellentesque adipiscing. Egestas et felis tellus proin nisl est ipsum. Pharetra consectetur risus risus viverra nec. Amet, at quis nisl ornare in ac. www.google.com <br/><br/>Pharetra consectetur risus risus viverra nec.  Congue sed sed pellentesque adipiscing. Egestas et felis tellus proin nisl est ipsum.  Amet, at quis consectetur risus et felis tellus proin nisl nisl..."
    },
    {
        img: waterImage,
        title: "Lorem ipsum dolor sit amet",
        tags: ["Tecnología", "Innovación", "Ciudad"],
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nunc arcu arcu, elementum orci. In consequat eget nunc massa id suspendisse. Congue sed sed pellentesque adipiscing. Egestas et felis tellus proin nisl est ipsum. Pharetra consectetur risus risus viverra nec. Amet, at quis nisl ornare in ac. www.google.com <br/><br/>Pharetra consectetur risus risus viverra nec.  Congue sed sed pellentesque adipiscing. Egestas et felis tellus proin nisl est ipsum.  Amet, at quis consectetur risus et felis tellus proin nisl nisl..."
    },
]