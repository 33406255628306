import flowerImage from '../../assets/images/Rectangle 151.png';

export const fakeActions = [
    {
        title: "Lorem ipsum dolor sit amet",
        subtitle: "12 de Agosto de 2020",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nunc arcu arcu, elementum orci. In consequat eget nunc massa id suspendisse. Congue sed sed pellentesque adipiscing. Egestas et felis tellus proin nisl est ipsum. Pharetra consectetur risus risus viverra nec. Amet, at quis nisl ornare in ac. www.google.com <br/><br/>Pharetra consectetur risus risus viverra nec.  Congue sed sed pellentesque adipiscing. Egestas et felis tellus proin nisl est ipsum.  Amet, at quis consectetur risus et felis tellus proin nisl nisl... Ver más. Egestas et felis tellus proin nisl est ipsum. Pharetra consectetur risus risus viverra nec.",
        img: flowerImage
    },
    {
        title: "Lorem ipsum dolor sit amet",
        subtitle: "11 de Agosto de 2020",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nunc arcu arcu, elementum orci. In consequat eget nunc massa id suspendisse. Congue sed sed pellentesque adipiscing. Egestas et felis tellus proin nisl est ipsum. Pharetra consectetur risus risus viverra nec. Amet, at quis nisl ornare in ac. www.google.com <br/><br/>Pharetra consectetur risus risus viverra nec.  Congue sed sed pellentesque adipiscing. Egestas et felis tellus proin nisl est ipsum.  Amet, at quis consectetur risus et felis tellus proin nisl nisl... Ver más. \n Egestas et felis tellus proin nisl est ipsum. Pharetra consectetur risus risus viverra nec.",
        img: flowerImage
    },
    {
        title: "Lorem ipsum dolor sit amet",
        subtitle: "10 de Agosto de 2020",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nunc arcu arcu, elementum orci. In consequat eget nunc massa id suspendisse. Congue sed sed pellentesque adipiscing. Egestas et felis tellus proin nisl est ipsum. Pharetra consectetur risus risus viverra nec. Amet, at quis nisl ornare in ac. www.google.com <br/><br/>Pharetra consectetur risus risus viverra nec.  Congue sed sed pellentesque adipiscing. Egestas et felis tellus proin nisl est ipsum.  Amet, at quis consectetur risus et felis tellus proin nisl nisl... Ver más. \n Egestas et felis tellus proin nisl est ipsum. Pharetra consectetur risus risus viverra nec.",
        img: flowerImage
    },
    {
        title: "Lorem ipsum dolor sit amet",
        subtitle: "09 de Agosto de 2020",
        description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nunc arcu arcu, elementum orci. In consequat eget nunc massa id suspendisse. Congue sed sed pellentesque adipiscing. Egestas et felis tellus proin nisl est ipsum. Pharetra consectetur risus risus viverra nec. Amet, at quis nisl ornare in ac. www.google.com <br/><br/>Pharetra consectetur risus risus viverra nec.  Congue sed sed pellentesque adipiscing. Egestas et felis tellus proin nisl est ipsum.  Amet, at quis consectetur risus et felis tellus proin nisl nisl... Ver más. \n Egestas et felis tellus proin nisl est ipsum. Pharetra consectetur risus risus viverra nec.",
        img: flowerImage
    }
]