import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Estadisticas.css";

const Estadisticas = (props) => {
  return (
    <Container>
        <Row>
              <Col sm={12} className="div-texto-preguta-encuesta">
                <h1 className="texto-pregunta-encuestas">{'Estadisticas de la encuesta: "'+props.encuesta[0].encuesta+'"'}</h1>
              </Col>
            </Row>
      {props.encuesta[0].preguntas.map((pregunta) => {
        console.log(pregunta);
        let maxPercentage = Math.max.apply(
          Math,
          pregunta.respuestas.map((ob) => {
            return parseInt(ob.porcentaje);
          })
        );
        console.log(maxPercentage);
        return (
          <div className="div-pregunta-encuesta">
               
            <Row>
              <Col sm={12} className="div-texto-preguta-encuesta">
                <p className="texto-pregunta-encuestas">{pregunta.pregunta}</p>
              </Col>
            </Row>
            <Row>
              {pregunta.respuestas.length > 0 &&
                pregunta.respuestas
                  .sort(
                    (a, b) =>
                      Number(b.porcentaje.slice(0, b.porcentaje.length - 1)) -
                      Number(a.porcentaje.slice(0, a.porcentaje.length - 1))
                  )
                  .map((respuesta, index) => {
                    return (
                      <Col sm={pregunta.respuestas.length === 2 ? 6 : 4}>
                        <Row>
                          <Col
                            className={
                              index === 0
                                ? "div-etiqueta-azul-oscuro-respuesta-encuestas"
                                : "div-etiqueta-respuesta-encuestas"
                            }
                          >
                            <p className="etiqueta-respuesta-encuestas">
                              {respuesta.etiqueta.toUpperCase()}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            className={
                              index === 0
                                ? "div-etiqueta-azul-claro-respuesta-encuestas"
                                : "div-etiqueta-respuesta-encuestas"
                            }
                          >
                            <p className="valor-respuestas-encuestas">
                              {respuesta.total} ({respuesta.porcentaje})
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
            </Row>
          </div>
        );
      })}
    </Container>
  );
};

export default Estadisticas;
