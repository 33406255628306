import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { fakeVotationQuiz, fakeVotaciones } from "../fakeVotaciones";
import ProposalsNavigation from "../../../components/proposals-navigation/ProposalsNavigation";
import { Card, Container, Button, Col, Row } from "react-bootstrap";
import QuestionQuiz from "../../../components/Questions/QuestionQuiz";
import { useParams, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Selector from "../../../redux/selectors/votacionSelector";
import * as Actions from "../../../redux/actions/votacionActions";
import Swal from "sweetalert2";

const VotacionQuiz = () => {
  const history = useHistory();
  const { idEncuesta } = useParams();
  const dispatch = useDispatch();
  let encuestas = useSelector((state) => Selector.encuestas(state));
  let result = useSelector((state) => Selector.result(state));
  const [encuestaInfo, setEncuestaInfo] = useState(null);
  const [answers, setAnswers] = useState();
  const token = localStorage.getItem("access");
  console.log(encuestaInfo);

  const encuestaInformation = () => {
    if (encuestas.length > 0) {
      let myEncuesta = encuestas.find((x) => {
        return x.id === parseInt(idEncuesta);
      });
      if (myEncuesta.contestada)
        history.push(`/votaciones/estadisticas/${myEncuesta.id}/`);
      setEncuestaInfo(myEncuesta);
    }
  };

  const submitQuiz = () => {
    const payloadSubmit = { encuesta: encuestaInfo.id, respuestas: answers };
    dispatch(Actions.postVotacionUser(payloadSubmit));
  };

  useEffect(() => {
    dispatch(Actions.getEncuestas(token));
  }, []);

  useEffect(() => {
    encuestaInformation();
  }, [encuestas]);

  useEffect(() => {
    if (result && result.status === 201) {
      history.push("/vote/success/" + idEncuesta);
    } else if (result === false) {
      Swal.fire({
        title: "Error!",
        text: "Hubo un error al enviar su votación",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  }, [result]);

  console.log(result);

  const renderProposalsNavigation = () => {
    return (
      <ProposalsNavigation
        paragraph="Regresar a Votaciones"
        goBackRoute={"/votaciones"}
      />
    );
  };

  return (
    <div>
      {encuestaInfo && (
        <Helmet>
          <title>Participación Ciudadana | {`${encuestaInfo.nombre}`}</title>
        </Helmet>
      )}
      {renderProposalsNavigation()}
      <Container fluid>
        {encuestaInfo !== null ? (
          <Card>
            <Card.Header>
              <Card.Title>{encuestaInfo && encuestaInfo.nombre}</Card.Title>
            </Card.Header>
            <Card.Body>
              <QuestionQuiz
                quiz={encuestaInfo && encuestaInfo.preguntas}
                answers={answers}
                setAnswers={setAnswers}
              />
            </Card.Body>
            <Row>
              <Col
                md={{ span: 3, offset: 9 }}
                style={{ marginBottom: "1.5em", paddingRight: "2em" }}
              >
                {/* <Link to={"/vote/success/" + idEncuesta}> */}
                <Button
                  variant="primary"
                  style={{ width: "100%" }}
                  onClick={submitQuiz}
                >
                  {" "}
                  Enviar
                </Button>
                {/* </Link> */}
              </Col>
            </Row>
          </Card>
        ) : (
          <div></div>
        )}
      </Container>
    </div>
  );
};

export default VotacionQuiz;
