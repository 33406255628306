import React from "react";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as userError from "../../redux/selectors/rootSelectors";
//Components
import HeaderMobile from "./HeaderMobile";

const LoginForm = (props) => {
  const { handleSubmit, validated } = props;

  const [datos, setDatos] = React.useState({
    user: "",
    password: "",
  });

  const handleDatos = (e) => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value,
    });
  };

  const error = useSelector((state) => userError.error(state));

  return (
    <Col sm={6}>
      <Row
        className="d-flex justify-content-center align-content-center"
        style={{ height: "100%" }}
      >
        <Col
          xs={12}
          className="d-sm-none header-registro"
          style={{ position: "absolute" }}
        >
          <HeaderMobile />
        </Col>
        <Col xs={11} sm={9}>
          <Card className="card-container">
            <h2 className="login__title">Iniciar Sesión</h2>

            <div className="external-links">
  ¿No tienes una cuenta? <Link to="/registro" style={{marginLeft: "12px"}}>Regístrate</Link>
            </div>

            <Form
              noValidate
              // validated={validated}
              onSubmit={(e) => handleSubmit(e, datos)}
              id="registerLogin"
            >
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="label-input">
                  DNI
                </Form.Label>
                <Form.Control
                  type="text"
                  // minLength="9"
                  // maxLength="10"
                  // pattern="(\d{7,8}[-]\w)"
                  placeholder="Ej. 2465938-4"
                  name="user"
                  onChange={handleDatos}
                  value={datos.user}
                  // required
                />
                <Form.Control.Feedback type="invalid">
                  DNI no válido
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label className="label-input">Contraseña</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="**********"
                  name="password"
                  onChange={handleDatos}
                  value={datos.password}
                  // required
                />
                <Form.Control.Feedback type="invalid">
                  Contraseña requerida
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Label className="error-label">{error}</Form.Label>
              <br />
              <Link to="/recuperacion">No recuerdo mi contraseña</Link>
              <Button type="submit" className="submit" block>
                Iniciar sesión{" "}
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default LoginForm;
