import React, { useEffect, useState, useReducer } from "react";
import { Row } from "react-bootstrap";
import "./Registro.css";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "../../redux/actions/userActions";
import * as userSelector from "../../redux/selectors/rootSelectors";
import * as GAservices from "../../redux/services/GoogleAnalyticsServices";
import RegistroBg from "./RegistroBg";
import RegistroForm from "./RegistroForm";
import { Modal, Button } from "react-bootstrap";

const Registro = () => {
  //let history = useHistory();
  const dispatch = useDispatch();
  const registerSuccess = useSelector((state) =>
    userSelector.registerSuccess(state)
  );
  const history = useHistory();
  // const [state, dispatch] = useReducer
  const [success, setSuccess] = useState(registerSuccess);
  const [showAlert, setShowAlert] = useState(false);
  const [validated, setValidated] = React.useState(false);

  const handleSubmit = (e, data) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      e.stopPropagation();

      let payload = new FormData();
      payload.append("username", data.user);
      payload.append("email", data.email);
      payload.append("password1", data.password);
      payload.append("password2", data.confirmPass);
      payload.append("clave_ciudadano", data.user);
      payload.append("first_name", data.nombre);
      payload.append("last_name", data.apellido1 + " " + data.apellido2);
      payload.append("region", data.region);
      payload.append("junta_vecinal", data.junta);
      payload.append("fecha_nacimiento", data.fechaDeNacimiento);
      payload.append("genero", data.sexo);
      // if (data.junta) {
      //   payload.junta_vecinal = data.junta;
      // }
      console.log(payload);

      dispatch(userActions.registerUser(payload));

      //setValidated(true)
    }
    setValidated(true);
  };

  // console.log(success)
  const showAlertSuccessfull = () => {
    console.log(success);
    if (success) {
      setShowAlert(true);
    }
  };

  useEffect(() => {
    dispatch(userActions.registerReset());
    console.log(registerSuccess);
    setSuccess(registerSuccess);
    // showAlertSuccessfull();
    GAservices.GApageView("Registro");
  }, []);

  useEffect(() => {
    console.log(registerSuccess);
    setSuccess(registerSuccess);
    setShowAlert(registerSuccess);
    setValidated(registerSuccess);
  }, [registerSuccess]);

  return (
    <div>
      <Row style={{ height: "100%" , justifyContent:"center"}}>
        {/* <RegistroBg /> */}
        <RegistroForm
          handleSubmit={handleSubmit}
          validated={validated}
          showAlert={showAlert}
          setShowAlert={setShowAlert}
        />
      </Row>
      <Modal show={success} backdrop="static" centered>
        <Modal.Header>
          <Modal.Title>Se ha registrado exitosamente!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Un correo de verificación ha sido enviado a la dirección de correo
          proporcionada, verifique su correo antes de ingresar a la plataforma
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setSuccess(null);
              history.push("/login");
              dispatch(userActions.registerReset());
            }}
            block
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <Modal show={success === false} backdrop="static" centered>
        <Modal.Header>
          <Modal.Title>Hubo un problema!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          El RUN o el correo proporcionado ya esta registrado en otra cuenta.
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setSuccess(null);
              dispatch(userActions.registerReset());
            }}
            block
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
};

export default Registro;

// A user is already registered with that username
// A user is already registered with this e-mail address.
