import { combineReducers } from "redux";
import toogleSidebar from "./sidebar";
import messagesReducer from "./messages";
import AuthReducer from "./authReducers";
import VotacionReducer from "./votacionReducer";
import ConversacionReducer from "./conversacionesReducer";
import PropuestaReducer from "./propuestaReducer";
import ProyectoReducer from "./proyectoReducers";
import ComentarioReducer from "./comentarioReducer";
import AccionReducer from "./accionReducer";
import ConfiguracionReducer from "./configuracionReducer";
import NotificationsReducer from "./notificationsReducer";
import SiacReducer from "./siacReducer";
import BusquedaReducer from "./busquedaReducer";
import RecuperacionReducer from "./recuperacionReducer";

const rootReducer = combineReducers({
  sideBar: toogleSidebar,
  messages: messagesReducer,
  AuthReducer,
  VotacionReducer,
  ConfiguracionReducer,
  ConversacionReducer,
  SiacReducer,
  PropuestaReducer,
  ProyectoReducer,
  ComentarioReducer,
  AccionReducer,
  NotificationsReducer,
  BusquedaReducer,
  RecuperacionReducer,
});

export default rootReducer;
