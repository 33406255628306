import { get } from 'lodash';
export const propuestas = (state) => get(state, "PropuestaReducer.propuestas")
export const myPropuestas = (state) => get(state, "PropuestaReducer.myPropuestas")
export const onePropuesta = (state) => get(state, "PropuestaReducer.propuestaResult")
export const tendenciasPropuestas = (state) => get(state, "PropuestaReducer.tendencias")
export const successFlagResult = (state) => get(state, "PropuestaReducer.successFlag")
export const responsePostPropuesta = (state) => get(state, "PropuestaReducer.responsePostPropuesta")
export const responsePutPropuesta = (state) => get(state, "PropuestaReducer.responsePutPropuesta")
export const apoyosMaximosResult = (state) => get(state, "PropuestaReducer.apoyosMaximos")
// export const encuestaDetail = (state) => get(state, "VotacionReducer.encuestas_detail")
// export const result = (state) => get(state, "VotacionReducer.result")

