import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import CorreoGrande from "../../assets/icons/Mail-grande.svg";
import * as GAservices from "../../redux/services/GoogleAnalyticsServices";
import { verifyEmail } from "../../redux/actions/userActions";
import { emailVerificadoResult } from "../../redux/selectors/rootSelectors";

import "./VerificarCorreo.css";

const VerificarCorreo = () => {
  const dispatch = useDispatch();
  const emailVerificado = useSelector((state) => emailVerificadoResult(state));
  const { verificationCode } = useParams();
  useEffect(() => {
    dispatch(verifyEmail(verificationCode));
    GAservices.GApageView("Verificación de correo");
  }, []);
  return (
    <div>
      {emailVerificado === undefined && (
        <div className="div-verificar-correo">
          <h1>Verificando correo...</h1>
        </div>
      )}
      {emailVerificado === true && (
        <div className="div-verificar-correo">
          <img src={CorreoGrande} alt="Correo verificado" />
          <h1>Su correo se ha verificado con exito!</h1>
          <br />
          <Link to="/login">Iniciar sesión</Link>
        </div>
      )}
      {emailVerificado === false && (
        <div className="div-verificar-correo">
          <img src={CorreoGrande} alt="Correo verificado" />
          <h1>Su correo no ha podido ser verificado</h1>
          <br />
          <h3>Contacte a soporte técnico</h3>
        </div>
      )}
    </div>
  );
};
export default VerificarCorreo;
