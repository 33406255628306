import React from "react";
import "./styles.css";
//Components
import { Card, Button, Col, Row, Alert } from "react-bootstrap";
import IconoPlanetaAzul from "../../../assets/icons/planeta-azul.svg";
import IconoCalendarioAzul from "../../../assets/icons/calendario.svg";
import IconoEncuestaAzul from "../../../assets/icons/encuesta-azul.svg";
//Constants
// import verticalImg from "../../../assets/images/login_bg.png";
import { Link } from "react-router-dom";

const CardVotaciones = (props) => {
  return (
    <div className="card-container-votation">
      <Card className="card-votation">
        <Row className="card-items-container-votation">
          <Col md={3} className="col-imagen-votacion">
            <div
              className="card-container-img-votation"
              style={{ backgroundImage: "url(" + props.image + ")" }}
            >
              <div
                className="card-img-votation"
                style={{ backgroundImage: "url(" + props.image + ")" }}
              ></div>
            </div>
          </Col>
          <Col md={6}>
            <Card.Text>
              <h5 className="titulo-tarjeta-votaciones">{props.title}</h5>
            </Card.Text>
            <Card.Text className="card-text-description-votation">
              <ul>
                {props.questions.map((question, key) => {
                  return (
                    <li key={key} className="pregunta-tarjeta-votacion">
                      {question.pregunta}
                    </li>
                  );
                })}
              </ul>
            </Card.Text>
          </Col>
          <Col md={3} className="col-imagen-votacion-movil">
            <div
              className="card-container-img-votation"
              style={{ backgroundImage: "url(" + props.image + ")" }}
            >
              <div
                className="card-img-votation"
                style={{ backgroundImage: "url(" + props.image + ")" }}
              ></div>
            </div>
          </Col>
          <Col md={3}>
            <Alert variant="light">
              <img src={IconoPlanetaAzul} alt="Ubicacion" />
              <span>{props.scope}</span>
            </Alert>
            <Alert variant="light">
              <img src={IconoCalendarioAzul} alt="Dias restantes" />
              {props.timeRemaining ? (
                <span>Quedan {props.timeRemaining} días</span>
              ) : (
                <span>Votación terminada</span>
              )}
            </Alert>
            <div className="card-buttons-container">
              {props.answered || props.terminada ? (
                <div>
                  {
                    
                    props.answered ? 
                    <div className="div-votacion-contestada-tarjetas">
                      <img src={IconoEncuestaAzul} alt="Votación contestada" />
                      <p>Usted ya respondió a esta Votación</p>
                    </div>
                    :
                    null
                  }
                  <Link to={`/votaciones/estadisticas/${props.idEncuesta}`}>
                    <Button className="ver">Ver Resultados</Button>
                  </Link>
                </div>
              ) : (
                <Link
                  to={`/votaciones/${props.idEncuesta}`}
                  style={{ width: "100%" }}
                >
                  <Button className="responder">Responder Votación</Button>
                </Link>
              )}
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default CardVotaciones;
