import React, { Component, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SinPropuestasSVG from "../assets/icons/Sin-propuestas.png";
import {
  MY_PROPOSALS_INFO,
  BADGES,
} from "../components/constants/ProposalsConstants";

import {
  shorten,
  checkBackground,
  setStatus,
  outerHtmlToReadableString,
} from "../utils/ComponentUtils";

import ProposalsNavigation from "../components/proposals-navigation/ProposalsNavigation";
import CardComponent from "../components/card-proposals/CardComponent";
import ProgressBarComponent from "../components/elements/ProgressBarComponent";
import BadgeComponent from "../components/elements/BadgeComponent";
import SocialMediaComponent from "../components/elements/SocialMediaComponent";
import ProposalStatus from "../components/elements/ProposalStatus";
import { useDispatch, useSelector } from "react-redux";
import * as SelectorPropuestas from "../redux/selectors/propuestaSelector";
import * as SelectorUser from "../redux/selectors/rootSelectors";
import * as Actions from "../redux/actions/propuestaActions";
import "./propuestas.css";
import { Pagination, Col, Row, Button } from "react-bootstrap";
import { Plus } from "react-feather";
import { useHistory } from "react-router-dom";
import GeneralUrlStatic from "../consts/GeneralUrl";
import * as GAservices from "../redux/services/GoogleAnalyticsServices";

const MyProposals = () => {
  const dispatch = useDispatch();
  const myPropuestas = useSelector((state) =>
    SelectorPropuestas.myPropuestas(state)
  );
  const apoyosMaximos = useSelector((state) =>
    SelectorPropuestas.apoyosMaximosResult(state)
  );
  const history = useHistory();
  const [arrayIndicesFiltrados, setArrayIndicesFiltrados] = useState(false);

  //tamaño de la pagina
  const [tamanoPagina, setTamanoPagina] = useState(12);
  const [page, setPage] = useState(1);

  const handleSearch = (stringDeBusqueda) => {
    let busqueda = stringDeBusqueda;
    setPage(1);
    if (busqueda) {
      busqueda = busqueda.toLowerCase();
      busqueda = busqueda.trimEnd();
      let palabrasDeBusqueda = busqueda.split(" ");
      let propuestasMatch = [];
      let propuestasNoMatch = [];
      palabrasDeBusqueda.forEach((palabra, index1) => {
        myPropuestas.forEach((propuesta, index2) => {
          if (
            propuesta.titulo.toLowerCase().match(palabra) ||
            propuesta.fecha_publicacion.toLowerCase().match(palabra) ||
            propuesta.etiquetas.some((etiqueta) =>
              etiqueta.nombre.toLowerCase().match(palabra)
            )
          ) {
            if (
              !propuestasMatch.includes(index2) &&
              !propuestasNoMatch.includes(index2)
            ) {
              propuestasMatch.push(index2);
            }
          } else {
            propuestasNoMatch.push(index2);
            if (propuestasMatch.includes(index2)) {
              propuestasMatch.splice(propuestasMatch.indexOf(index2), 1);
            }
          }
        });
      });
      setArrayIndicesFiltrados(propuestasMatch);
    } else {
      setArrayIndicesFiltrados(false);
    }
  };

  const renderProgressBarComponent = (info, max) => {
    return <ProgressBarComponent now={info.apoyos} max={max} />;
  };

  const renderBadges = (badges) => {
    return badges.map((badge) => {
      return (
        <BadgeComponent className="badge-component-style" text={badge.nombre} />
      );
    });
  };

  const renderSocialMediaComponent = (paramForPath, propuesta) => {
    let hashtags = "";
    propuesta.etiquetas.forEach((etiqueta, index) => {
      if (index === 0) {
        hashtags = hashtags + etiqueta.nombre;
      } else {
        hashtags = hashtags + "," + etiqueta.nombre.trim();
      }
    });
    return (
      <SocialMediaComponent
        classNameSocialMediaDiv="social-media-div social-media-centered-text"
        classNameSocialMediaIcons="social-media-icons social-media-margin"
        paramForPath={paramForPath ? paramForPath : false}
        twitterHashtags={hashtags}
        titulo={
          propuesta.titulo
            ? "Ven y apoya la propuesta ciudadana: " + propuesta.titulo
            : "Ven y apoya la propuesta ciudadana"
        }
      />
    );
  };

  const renderProposalStatus = (info) => {
    return (
      <ProposalStatus
        background={checkBackground(info.status)}
        status={setStatus(info.status)}
      />
    );
  };

  const renderProposalNavigation = () => {
    return (
      <ProposalsNavigation
        paragraph="Inicio"
        title="Mis Propuestas"
        isHaveButton={true}
        textButton="Nueva Propuesta"
        isHaveModal={true}
        isHaveSearchInput={true}
        handleSearch={handleSearch}
        goBackRoute="/"
      />
    );
  };


  const renderProposalsCards = (aprobadas) => {
    return Array.isArray(myPropuestas)
      ? myPropuestas.filter((propuesta)=>{return(aprobadas?propuesta.status==="A":propuesta.status!="A")})
          .sort(
            (a, b) =>
              new Date(b.fecha_publicacion).getTime() -
              new Date(a.fecha_publicacion).getTime()
          )
          .slice((page - 1) * tamanoPagina, tamanoPagina * page)
          .map((info, key) => {
            if (
              Array.isArray(arrayIndicesFiltrados)
                ? arrayIndicesFiltrados.includes(key)
                : true
            ) {
              return (
                <CardComponent
                  key={key}
                  classNameLink="card-width"
                  classNameCard="card-width-under-link"
                  classNameTitle="card-big-title"
                  classNameSubtitle="subtitle-style"
                  classNameText="card-text-style"
                  classNameSecondTitle="card-second-title"
                  classNameNumberOfVotes="number-of-votes-style"
                  classNameButton="button-vote boton-ver-propuesta"
                  classNameImg="bg-contain-image card-img-top"
                  isHaveVoteSection={true}
                  title={shorten(info.titulo, 75)}
                  subtitle={info.fecha_publicacion}
                  src={info.imagen ? GeneralUrlStatic + info.imagen.substring(1) : false}
                  // src={info.imagen ? info.imagen:false}
                  text={shorten(outerHtmlToReadableString(info.contenido), 150)}
                  secondTitle={info.secondTitle}
                  progressBar={renderProgressBarComponent(info, apoyosMaximos)}
                  numberOfVotes={
                    apoyosMaximos
                      ? `${info.apoyos}/${apoyosMaximos} apoyos`
                      : ""
                  }
                  buttonText={"Ver Propuesta"}
                  badges={renderBadges(info.etiquetas)}
                  socialMedia={renderSocialMediaComponent(`/${info.id}`, info)}
                  proposalStatus={renderProposalStatus(info)}
                  isMyProposals={true}
                  disabledButton={false}
                  to={"/mis-propuestas/" + info.id}
                />
              );
            }
          })
      : null;
  };

  useEffect(() => {
    dispatch(Actions.getMyPropuestas());
    GAservices.GApageView("Mis propuestas");
  }, []);

  useEffect(() => {
    dispatch(Actions.getApoyosMaximos());
  }, [myPropuestas]);

  const disabledNext = () => {
    let num = Array.isArray(arrayIndicesFiltrados)
      ? arrayIndicesFiltrados.length
      : myPropuestas.length;

    return page * tamanoPagina >= num;
  };

  return (
    <div>
      <Helmet>
        <title>Participación Ciudadana | Mis propuestas</title>
      </Helmet>
      {renderProposalNavigation()}
      {!Array.isArray(myPropuestas) || myPropuestas.length < 1 ? (
        <Row className="emptyPropuestas">
          <Col>
            <img src={SinPropuestasSVG} alt="sin_propuestas"></img>
            <p>Aún no tienes propuestas</p>
            <Button
              size="small"
              className="boton-propuesta-nueva"
              onClick={() => history.push("/mis-propuestas-crear")}
            >
              <p>Crear propuesta</p>
              <Plus />
            </Button>
          </Col>
        </Row>
      ) : null}
            <div className="cards-content">{renderProposalsCards(true)}</div>

      <div className="cards-content">{renderProposalsCards(false)}</div>
      {myPropuestas.length > 0 ? (
        <Col style={{ justifyContent: "flex-end", display: "flex" }}>
          <Pagination>
            <Pagination.Prev
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
            />
            <Pagination.Item>{page}</Pagination.Item>
            <Pagination.Next
              disabled={disabledNext()}
              onClick={() => setPage(page + 1)}
            />
          </Pagination>
        </Col>
      ) : null}
    </div>
  );
};

export default MyProposals;
