// import React from 'react';

// import InputComponent from '../elements/InputComponent';
// import ButtonComponent from '../elements/ButtonComponent';

// import plusIcon from '../../assets/icons/plus-icon-white.png';
// import filterIcon from '../../assets/icons/filters-icon.png';
// import searchIcon from '../../assets/icons/search-icon.png';

// import './ProposalsNavigation.css';
// import DialogModalComponent from '../elements/DialogModalComponent';

// const ProposalsNavigation = (props) => {
// 	return (
// 		<div className="proposals-navigation">
// 			<div className="proposals-title-navigation">
// 				<p className="proposals-paragraph">{props.paragraph}</p>
// 				<h5 className="proposals-title">{props.title}</h5>
// 			</div>
// 			<div className="proposals-inputs">
// 				{props.isHaveFilterInput ? (
// 					<div className="proposals-filters-input">
// 						<InputComponent
// 							classNameInputIcon="style-icon-in-input"
// 							classNameInput="style-input-proposals"
// 							srcIcon={filterIcon}
// 							placeholder="Filtros"
// 						/>
// 					</div>
// 				) : (
// 					''
// 				)}
// 				{props.isHaveSearchInput ? (
// 					<div className="proposals-search-input">
// 						<InputComponent
// 							classNameInputIcon="style-icon-in-input"
// 							classNameInput="style-input-proposals"
// 							srcIcon={searchIcon}
// 							placeholder="Buscador avanzado"
// 						/>
// 					</div>
// 				) : (
// 					''
// 				)}

// 				{props.isHaveButton ? (
// 					<div className="proposals-new">
// 						<ButtonComponent
// 							classNameButton="button-vote"
// 							classNameImgAfter="proposals-image-after-style"
// 							isHaveIconAfter={true}
// 							srcAfter={plusIcon}
// 							buttonText="Nueva Solicitud"
// 							to="/mis-propuestas-crear"
// 							classNameLink=""
// 						/>
// 					</div>
// 				) : (
// 					''
// 				)}
// 				{props.isHaveModal ? <DialogModalComponent

// 				/> : ''}
// 			</div>
// 		</div>
// 	);
// };

// export default ProposalsNavigation;

import React, { Component } from "react";
import { Link } from "react-router-dom";

import InputComponent from "../elements/InputComponent";
import ButtonComponent from "../elements/ButtonComponent";

import plusIcon from "../../assets/icons/plus-icon-white.png";
import filterIcon from "../../assets/icons/filters-icon.png";
import searchIcon from "../../assets/icons/search-icon.png";

import "./ProposalsNavigation.css";
import DialogModalComponent from "../elements/DialogModalComponent";
import FiltersContentDialog from "../elements/FiltersContentDialog";

import { ChevronLeft } from "react-feather";

/**
 * @param {{isHaveButton: Boolean, isHaveFilterModal:Boolean, isHaveSearchInput:Boolean}} props
 */
class ProposalsNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
    this.onClickShow = this.onClickShow.bind(this);
    this.onClickHide = this.onClickHide.bind(this);
  }

  onClickShow() {
    console.log("CLick show");
    this.setState({ show: true });
  }

  onClickHide() {
    console.log("Click hide");
    this.setState({ show: false });
  }


  render() {
    return (
      <div className="proposals-navigation">
        <div className="proposals-title-navigation">
          <Link to={this.props.goBackRoute} className="proposals-paragraph">
            <ChevronLeft />
            {this.props.paragraph}
          </Link>
          <h5 className="proposals-title">{this.props.title}</h5>
        </div>
        <div className="proposals-inputs">
          {this.props.isHaveFilterModal ? (
            <DialogModalComponent
              classNameDivModal="proposals-filters-input"
              isHaveIconBefore={true}
              srcBefore={filterIcon}
              showButtonText="Filtros"
              classNameShowButton="custom-show-button-filtros-style"
              onClickShow={this.onClickShow}
              show={this.state.show}
              onClickHide={this.onClickHide}
              isHaveModalHeader={true}
              modalTitle="Aplicar filtros"
            >
              <FiltersContentDialog 
              filterHasArea={this.props.filterHasArea}
              setFiltros={this.props.setFiltros}
              filtros={this.props.filtros}
              onClickHide={this.onClickHide}
              />
            </DialogModalComponent>
          ) : (
            ""
          )}
          {this.props.isHaveSearchInput ? (
            <div className="proposals-search-input">
              <InputComponent
                classNameInputIcon="style-icon-in-input"
                classNameInput="style-input-proposals"
                srcIcon={searchIcon}
                placeholder="Buscador avanzado"
                handleSearch={this.props.handleSearch}
              />
            </div>
          ) : (
            ""
          )}
          {this.props.isHaveButton ? (
            <div className="proposals-new">
              <ButtonComponent
                classNameButton="button-vote boton-nueva-propuesta"
                classNameImgAfter="proposals-image-after-style"
                isHaveIconAfter={true}
                srcAfter={plusIcon}
                buttonText={
                  this.props.textButton
                    ? this.props.textButton
                    : "Nueva Solicitud"
                }
                to={
                  this.props.routeForButton
                    ? this.props.routeForButton
                    : "/mis-propuestas-crear"
                }
                classNameLink=""
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default ProposalsNavigation;
