import { call, put, select, takeLatest, fork, all } from "redux-saga/effects";
import { apiCall, token } from "../api/pwa_api";
import * as types from "../../consts/actionTypes";
import { getConversationMessages } from "../actions/conversacionesActions";
import * as Services from "../services/authServices";

/* Fetch all the conversations related to user */
export function* getConversacionesSaga({ payload }) {
  try {
    const conversations = yield call(
      apiCall,
      "api/chat/conversations",
      {},
      {
        Authorization: `Bearer  ${Services.getAccessToken()}`,
        //Host: "http://app01.pwa.expandit.io/", //"http://localhost:3000"
      },
      "GET"
    );

    yield put({
      type: types.GET_CONVERSACIONES_SUCCESS,
      conversations: conversations.data,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_REQUEST_ERROR, error });
  }
}

/* Get conversation messages  */
export function* getConversationMessagesSaga({ payload }) {
  const { conversation_id } = payload;
  try {
    const messages = yield call(
      apiCall,
      `api/chat/conversation/${conversation_id}`,
      {},
      {
        Authorization: `Bearer  ${Services.getAccessToken()}`,
        //Host: "http://app01.pwa.expandit.io/", //"http://localhost:3000"
      },
      "GET"
    );

    yield put({
      type: types.GET_CONVERSATION_MESSAGES_SUCCESS,
      messages: messages.data,
    });
  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_REQUEST_ERROR, error });
  }
}

/* Send messages */
export function* sendMessageSaga({ payload }) {
  const { content, file, encuesta, conversation } = payload;
  try {
    const message = yield call(
      apiCall,
      `api/chat/message/`,
      { content, file, encuesta, conversation },
      {
        Authorization: `Bearer  ${Services.getAccessToken()}`,
        //Host: "http://app01.pwa.expandit.io/", //"http://localhost:3000"
      },
      "POST"
    );

    yield put(getConversationMessages({ conversation_id: conversation }));
  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_REQUEST_ERROR, error });
  }
}

/* Upload converstation file saga */
export function* uploadConversationFileSaga({ payload }) {
  try {
    const { file, conversation } = payload;
    console.log(conversation);
    // Create a Form Data
    let fileFormData = new FormData();
    fileFormData.append("file", file);
    fileFormData.append("conversation", conversation);

    const attachment = yield call(
      apiCall,
      "api/chat/conversation/upload/",
      fileFormData,
      {
        Authorization: `Bearer  ${Services.getAccessToken()}`,
        //Host: "http://app01.pwa.expandit.io/", //"http://localhost:3000"
        "Content-Type": "multipart/form-data",
      },
      "POST"
    );

    // yield put({
    //   type: types.UPLOAD_CONVERSATION_FILE_SUCCESS,
    //   attachment: attachment.data,
    // });

    yield put({
      type: types.SEND_MESSAGE,
      payload:{
        content: '',
        conversation,
        file:attachment.data.id,
        active: true,
        encuesta: null,
      }
    })
  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_REQUEST_ERROR, error });
  }
}

/* REQUESTS */

// GET_CONVERSATIONS SUCCESS
function* getConversacionesRequest() {
  yield takeLatest(types.GET_CONVERSACIONES, getConversacionesSaga);
}

// Get conversation messages
function* getConversationMessagesRequest() {
  yield takeLatest(
    types.GET_CONVERSATION_MESSAGES,
    getConversationMessagesSaga
  );
}

// Receive message

// Send message
function* sendMessageRequest() {
  yield takeLatest(types.SEND_MESSAGE, sendMessageSaga);
}

// Upload File
function* uploadConversationFileRequest() {
  yield takeLatest(types.UPLOAD_CONVERSATION_FILE, uploadConversationFileSaga);
}

/* FORK */

// Wrap all request related to Conversaciones
export default function* rootConversacionesSaga() {
  yield all([
    fork(getConversacionesRequest),
    fork(getConversationMessagesRequest),
    fork(sendMessageRequest),
    fork(uploadConversationFileRequest),
  ]);
}
