import * as types from "../../consts/actionTypes";
import { ComentarioSuccessFlagToFalse } from "../actions/comentarioActions";

const initialState = {
  propuestas: [],
  propuestaResult: {},
  successFlag: false,
  myPropuestas: [],
  responsePostPropuesta: undefined,
  responsePutPropuesta: undefined,
  apoyosMaximos: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_PROPUESTAS:
      return {
        ...state,
        propuestaResult: {},
      };

    case types.GET_PROPUESTAS_SUCCESS:
      Object.assign(state.propuestas, action);
      return {
        ...state,
        propuestas: action.results.data,
      };
    case types.GET_MY_PROPUESTAS:
      Object.assign(state.propuestas, action);
      return {
        ...state,
        propuestaResult: {},
      };

    case types.GET_MY_PROPUESTAS_SUCCESS:
      Object.assign(state.propuestas, action);
      return {
        ...state,
        myPropuestas: action.results.data,
      };

    case types.GET_ONE_PROPUESTA:
      return {
        ...state,
        propuestaResult: {},
        successFlag: false,
      };

    case types.GET_ONE_PROPUESTA_SUCCESS:
      return {
        ...state,
        propuestaResult: action.results.data,
      };

    case types.POST_SUPPORT_PROPUESTA_SUCCESS:
      console.log(action.support);
      return {
        ...state,
        support: action.support.data,
        successFlag: true,
      };

    case types.GET_TENDENCIAS_PROPUESTAS_SUCCESS:
      return {
        ...state,
        tendencias: action.tendencias.data,
      };

    case types.POST_PROPUESTA_SUCCESS:
      return {
        ...state,
        successFlag: true,
        responsePostPropuesta: true,
      };
    case types.POST_PROPUESTA_ERROR:
      return {
        ...state,
        successFlag: true,
        responsePostPropuesta: false,
      };

    case types.PUT_PROPUESTA_SUCCESS:
      return {
        ...state,
        successFlag: true,
        responsePutPropuesta: true,
      };
    case types.PUT_PROPUESTA_ERROR:
      return {
        ...state,
        successFlag: true,
        responsePutPropuesta: false,
      };

    case types.PROPUESTAS_SUCCESS_FLAG_TO_FALSE:
      return {
        ...state,
        successFlag: false,
      };
    case types.GET_APOYOS_MAXIMOS_SUCCESS:
      return {
        ...state,
        apoyosMaximos: action.results.data.value,
      };
    case types.DELETE_PROPUESTA_SUCCESS:
      return {
        ...state,
      };
    case types.DELETE_PROPUESTA_ERROR:
      return {
        ...state,
      };

    default:
      return {
        ...state,
      };
  }
}
