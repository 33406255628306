export default [
  "Seguridad",
  "Salud",
  "Transporte publico",
  "Vialidad rodada",
  "Vialidad peatonal",
  "Educacion",
  "Cultura",
  "Ocio",
  "Patrimonio",
  "Economia",
  "Emprendimiento",
  "Proteccion Social",
  "Telecomunicaciones",
  "Infraestructura",
  "Empleo",
  "Institucional",
  "Deporte",
  "Inmigracion",
  "Convivencia",
  "Multiculturalidad",
  "Igualdad",
  "Inclusion",
  "Medio Ambiente"
];
