import { 
    GET_CONVERSACIONES, GET_CONVERSACIONES_SUCCESS,
    GET_CONVERSATION_MESSAGES, GET_CONVERSATION_MESSAGES_SUCCESS,
    SEND_MESSAGE, SEND_MESSAGE_SUCCESS, 
    UPLOAD_CONVERSATION_FILE, UPLOAD_CONVERSATION_FILE_SUCCESS,
    OPEN_CONVERSATION_CHANNEL, CLOSE_CONVERSATION_CHANNEL,
    RECEIVE_MESSAGE
} from '../../consts/actionTypes'

const initialState={
    conversations: [],
    conversation_index: 1,
    attachment: null,
    messages: [],
}

export default function (state = initialState, action){

    switch (action.type) {
        case GET_CONVERSACIONES:
            return{
                ...state
            }
        case GET_CONVERSACIONES_SUCCESS:
            return{
                ...state,
                conversations: action.conversations,
            }
        case GET_CONVERSATION_MESSAGES:
            return{
                ...state
            }
        case GET_CONVERSATION_MESSAGES_SUCCESS:
            return{
                ...state,
                messages: action.messages,
            }
        case SEND_MESSAGE:
            return{
                ...state
            }
        case SEND_MESSAGE_SUCCESS:
            return{
                ...state,
                file_id: null,
            }
        case UPLOAD_CONVERSATION_FILE:
            return{
                ...state
            }
        case UPLOAD_CONVERSATION_FILE_SUCCESS:
            return{
                ...state,
                attachment: action.attachment,
            }
        case OPEN_CONVERSATION_CHANNEL:
            return{
                ...state
            }
        case CLOSE_CONVERSATION_CHANNEL:
            return{
                ...state
            }
        case RECEIVE_MESSAGE:
            return{
                ...state,
                messages: [...state.messages, action.payload]
            }
    
        default:
            return{
                ...state
            }
    }

}