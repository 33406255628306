import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { AppRouter } from "./routers/AppRouter";
import configureStore from "./redux/store";
import Footer from "./components/footer/Footer"
import { initGA } from "./redux/services/GoogleAnalyticsServices"


//Google Analytics
initGA()



// store all the data for the App
const store = configureStore();
if (localStorage.getItem('access')) {

  store.dispatch({
    type: 'LOGIN_SUCCESS', tokens: {
      data:{
          access: localStorage.access,
          refresh: localStorage.refresh
      }
    }
  });
}
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.warn = () => {}
}
/**
 * Se crea el store de la aplicacion
 */

ReactDOM.render(
  <Provider store={store}>
    <AppRouter />
    {/* <Footer/> */}
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();