import * as types from "../../consts/actionTypes";
const initialState = {
  successFlag: undefined,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.POST_SOLICITUD_SIAC_SUCCESS:
      return {
        ...state,
        successFlag: true,
      };
    case types.POST_SOLICITUD_SIAC_ERROR:
      return {
        ...state,
        successFlag: false,
      };
    default:
      return { ...state };
  }
}
