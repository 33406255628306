import React, { useState } from "react";
import { Card, Badge } from "react-bootstrap";
import "./styles.css";
import { Link } from "react-router-dom";

import {
  djangoParserDeFecha,
  shorten,
  outerHtmlToReadableString,
} from "../../../utils/ComponentUtils";

import IconoCalendarioBlanco from "../../../assets/icons/calendario-blanco.svg";
import IconoUsuarioBlanco from "../../../assets/icons/usuario-blanco.svg";
import IconoExternalLinkBlanco from "../../../assets/icons/external-link-blanco.svg";

/**
 *
 * @param {{img:String, title:String, description: String, tags:Array, cardHeight:String}} props
 */
const CardFullImage = (props) => {
  const [desplegarTexto, setDesplegarTexto] = useState(false);
  return (
    <Card className="fullCard-styles" style={{ height: `${props.cardHeight}` }}>
      <Card.Body
        className="fullImage"
        style={{
          background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 28, 65, 0.5) 50.00%, #001C41 80.00%), url('${props.img}') center center/cover no-repeat`,
        }}
      >
        <div
          style={{
            bottom: 0,
            position: "absolute",
            color: "white",
            maxWidth: "calc(100% - 32px)",
          }}
        >
          <div className="title-link">
            <h4>{props.title}</h4>
            {!props.hideExternalLinkIcon && (
              <Link to={"/proyectos/" + props.idProy}>
                <div className="div-icono-link">
                  <img src={IconoExternalLinkBlanco} alt="link de proyecto" />{" "}
                </div>
              </Link>
            )}
          </div>

          <div className="div-tags-fecha-area-proyecto">
            <div className="div-tags-proyecto">
              {props.tags.map((tag, key) => {
                return (
                  <Badge pill key={key} className="badge-style">
                    {tag.nombre}
                  </Badge>
                );
              })}
            </div>
            <div className="div-fecha-proyecto">
              <img src={IconoCalendarioBlanco} alt="fecha" />
              <p>{djangoParserDeFecha(props.date)}</p>
            </div>
            <div className="div-area-proyecto">
              <img src={IconoUsuarioBlanco} alt="area" />
              <p>Ayuntamiento</p>
            </div>
          </div>
          <Card.Text className="text-container-card">
            <p id={desplegarTexto && "texto-descriptivo-tarjeta-proyecto"}>
              {shorten(
                outerHtmlToReadableString(props.description),
                desplegarTexto ? undefined : 250
              )}
              {"    "}
              {outerHtmlToReadableString(props.description).length > 250 && (
                <span
                  className="ver-mas-desplegar-texto"
                  onClick={() => setDesplegarTexto(!desplegarTexto)}
                >
                  {desplegarTexto ? "Ver menos" : "Ver más"}
                </span>
              )}
            </p>
          </Card.Text>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CardFullImage;
