import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../api/pwa_api";
import * as types from "../../consts/actionTypes";
import * as Services from "../services/authServices";
const token = localStorage.getItem("access");

export function* getEncuestas({ payload }) {
  console.log("pidiendo info...");
  try {
    let encuestas = yield call(
      apiCall,
      "encuesta/encuestas",
      {},
      {
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "GET"
    );

    let encuestasVencidas = yield call(
      apiCall,
      "encuesta/detalle/encuesta/?detalle=vencidas",
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );

    encuestas = encuestas.data;
    encuestasVencidas = encuestasVencidas.data;

    Array.prototype.push.apply(encuestas, encuestasVencidas);

    console.log("info obtenida");
    yield put({ type: types.GET_ENCUESTAS_SUCCESS, encuestas });
  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_REQUEST_ERROR, error });
  }
}

export function* getPollDetails({ payload }) {
  try {
    console.log(payload);
    const result = yield call(
      apiCall,
      "encuesta/detalle/encuesta/?detalle=encuesta&referencias=[" +
        payload +
        "]",
      {},
      {
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "GET"
    );

    yield put({ type: types.GET_ENCUESTAS_DETAILS_SUCCESS, result });
  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_REQUEST_ERROR, error });
  }
}

export function* postVotacionUser({ payload }) {
  try {
    const result = yield call(
      apiCall,
      "encuesta/encuestas-user/",
      {
        encuesta: payload.encuesta,
        respuestas_encuesta: payload.respuestas,
      },
      {
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );

    yield put({ type: types.POST_VOTACION_USER_SUCCESS, result });
  } catch (error) {
    console.log(error);
    yield put({ type: types.POST_VOTACION_USER_ERROR, error });
  }
}

export default function* votacionSaga() {
  yield takeLatest(types.GET_ENCUESTAS, getEncuestas);
  yield takeLatest(types.GET_ENCUESTAS_DETAILS, getPollDetails);
  yield takeLatest(types.POST_VOTACION_USER, postVotacionUser);
}
