import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import {
  PROPOSALS_CARD,
  BADGES,
} from "../components/constants/ProposalsConstants";

import {
  shorten,
  outerHtmlToReadableString,
  checkBackground,
  setStatus,
} from "../utils/ComponentUtils";
import { Pagination, Col } from "react-bootstrap";

import CardComponent from "../components/card-proposals/CardComponent";
import ProgressBarComponent from "../components/elements/ProgressBarComponent";
import BadgeComponent from "../components/elements/BadgeComponent";
import ProposalsNavigation from "../components/proposals-navigation/ProposalsNavigation";
import SocialMediaComponent from "../components/elements/SocialMediaComponent";
import ProposalStatus from "../components/elements/ProposalStatus";
import { useDispatch, useSelector } from "react-redux";
import * as Selector from "../redux/selectors/propuestaSelector";
import * as Actions from "../redux/actions/propuestaActions";
import * as rootSelector from "../redux/selectors/rootSelectors";
import baseUrl from "../consts/GeneralUrl";
import * as GAservices from "../redux/services/GoogleAnalyticsServices";
import "./propuestas.css";

const Propuestas = () => {
  const dispatch = useDispatch();
  const [filtros, setFiltros] = useState(false);
  const [arrayIndicesFiltrados, setArrayIndicesFiltrados] = useState(false);
  const propuestas = useSelector((state) => Selector.propuestas(state));
  const apoyosMaximos = useSelector((state) =>
    Selector.apoyosMaximosResult(state)
  );
  const user = useSelector((state) => rootSelector.user(state));

  //tamaño de la pagina
  const [tamanoPagina, setTamanoPagina] = useState(12);
  const [page, setPage] = useState(1);

  const filterFunction = (elemento) => {
    let area = true;
    let etiquetas = true;
    let antiguedad = true;
    if (filtros === false) {
      return true;
    }
    if (elemento) {
      if (filtros.area) {
        if (filtros.area === elemento.area) {
          area = true;
        } else {
          area = false;
        }
      }
      if (
        filtros.etiquetas && elemento.etiquetas
          ? filtros.etiquetas.length > 0
          : false
      ) {
        if (
          filtros.etiquetas.every((etiq) =>
            elemento.etiquetas.some(
              (etiq2) =>
                etiq2.nombre.toLowerCase().trim() === etiq.toLowerCase().trim() //MUST FIX
            )
          )
        ) {
          etiquetas = true;
        } else {
          etiquetas = false;
        }
      }
      if (filtros.antiguedad > 0 && typeof filtros.antiguedad === "number") {
        if (
          new Date(elemento.fecha_publicacion) >
          new Date(Date.now() - filtros.antiguedad * 24 * 60 * 60 * 1000)
        ) {
          antiguedad = true;
        }
      } else if (filtros.antiguedad === 0) {
        antiguedad = true;
      } else if (filtros.antiguedad === "personalizado") {
        if (
          new Date(elemento.fecha_publicacion) >= filtros.inicio && //MUST FIX
          new Date(elemento.fecha_publicacion) <= filtros.fin
        ) {
          antiguedad = true;
        } else {
          antiguedad = false;
        }
      } else {
        antiguedad = false;
      }
      if (area && etiquetas && antiguedad) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    dispatch(Actions.getPropuestas());
    GAservices.GApageView("Propuestas Ciudadanas");
  }, []);

  useEffect(() => {
    dispatch(Actions.getApoyosMaximos());
  }, [propuestas]);

  useEffect(() => {
    if (filtros) {
      setPage(1);
    }
  }, [filtros]);

  const renderProgressBarComponent = (info, max) => {
    return <ProgressBarComponent now={info.apoyos} max={max} />;
  };

  const renderBadges = (badges) => {
    return badges.map((badge) => {
      return (
        <BadgeComponent className="badge-component-style" text={badge.nombre} />
      );
    });
  };

  const renderSocialMediaComponent = (paramForPath, propuesta) => {
    let hashtags = "";
    propuesta.etiquetas.forEach((etiqueta, index) => {
      if (index === 0) {
        hashtags = hashtags + etiqueta.nombre;
      } else {
        hashtags = hashtags + "," + etiqueta.nombre.trim();
      }
    });
    return (
      <SocialMediaComponent
        classNameSocialMediaDiv="social-media-div social-media-centered-text"
        classNameSocialMediaIcons="social-media-icons social-media-margin"
        paramForPath={paramForPath ? paramForPath : false}
        twitterHashtags={hashtags}
        titulo={
          propuesta.titulo
            ? "Ven y apoya la propuesta ciudadana: " + propuesta.titulo
            : "Ven y apoya la propuesta ciudadana"
        }
      />
    );
  };

  const renderProposalStatus = (info) => {
    return (
      <ProposalStatus
        background={checkBackground(info.status)}
        status={setStatus(info.status)}
      />
    );
  };

  const handleSearch = (stringDeBusqueda) => {
    let busqueda = stringDeBusqueda;
    if (busqueda) {
      setPage(1);
      busqueda = busqueda.toLowerCase();
      busqueda = busqueda.trimEnd();
      let palabrasDeBusqueda = busqueda.split(" ");
      let propuestasMatch = [];
      let propuestasNoMatch = [];
      palabrasDeBusqueda.forEach((palabra, index1) => {
        propuestas.forEach((propuesta, index2) => {
          if (
            propuesta.titulo.toLowerCase().match(palabra) ||
            propuesta.fecha_publicacion.toLowerCase().match(palabra) ||
            propuesta.etiquetas.some((etiqueta) =>
              etiqueta.nombre.toLowerCase().match(palabra)
            )
          ) {
            if (
              !propuestasMatch.includes(index2) &&
              !propuestasNoMatch.includes(index2)
            ) {
              propuestasMatch.push(index2);
            }
          } else {
            propuestasNoMatch.push(index2);
            if (propuestasMatch.includes(index2)) {
              propuestasMatch.splice(propuestasMatch.indexOf(index2), 1);
            }
          }
        });
      });
      setArrayIndicesFiltrados(propuestasMatch);
    } else {
      setArrayIndicesFiltrados(false);
    }
  };

  const disabledNext = () => {
    let num = Array.isArray(arrayIndicesFiltrados)
      ? arrayIndicesFiltrados.length
      : propuestas.length;

    return page * tamanoPagina >= num;
  };

  const renderProposalNavigation = () => {
    return (
      <ProposalsNavigation
        paragraph="Inicio"
        title="Propuestas ciudadanas"
        textButton="Nueva Propuesta"
        isHaveFilterModal={true}
        isHaveSearchInput={true}
        isHaveButton={true}
        setFiltros={setFiltros}
        handleSearch={handleSearch}
        filtros={filtros}
        goBackRoute={"/"}
      />
    );
  };

  const renderProposalsCards = () => {
    console.log(propuestas);
    return propuestas
      .sort(
        (a, b) =>
          new Date(b.fecha_publicacion).getTime() -
          new Date(a.fecha_publicacion).getTime()
      )
      .slice((page - 1) * tamanoPagina, tamanoPagina * page)
      .map((info, key) => {
        if (
          info.status !== "I" &&
          info.status !== "B" &&
          info.status !== "NP" &&
          info.status !== "ER" &&
          filterFunction(info) &&
          (Array.isArray(arrayIndicesFiltrados)
            ? arrayIndicesFiltrados.includes(key)
            : true)
        ) {
          return (
            <CardComponent
              classNameLink="card-width"
              classNameCard="card-width-under-link"
              classNameTitle="card-big-title"
              classNameSubtitle="subtitle-style"
              classNameText="card-text-style"
              classNameSecondTitle="card-second-title"
              classNameNumberOfVotes="number-of-votes-style"
              classNameButton="button-vote boton-ver-propuesta"
              classNameImg="bg-contain-image card-img-top"
              isHaveVoteSection={true}
              title={shorten(info.titulo, 75)}
              subtitle={info.fecha_publicacion}
              src={info.imagen ? baseUrl + info.imagen.substring(1) : false}
              //src={info.imagen ? info.imagen : false}              
              text={shorten(outerHtmlToReadableString(info.contenido), 150)}
              secondTitle={info.secondTitle}
              progressBar={renderProgressBarComponent(info, apoyosMaximos)}
              numberOfVotes={
                apoyosMaximos ? `${info.apoyos}/${apoyosMaximos} apoyos` : ""
              }
              buttonText={"Ver Propuesta"}
              badges={renderBadges(info.etiquetas)}
              proposalStatus={renderProposalStatus(info)}
              socialMedia={renderSocialMediaComponent(`/${info.id}`, info)}
              disabledButton={false}
              showAuthor={true}
              autor={info.nombre + " " + info.apellido}
              to={"/propuestas/" + info.id}
              key={key}
              activo={user? user.id:"" === info.usuario}
            />
          );
        }
      });
  };

  return (
    <div>
      <Helmet>
        <title>Participación Ciudadana | Propuestas Ciudadanas</title>
      </Helmet>
      {renderProposalNavigation()}
      <div className="cards-content">{renderProposalsCards()}</div>
      {propuestas.length > 0 ? (
        <Col style={{ justifyContent: "flex-end", display: "flex" }}>
          <Pagination>
            <Pagination.Prev
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
            />
            <Pagination.Item>{page}</Pagination.Item>
            <Pagination.Next
              disabled={disabledNext()}
              onClick={() => setPage(page + 1)}
            />
          </Pagination>
        </Col>
      ) : null}
    </div>
  );
};

export default Propuestas;
