import * as types from "../../consts/actionTypes"

export const getTendenciasProyectos = (payload) =>{
    return{
        type: types.GET_TENDENCIAS_PROYECTOS,
        payload
    }
}

export const getProyectos = (payload) =>{
    return{
        type: types.GET_PROYECTOS,
        payload
    }
}

export const getOneProyecto = (payload) =>{
    return{
        type: types.GET_ONE_PROYECTO,
        payload
    }
}