import * as types from "../../consts/actionTypes";
import { getUser } from "../services/authServices";

// let user = JSON.parse(localStorage.getItem("user"));
const initialState = {
  user: getUser(),
  logged: false,
  isValid: false,
  tokenAccess:
    localStorage.getItem("access") !== null
      ? localStorage.getItem("access")
      : "",
  tokenRefresh:
    localStorage.getItem("refresh") !== null
      ? localStorage.getItem("refresh")
      : "",
  registerSuccess: null,
  registerError: "",
  error: "",
  politicasPrivacidad: null,
  politicasAceptadas: false,
  videoAceptado: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.LOGIN:
      return {
        ...state,
        logged: true,
        isValid: true,
      };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        tokenAccess: action.tokens.data.access,
        tokenRefresh: action.tokens.data.refresh,
        logged: true,
        isValid: true,
        error: "",
      };

    case types.VERIFY_TOKEN:
      return {
        ...state,
        // isValid: true
      };

    case types.VALID_TOKEN:
      Object.assign(state.isValid, true);
      return {
        ...state,
        isValid: true,
        logged: true,
      };
    case types.INVALID_TOKEN:
      Object.assign(state.isValid, false);
      return {
        ...state,
        isValid: false,
        logged: true,
      };

    case types.REFRESH_TOKEN:
      return {
        ...state,
      };

    case types.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        tokenAccess: action.access,
      };

    case types.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        user: action.userInfo.data[0],
      };

    case types.LOGIN_ERROR:
      return {
        ...state,
        logged: false,
        error: "DNI o Contraseña incorrecta",
      };
    case types.REGISTER_STATE_RESET:
      return {
        ...state,
        registerSuccess: null,
        registerError: "",
      };

    case types.REGISTER_SUCCESS:
      console.log(action.register.data);
      return {
        ...state,
        registerSuccess: true,
      };
    case types.REGISTER_FAIL:
      return {
        ...state,
        registerSuccess: false,
        registerError: action.error,
      };

    case types.LOGOUT:
      localStorage.clear();
      return {
        ...state,
        user: false,
        logged: false,
        tokenAccess: null,
        tokenRefresh: null,
      };

    case types.GET_POLITICAS_PRIVACIDAD_SUCCESS:
      return {
        ...state,
        politicasPrivacidad: action.results.data,
      };

    case types.POST_ACEPTA_POLITICAS_PRIVACIDAD_SUCCESS:
      return {
        ...state,
        politicasAceptadas: true,
      };

    case types.POST_ACEPTA_VIDEO_TUTORIAL:
      return {
        ...state,
        videoAceptado: true,
      };
    case types.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        emailVerificado: true,
      };
    case types.VERIFY_EMAIL_ERROR:
      return {
        ...state,
        emailVerificado: false,
      };
    default:
      return { ...state };
  }
}
