import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
//Components
import ProposalsNavigation from "../../components/proposals-navigation/ProposalsNavigation";
import CardComunicados from "../../components/card-proposals/CardComunicados";
import BadgeComponent from "../../components/elements/BadgeComponent";
import SocialMediaComponent from "../../components/elements/SocialMediaComponent";
import CardFullImage from "../../components/card-proposals/CardFullImage/CardFullImage";
//Constants
import { BADGES } from "../../components/constants/ProposalsConstants";
import { fakeProyectos } from "./fakeProyectos";
import { fakeActions } from "../Acciones/fakeAcciones";
import { useEffect, useState } from "react";
import * as Actions from "../../redux/actions/proyectoActions";
import * as Selector from "../../redux/selectors/proyectoSelectors";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  shorten,
  outerHtmlToReadableString,
  djangoParserDeFecha,
} from "../../utils/ComponentUtils";
import "./Proyectos.css";
import BaseUrl from '../../consts/GeneralUrlStatic'

const DetalleProyectos = () => {
  const { idProyecto } = useParams();

  const dispatch = useDispatch();
  const [arrayIndicesFiltrados, setArrayIndicesFiltrados] = useState(false);
  const [filtros, setFiltros] = useState(false);
  const proyectoInfo = useSelector((state) => Selector.oneProyecto(state));

  const filterFunction = (elemento) => {
    let area = true;
    let etiquetas = true;
    let antiguedad = true;
    if (filtros === false) {
      return true;
    }
    if (elemento) {
      if (filtros.area) {
        if (filtros.area === elemento.area) {
          area = true;
        } else {
          area = false;
        }
      }
      if (
        filtros.etiquetas && elemento.etiquetas
          ? filtros.etiquetas.length > 0
          : false
      ) {
        if (
          filtros.etiquetas.every((etiq) =>
            elemento.etiquetas.some(
              (etiq2) =>
                etiq2.nombre.toLowerCase().trim() === etiq.toLowerCase().trim() //MUST FIX
            )
          )
        ) {
          etiquetas = true;
        } else {
          etiquetas = false;
        }
      }
      if (filtros.antiguedad > 0 && typeof filtros.antiguedad === "number") {
        if (
          new Date(elemento.fecha) >
          new Date(Date.now() - filtros.antiguedad * 24 * 60 * 60 * 1000)
        ) {
          antiguedad = true;
        }
      } else if (filtros.antiguedad === 0) {
        antiguedad = true;
      } else if (filtros.antiguedad === "personalizado") {
        if (
          new Date(elemento.fecha) >= filtros.inicio && //MUST FIX
          new Date(elemento.fecha) <= filtros.fin
        ) {
          antiguedad = true;
        } else {
          antiguedad = false;
        }
      } else {
        antiguedad = false;
      }
      if (area && etiquetas && antiguedad) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleSearch = (stringDeBusqueda) => {
    let busqueda = stringDeBusqueda;
    if (busqueda) {
      busqueda = busqueda.toLowerCase();
      busqueda = busqueda.trimEnd();
      let palabrasDeBusqueda = busqueda.split(" ");
      let propuestasMatch = [];
      let propuestasNoMatch = [];
      palabrasDeBusqueda.forEach((palabra, index1) => {
        proyectoInfo.comunicados.forEach((propuesta, index2) => {
          if (
            propuesta.titulo.toLowerCase().match(palabra) ||
            djangoParserDeFecha(propuesta.fecha).toLowerCase().match(palabra) ||
            propuesta.etiquetas.some((etiqueta) =>
              etiqueta.nombre.toLowerCase().match(palabra)
            )
          ) {
            if (
              !propuestasMatch.includes(index2) &&
              !propuestasNoMatch.includes(index2)
            ) {
              propuestasMatch.push(index2);
            }
          } else {
            propuestasNoMatch.push(index2);
            if (propuestasMatch.includes(index2)) {
              propuestasMatch.splice(propuestasMatch.indexOf(index2), 1);
            }
          }
        });
      });
      setArrayIndicesFiltrados(propuestasMatch);
    } else {
      setArrayIndicesFiltrados(false);
    }
  };

  const renderProposalNavigation = () => {
    return (
      <ProposalsNavigation
        paragraph="Inicio"
        title="Proyectos"
        isHaveFilterModal={true}
        isHaveSearchInput={true}
        filterHasArea={true}
        handleSearch={handleSearch}
        setFiltros={setFiltros}
        filtros={filtros}
      />
    );
  };

  const renderBadges = (etiquetas) => {
    return etiquetas.map((badge) => {
      return (
        <BadgeComponent className="badge-component-style" text={badge.nombre} />
      );
    });
  };

  const renderSocialMediaComponent = (paramForPath, accion) => {
    let hashtags = "";
    accion.etiquetas.forEach((etiqueta, index) => {
      if (index === 0) {
        hashtags = hashtags + etiqueta.nombre;
      } else {
        hashtags = hashtags + "," + etiqueta.nombre.trim();
      }
    });
    return (
      <SocialMediaComponent
        classNameSocialMediaDiv="social-media-div social-media-centered-text"
        classNameSocialMediaIcons="social-media-icons social-media-margin"
        paramForPath={paramForPath ? paramForPath : false}
        twitterHashtags={hashtags}
        titulo={accion.titulo
          ? "Ven y apoya la acción del Participación Ciudadana: " + accion.titulo
          : "Ven y apoya la acción del Participación Ciudadana"}
      />
    );
  };

  useEffect(() => {
    dispatch(Actions.getOneProyecto(idProyecto));
    document.getElementById("root").scrollTop = 0;
  }, []);

  return (
    <div>
      {proyectoInfo && (
        <Helmet>
          <title>Participación Ciudadana | {`${proyectoInfo.nombre}`}</title>
        </Helmet>
      )}
      {renderProposalNavigation()}
      <Container fluid>
        {proyectoInfo ? (
          <div>
            <CardFullImage
              img={proyectoInfo.img}
              title={proyectoInfo.nombre}
              hideExternalLinkIcon={true}
              tags={proyectoInfo.etiquetas}
              date={proyectoInfo.fecha_creacion}
              area={proyectoInfo.area}
              description={proyectoInfo.descripcion}
              cardHeight={"23em"}
            />
            <p className="titulo-acciones-del-proyecto">
              Acciones del Proyecto
            </p>
            {proyectoInfo.comunicados.map((item, key) => {
              if (
                item.status !== "I" &&
                item.status !== "B" &&
                item.status !== "I" &&
                item.status !== "NP" &&
                item.status !== "ER" &&
                filterFunction(item) &&
                (Array.isArray(arrayIndicesFiltrados)
                  ? arrayIndicesFiltrados.includes(key)
                  : true)
              ) {
                return (
                  <CardComunicados
                    to={`/acciones/${item.id}`}
                    classnameLink="link-styles"
                    title={item.titulo}
                    subtitle={item.fecha}
                    area={proyectoInfo.area}
                    numComentarios={item.comentarios.length}
                    encuesta={item.encuesta}
                    description={shorten(
                      outerHtmlToReadableString(item.quill_html),
                      500
                    )}
                    imageCard={BaseUrl+item.img}
                    badges={renderBadges(item.etiquetas)}
                    classNameSocialMedia="socialMedia"
                    socialMedia={renderSocialMediaComponent(
                      `/${item.id}`,
                      item
                    )}
                  />
                );
              }
            })}
          </div>
        ) : null}
      </Container>
    </div>
  );
};

export default DetalleProyectos;
