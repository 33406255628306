import flowerImage from '../../assets/images/Rectangle 151.png';
import treeImage from '../../assets/images/Rectangle 181.png';
import waterImage from '../../assets/images/Rectangle 184.png';

export const PROPOSALS_CARD = [
	{
		id: '1',
		title:
			'Creación de un consejo ciudadano para cuidar el ambiente en Pica. Creación de un consejo ciudadano para cuidar el ambiente en Pica',
		subtitle: '12 de Junio e 2020',
		src: flowerImage,
		text:
			'Creación de un consejo ciudadano para cuidar el ambiente en Pica. Creación de un consejo ciudadano para cuidar el ambiente en Pica. Creación de un consejo ciudadano para cuidar el ambiente en Pica. Creación de un consejo ciudadano para cuidar el ambiente en Pica',
		secondTitle: 'Progreso',
		numberOfVotes: '990/3,300 votos',
		buttonText: 'Apoyar',
		progress: '30'
	},
	{
		id: '2',
		title: 'Evitar la explotación de recursos naturales en Colchane',
		subtitle: '12 de Junio e 2020',
		src: treeImage,
		text:
			'Le exigimimos al gobierno crear una comitiva de funcionarios para cuidar la actividad de distintas empresas que están sobreexplotando recursos naturales en Colchane.',
		secondTitle: 'Progreso',
		numberOfVotes: '1980/3,300 votos',
		buttonText: 'Apoyar',
		progress: '60'
	},
	{
		id: '1',
		title: 'Huara necesita resolver sus problemas por las lluvias',
		subtitle: '12 de Junio e 2020',
		src: waterImage,
		text:
			'Cada que llueve, la comunidad de Huara sufre inundaciones importantes... Por lo cual, exigimos al gobierno un plan de acción para resolver este problema.',
		secondTitle: 'Progreso',
		numberOfVotes: '2,970/3,300 votos',
		buttonText: 'Apoyar',
		progress: '90'
	},
	{
		id: '1',
		title: 'Creación de un consejo ciudadano para cuidar el ambiente en Pica',
		subtitle: '12 de Junio e 2020',
		src: flowerImage,
		text:
			'Creación de un consejo ciudadano para cuidar el ambiente en Pica. Creación de un consejo ciudadano para cuidar el ambiente en Pica.',
		secondTitle: 'Progreso',
		numberOfVotes: '0/3,300 votos',
		buttonText: 'Apoyar',
		progress: '0'
	}
];

export const MY_PROPOSALS_INFO = [
	{
		id: '1',
		title:
			'Creación de un consejo ciudadano para cuidar el ambiente en Pica. Creación de un consejo ciudadano para cuidar el ambiente en Pica',
		subtitle: '12 de Junio e 2020',
		src: flowerImage,
		text:
			'Creación de un consejo ciudadano para cuidar el ambiente en Pica. Creación de un consejo ciudadano para cuidar el ambiente en Pica. Creación de un consejo ciudadano para cuidar el ambiente en Pica. Creación de un consejo ciudadano para cuidar el ambiente en Pica',
		secondTitle: 'Progreso',
		numberOfVotes: '990/3,300 votos',
		buttonText: 'Apoyar',
		progress: '30'
	},
	{
		id: '2',
		title: 'Evitar la explotación de recursos naturales en Colchane',
		subtitle: '12 de Junio e 2020',
		src: treeImage,
		text:
			'Le exigimimos al gobierno crear una comitiva de funcionarios para cuidar la actividad de distintas empresas que están sobreexplotando recursos naturales en Colchane.',
		secondTitle: 'Progreso',
		numberOfVotes: '1980/3,300 votos',
		buttonText: 'Apoyar',
		progress: '60'
	},
	{
		id: '1',
		title: 'Huara necesita resolver sus problemas por las lluvias',
		subtitle: '12 de Junio e 2020',
		src: waterImage,
		text:
			'Cada que llueve, la comunidad de Huara sufre inundaciones importantes... Por lo cual, exigimos al gobierno un plan de acción para resolver este problema.',
		secondTitle: 'Progreso',
		numberOfVotes: '2,970/3,300 votos',
		buttonText: 'Apoyar',
		progress: '90'
	},
	{
		id: '1',
		title: 'Creación de un consejo ciudadano para cuidar el ambiente en Pica',
		subtitle: '12 de Junio e 2020',
		src: flowerImage,
		text:
			'Creación de un consejo ciudadano para cuidar el ambiente en Pica. Creación de un consejo ciudadano para cuidar el ambiente en Pica.',
		secondTitle: 'Progreso',
		numberOfVotes: '0/3,300 votos',
		buttonText: 'Apoyar',
		progress: '0',
		status: 'Rechazada'
	}
];

export const MIS_SOLICITUDES_CARD = [
	{
		id: '1',
		title:
			'Creación de un consejo ciudadano para cuidar el ambiente en Pica. Creación de un consejo ciudadano para cuidar el ambiente en Pica',
		subtitle: '12 de Junio e 2020',
		src: flowerImage,
		text:
			'Creación de un consejo ciudadano para cuidar el ambiente en Pica. Creación de un consejo ciudadano para cuidar el ambiente en Pica. Creación de un consejo ciudadano para cuidar el ambiente en Pica. Creación de un consejo ciudadano para cuidar el ambiente en Pica'
	},
	{
		id: '2',
		title: 'Creación de un consejo ciudadano para cuidar el ambiente en Pica',
		subtitle: '12 de Junio e 2020',
		src: flowerImage,
		text:
			'Creación de un consejo ciudadano para cuidar el ambiente en Pica. Creación de un consejo ciudadano para cuidar el ambiente en Pica.'
	},
	{
		id: '1',
		title: 'Creación de un consejo ciudadano para cuidar el ambiente en Pica',
		subtitle: '12 de Junio e 2020',
		src: flowerImage,
		text:
			'Creación de un consejo ciudadano para cuidar el ambiente en Pica. Creación de un consejo ciudadano para cuidar el ambiente en Pica.'
	},
	{
		id: '1',
		title: 'Creación de un consejo ciudadano para cuidar el ambiente en Pica',
		subtitle: '12 de Junio e 2020',
		src: flowerImage,
		text:
			'Creación de un consejo ciudadano para cuidar el ambiente en Pica. Creación de un consejo ciudadano para cuidar el ambiente en Pica.'
	}
];

export const BADGES = [ { id: '1', text: 'Ambiente' }, { id: '2', text: 'Pica' }, { id: '3', text: 'Comunidad' } ];

export const COMENTARIOS =[
	{
		id: 1,
		user: "El Ferras",
		date: "13 de junio de 2020 a las 16:05",
		content: "Hola pequeño",
		respuestas:[
			{
				id: 1,
				content: "Que pez!"
			}
		]
	},
	{
		id: 2,
		user: "Don Peperoni",
		date: "13 de junio de 2020 a las 16:05",
		content: "Pizza Pizza",
		respuestas:[
			{
				id: 1,
				content: "Estan Wenas"
			},
			{
				id: 2,
				content: "Si que si!"
			}
		]
	},
	{
		id: 1,
		user: "Pedro Picapiedra",
		date: "13 de junio de 2020 a las 16:05",
		content: "Yabadabadu",
		respuestas:[
			{
				id: 1,
				content: "Muebe la broncogrua!"
			}
		]
	},
]