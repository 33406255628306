import React from "react";
import { makeStyles } from "@material-ui/core";
import { Tabs, Tab } from "react-bootstrap";
import "../styles/variables.css";
import ListConversations from "./ListConversations";
import { Inbox, Archive } from "react-feather";

const styles = makeStyles((theme) => ({
  wrapperList: {
    backgroundColor: "var(--list-section-color)",
    minWidth: "320px",
    position: "static",
    display: "flex",
    flexDirection: "column",
    boxShadow: "-2px 0px 10px rgba(0, 0, 0, 0.03)",
  },
  header: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    height: "64px",
  },
  link: {
    width: "fit-content",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignSelf: "center",
  },
  archiveButton: {
    cursor: "pointer",
    color: "#2475B9",
    border: "none",
    backgroundColor: "inherit",
    outline: "none",
    fontSize: "14px",
  },
}));

const List = ({
  personInfo,
  id,
  handleArchive,
  isArchive,
  conversations,
  conversation_id,
  showList,
}) => {
  const classes = styles();

  return (
    <div
      className={classes.wrapperList}
      id="lista-chats-conversaciones"
    >
      <Tabs className="tabs-chat">
        <Tab
          className="tab-chat"
          style={{    maxHeight: "calc(100vh - 266px)",
          overflow: "auto",}}
          eventKey={1}
          title={
            <span>
              <Inbox /> {"Recientes"}
            </span>
          }
          onClick={() => handleArchive(false)}
        >
          <ListConversations
            personInfo={personInfo}
            conversation_id={conversation_id}
            isArchive={false}
            conversations={conversations}
          />
        </Tab>
        <Tab
          className="tab-chat"
          eventKey={2}
          title={
            <span>
              <Archive /> {"Archivadas"}
            </span>
          }
          onClick={() => handleArchive(true)}
        >
          <ListConversations
            personInfo={personInfo}
            conversation_id={conversation_id}
            isArchive={true}
            conversations={conversations}
          />
        </Tab>
      </Tabs>
    </div>
  );
};
export default List;
