import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Selector from "../../redux/selectors/rootSelectors";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import PersonaInfoBlock from "./PersonaInfoBlock";
import DefaultAvatar from "../../assets/images/conversations/avatar.png";
import Questionnaire from "./Questionnaires/Questionnaire";
import QuestionnaireMultiply from "./Questionnaires/QuestionnaireMultiply";
import PreQuestionnaire from "./Questionnaires/PreQuestionnaire";
import MultilineTextFields from "./Questionnaires/MultilineTextFields";
import MessagesRender from "./MessagesRender";
import decode from "../../utils/decode";
import generalUrl from "../../consts/GeneralUrl";

const useStyles = makeStyles((theme) => ({
  chatWrapper: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px",
  },
  reverseWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column-reverse",
    overflowY: " auto",
  },
  messageBox: {
    border: "1px solid #ded5ff",
    width: "fit-content",
    padding: "20px",
    color: "var(--chat-person-color)",
    boxShadow: "0px 5px 25px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    maxWidth: "300px",
  },
  receivedMessage: {
    display: "flex",
    justifyContent: "flex-start",
  },
  sentMessage: {
    display: "flex",
    justifyContent: "flex-end",
  },
  questionnaireTextWrapper: {
    display: "flex",
    alignItems: "center",
  },
  chatIconBox: {
    width: "40px",
    height: "40px",
    backgroundColor: "var(--chat-main-color)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginRight: "20px",
    color: "white",
  },
  questionnaireText: {
    color: "var(--chat-main-color)",
    "& p": {
      margin: "10px  0",
    },
  },
}));

const QuestionnaireWrapper = ({
  message,
  children,
  questionnaire,
  questionnaireMultiply,
  multilineField,
}) => {
  const classes = useStyles();

  return (
    <>
      <PreQuestionnaire
        questionnaire={questionnaire}
        questionnaireMultiply={questionnaireMultiply}
        multilineField={multilineField}
      />
      <div className={classes.messageBox}>{message}</div>
      <div className={classes.questionnaireWrapper}>{children}</div>
    </>
  );
};

const Chat = ({ messages, personInfo }) => {
  const classes = useStyles();

  // Get the user id from the auth reducer by decoding the token
  const userInfo = useSelector((state) => Selector.user(state));
  const authState = useSelector((state) => state.AuthReducer);
  const { tokenAccess } = authState;

  /* DISABLED - ONLY USING MESSAGE TO MESSAGE */
  /*   const isMultiquestion =
    questionnaire && questionnaireMultiply && multilineField;
  const [answersCount, setAnswersCount] = useState(0);
  const handleAnswer = useCallback(() => {
    if (answersCount < 2) {
      setAnswersCount(answersCount + 1);
    }
  }, [answersCount]); */

  return (
    <div className={classes.reverseWrapper}>
      <div className={classes.chatWrapper}>
        {/* DISABLED - ONLY USING MESSAGE TO MESSAGE */}
        {/* <div>
          {!questionnaire && !questionnaireMultiply && !multilineField && (
            <div className={classes.messageBox}>{message}</div>
          )}

          {isMultiquestion && (
            <>
              {answersCount === 0 && (
                <QuestionnaireWrapper
                  message={message}
                  questionnaire={questionnaire}
                >
                  <Questionnaire
                    personInfo={personInfo}
                    onAnswer={handleAnswer}
                  />
                </QuestionnaireWrapper>
              )}
              {answersCount === 1 && (
                <QuestionnaireWrapper
                  message={message}
                  questionnaireMultiply={questionnaireMultiply}
                >
                  <QuestionnaireMultiply
                    personInfo={personInfo}
                    onAnswer={handleAnswer}
                  />
                </QuestionnaireWrapper>
              )}
              {answersCount >= 2 && (
                <QuestionnaireWrapper
                  message={message}
                  multilineField={multilineField}
                >
                  <MultilineTextFields
                    personInfo={personInfo}
                    onAnswer={handleAnswer}
                  />
                </QuestionnaireWrapper>
              )}
            </>
          )}

          {questionnaire && !isMultiquestion && (
            <QuestionnaireWrapper message={message} questionnaire={questionnaire}>
              <Questionnaire personInfo={personInfo} />
            </QuestionnaireWrapper>
          )}
          {questionnaireMultiply && !isMultiquestion && (
            <QuestionnaireWrapper
              message={message}
              questionnaireMultiply={questionnaireMultiply}
            >
              <QuestionnaireMultiply personInfo={personInfo} />
            </QuestionnaireWrapper>
          )}
          {multilineField && !isMultiquestion && (
            <QuestionnaireWrapper
              message={message}
              multilineField={multilineField}
            >
              <MultilineTextFields personInfo={personInfo} />
            </QuestionnaireWrapper>
          )}
          <PersonaInfoBlock name={name} time={time} avatar={avatar} />
        </div> */}

        {userInfo &&
          messages
            .filter((item) => !!item)
            .map((item, index) => {
              const has_image =
                item.file &&
                item.file.file &&
                item.file.file.includes("images");
              const has_pdf =
                item.file && item.file.file && item.file.file.includes("pdf");
              const is_message_owner =
                item.user.id === userInfo.user.id ||
                (item.user.first_name === userInfo.user.first_name &&
                  item.user.last_name === userInfo.user.last_name);
              return (
                <div
                  key={index}
                  className={
                    is_message_owner
                      ? classes.sentMessage
                      : classes.receivedMessage
                  }
                >
                  <div style={{ marginBottom: "20px" }}>
                    <MessagesRender
                      item={item}
                      has_image={has_image}
                      has_pdf={has_pdf}
                      is_message_owner={is_message_owner}
                    />
                    <PersonaInfoBlock
                      reverse={is_message_owner}
                      name={item.user.first_name + " " + item.user.last_name}
                      time={moment(item.time).format("HH:mm")}
                      avatar={"https://static-pc.nyc3.digitaloceanspaces.com/static-pc/static/avatar/" + item.user.avatar}
                    />
                  </div>
                </div>
              );
            })}
        {/* {messages.length > 0 && (
          <PersonaInfoBlock
            reverse
            name="Victor"
            time={moment().format("HH:mm")}
            avatar={DefaultAvatar}
          />
        )} */}
      </div>
    </div>
  );
};

export default Chat;
