import * as types from "../../consts/actionTypes"

/* Get conversations */
export const getConversaciones = (payload) =>{
    return{
        type: types.GET_CONVERSACIONES,
        payload
    }
}

export const getConversacionesSuccess = (payload) =>{
    return{
        type: types.GET_CONVERSACIONES_SUCCESS,
        payload
    }
}

/* Get conversation messages */ 
export const getConversationMessages = (payload) =>{
    return{
        type: types.GET_CONVERSATION_MESSAGES,
        payload
    }
}

export const getConversationMessagesSuccess = (payload) =>{
    return{
        type: types.GET_CONVERSATION_MESSAGES_SUCCESS,
        payload
    }
}

/* Send a message */ 
export const sendMessage = (payload) =>{
    return{
        type: types.SEND_MESSAGE,
        payload
    }
}

export const sendMessageSuccess = (payload) =>{
    console.log('Reacting');
    return{
        type: types.SEND_MESSAGE_SUCCESS,
        payload
    }
}

/* Upload a file */
export const uploadConversationFile = (payload) =>{
    console.log(payload);
    return{
        type: types.UPLOAD_CONVERSATION_FILE,
        payload
    }
}

export const uploadConversationFileSuccess = (payload) =>{
    return{
        type: types.UPLOAD_CONVERSATION_FILE_SUCCESS,
        payload
    }
}

/* REAL TIME */
export const openConversationChannel = (payload) =>{
    return{
        type: types.OPEN_CONVERSATION_CHANNEL,
        payload
    }
}

export const closeConversationChannel = (payload) =>{
    return{
        type: types.CLOSE_CONVERSATION_CHANNEL,
        payload
    }
}

export const receiveMessage = (payload) =>{
    return{
        type: types.RECEIVE_MESSAGE,
        payload
    }
}

