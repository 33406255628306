import React from "react";
import { useState, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import IconoCalendario from "../../assets/icons/calendario.svg";
import IconoPlanetaAzul from "../../assets/icons/planeta-azul.svg";
import IconoVotacion from "../../assets/icons/encuesta-blanco.svg";
import ButtonComponent from "../elements/ButtonComponent";
// import SocialMediaComponent from "../elements/SocialMediaComponent";
// import { CommentSharp } from "@material-ui/icons";
import CommentComponent from "../elements/CommentComponent";
// import userIcon from "../../assets/images/user-icon.png";
import { X } from "react-feather";
import pointsVertical from "../../assets/icons/points-vertical.png";
import WriteCommentComponent from "../elements/WriteCommentComponent";
import commentWhite from "../../assets/icons/comment-white.png";
import moment from "moment";
// import { user } from "../../redux/selectors/rootSelectors";
import ReactQuill from "react-quill";
import imagenDefault from "../../assets/images/PWA-imagen-default.jpg";

const CardInfoComponent = (props) => {
  const history = useHistory();
  const [esRespuesta, setEsRespuesta] = useState(false);
  const [usuarioAResponder, setUsuarioAResponder] = useState(false);
  const [idComentarioAResponder, setIdComentarioAResponder] = useState(false);
  const [idComentarioAEditar, setIdComentarioAEditar] = useState(false);
  const [idShowOpciones, setIdShowOpciones] = useState(false);
  const localTime = moment(props.subtitle);
  moment.locale("es");
  localTime.locale(false);
  // const [comment, setComment] = useState();
  console.log(idShowOpciones);

  useEffect(() => {
    if (props.refreshCommentsStates) {
      setEsRespuesta(false);
      setUsuarioAResponder(false);
      setIdComentarioAResponder(false);
      setIdComentarioAEditar(false);
      setIdShowOpciones(false);
      props.setRefreshCommentsStates(false);
    }
    document.getElementById(
      "seccion-coments"
    ).scrollTop = document.getElementById("seccion-coments").scrollHeight;
  }, [props.refreshCommentsStates]);

  useEffect(() => {
    if (idShowOpciones) {
      setIdShowOpciones(false);
    }
  }, [idComentarioAEditar, idComentarioAResponder]);

  console.log(props.isHaveVoteSection);

  return (
    <Card className={props.classNameCard}>
      <Card.Body>
        <div className={props.classNameUpperPartDiv}>
          <div className={props.classNameTitleDiv}>
            <Card.Title className={props.classNameTitle}>
              {props.title}
            </Card.Title>
            {props.isMyProposals ? (
              <Card.Text>{props.proposalStatus}</Card.Text>
            ) : (
              ""
            )}
            <Row className="row-date-tags-share">
              <Col md={3}>
                <Card.Text
                  className={`mb-2 text-muted ${props.classNameSubtitle}`}
                >
                  <img src={IconoCalendario} style={{ marginRight: "0.5em" }} />
                  {localTime.format("LL")}
                </Card.Text>
                {(props.comuna || props.juntaVecinal) && (
                  <Card.Text
                    className={`mb-2 text-muted ${props.classNameSubtitle}`}
                  >
                    <img
                      src={IconoPlanetaAzul}
                      style={{ marginRight: "0.5em" }}
                    />

                    {props.comuna ? props.comuna : ""}
                    {props.juntaVecinal && props.juntaVecinal !== "null"
                      ? ", " + props.juntaVecinal
                      : ""}
                  </Card.Text>
                )}
              </Col>
              <Col md={5}>
                <Card.Text>{props.badges}</Card.Text>
              </Col>
              <Col md={4}>
                <Card.Text className={props.classNameSocialMedia}>
                  {props.socialMedia}
                </Card.Text>
              </Col>
            </Row>
          </div>
          {props.isHaveSocialMediaUpper ? (
            <div className={props.classNameSocialMediaUpperDiv}>
              <Card.Text className={props.classNameSocialMediaUpper}>
                {props.socialMediaUpper}
              </Card.Text>
            </div>
          ) : (
            ""
          )}
        </div>
        <ReactQuill
          className="quill-no-toolbar"
          readOnly={true}
          value={props.text ? props.text.toString() : ""}
        ></ReactQuill>
        <div className="display-flex-proporty">
          <div
            className={`card-info-image-novotes ${props.classNameImageDiv}`}
            style={{
              backgroundImage: props.src
                ? `url(${props.src})`
                : `url(${imagenDefault})`,
              backgroundSize: "cover",
            }}
          >
            <div className="div-card-img-top-custom">
              <Card.Img
                variant="top"
                className="card-img-top-custom"
                src={props.src ? props.src : imagenDefault}
                onClick={() => {
                  let a = document.createElement("a");
                  a.target = "_blank";
                  if (props.src) {
                    a.href = props.src;
                    a.click();
                  }
                }}
              />
            </div>
          </div>
          {props.tieneVotacionActiva && (
            <div className="card-info-div-enlace-votacion">
              <p>
                ¿Te interesa este tema? ¡Haz que cuente y participa en la
                votación!
              </p>
              <button
                onClick={() => history.push("/votaciones/" + props.votacion.id)}
              >
                <p>Ir a Votacion</p>
                <img src={IconoVotacion} alt="votacion" />
              </button>
            </div>
          )}
          {props.isHaveVoteSection && (
            <div className="card-info-votes">
              {/* <Card.Title className={props.classNameSecondTitle}>{props.secondTitle}</Card.Title> */}
              <Row>
                <Col md={10}>
                  <Card.Text className={props.classNameNumberOfVotes}>
                    {props.numberOfVotes}
                  </Card.Text>
                  <Card.Text>{props.progressBar}</Card.Text>
                </Col>
                <Col md={2}>
                  <ButtonComponent
                    classNameButton={props.classNameButton}
                    buttonText={props.buttonText}
                    to={props.to}
                    classNameLink={props.classNameLink}
                    onClick={props.buttonAccion}
                    disabled={props.buttonDisable}
                    isHaveIconAfter={props.supportIcon ? true : false}
                    srcAfter={props.supportIcon}
                  />
                </Col>
              </Row>
            </div>
          )}
        </div>
        {props.isHaveCommentsSection ? (
          <div>
            <div id="seccion-coments" className="comments-section">
              {/* Set Comment component */}

              {/* Comments tittle */}
              <div className={props.classNameCommentTitle}>
                <img src={props.iconCommentSrc} />
                <p style={{ display: "inline", marginLeft: "1%" }}>
                  {props.commentTitle}
                </p>
              </div>
              {/* Comments */}
              {/* <Card.Text className={props.classNameComment}>{props.comment}</Card.Text> */}
              {props.comentarios.length > 0
                ? props.comentarios
                    .sort((a, b) => {
                      return new Date(a.fecha) - new Date(b.fecha);
                    })
                    .map((comment) => {
                      if (comment.padre === null) {
                        return (
                          <Card.Text className={props.classNameComment}>
                            <CommentComponent
                              src={
                                comment.user
                                  ? comment.user.avatar
                                  : comment.usuario.avatar
                              }
                              iconSrc={pointsVertical}
                              user={
                                comment.user ? comment.user : comment.usuario
                              } //MUST FIX
                              date={comment.fecha}
                              content={comment.comentario}
                              respuestas={comment.respuestas}
                              idComment={comment.id}
                              moderated={comment.moderated}
                              ofensive={comment.ofensivo}
                              votos_up={comment.votos_up}
                              votos_down={comment.votos_down}
                              likeAction={props.likeAction}
                              unlikeAction={props.unlikeAction}
                              responseAction={() => {
                                comment.user
                                  ? setUsuarioAResponder(
                                      comment.user.first_name +
                                        " " +
                                        comment.user.last_name
                                    )
                                  : setUsuarioAResponder(
                                      comment.usuario.first_name +
                                        " " +
                                        comment.usuario.last_name
                                    );
                                setEsRespuesta(true);
                                setIdComentarioAResponder(comment.id);
                              }}
                              editAction={props.editAction}
                              deleteAction={props.deleteAction}
                              idUsuarioLogged={props.idUsuarioLogged}
                              idComentarioAResponder={idComentarioAResponder}
                              setIdComentarioAResponder={
                                setIdComentarioAResponder
                              }
                              idComentarioAEditar={idComentarioAEditar}
                              setIdComentarioAEditar={setIdComentarioAEditar}
                              idShowOpciones={idShowOpciones}
                              setIdShowOpciones={setIdShowOpciones}
                            />
                          </Card.Text>
                        );
                      }
                    })
                : ""}
            </div>

            <Card.Text className={props.classNameWriteComment}>
              {esRespuesta && (
                <div className="mensaje-respondiendo-comentario">
                  <p>Respondiendo a {usuarioAResponder}</p>
                  <X
                    onClick={() => {
                      setEsRespuesta(false);
                      setUsuarioAResponder(false);
                      setIdComentarioAResponder(false);
                    }}
                  />
                </div>
              )}
              <WriteCommentComponent
                className="write-comment-div"
                rows="1"
                placeholder="Escribe un comentario ..."
                classNameButton="button-vote boton-comentar"
                buttonText="Comentar"
                srcAfter={commentWhite}
                classNameImgAfter="proposals-image-after-style custom-icon-style"
                classNameLink="custom-write-comment-style"
                value={props.comment}
                changeAction={props.setComments}
                buttonAccion={
                  esRespuesta
                    ? () => props.responseAction(idComentarioAResponder)
                    : props.buttonCommentAction
                }
                // to=""
              />
            </Card.Text>
          </div>
        ) : (
          ""
        )}
      </Card.Body>
    </Card>
  );
};

export default CardInfoComponent;
