import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Sidebar from "../components/sidebar/sidebar";

import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Selector from "../redux/selectors/rootSelectors";
import { verifyToken, refreshToken } from "../redux/actions/userActions";

//Materia UI
import { makeStyles } from "@material-ui/core";
import { drawerWidth } from "../components/sidebar/sidebar";

const appStyles = makeStyles((theme) => ({
  drawerContent: {
    minHeight: "100vh",
    width: "100%",
    height: "auto",
    paddingTop: "72px",
    paddingLeft: drawerWidth,
    backgroundColor: "#fbfbff",
    paddingBottom: "3em",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      marginLeft: 0,
    },
  },
}));

export const PrivateRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => {
  const dispatch = useDispatch();
  const classes = appStyles();
  const logged = useSelector((state) => Selector.logged(state));
  const access = useSelector((state) => Selector.tokenAccess(state));
  const isValid = useSelector((state) => Selector.isValid(state));
  const token = localStorage.getItem("access");
  const tokenRefresh = localStorage.getItem("refresh");

  const verifyTokenTrue = () => {
    dispatch(verifyToken(token));
    if (!isValid) {
      dispatch(refreshToken(tokenRefresh));
    }
  };

  useEffect(() => {
    if (token !== "" && token !== null) verifyTokenTrue();
  }, [isValid]);

  console.log(logged)

  return (
    <div className={classes.drawerContent}>
      <Route
        {...rest}
        render={(props) =>
          logged ? (
            <div>
              <Sidebar />
              <Component {...props} />
            </div>
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
      />
    </div>
  );
};

// PrivateRoute.propTypes = {
//     isAuthenticated: PropTypes.bool.isRequired,
//     component: PropTypes.func.isRequired
// }

export default PrivateRoute;
