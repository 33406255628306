import React from 'react';

import './ProposalStatus.css';

const ProposalStatus = (props) => {
	return (
		<div>
			<span style={{ backgroundColor: `${props.background}` }} className="status-dot" />
			<span className="proposal-status">{props.status}</span>
		</div>
	);
};

export default ProposalStatus;
