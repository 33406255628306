import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";


import {
 
  Row,
  Col,
  Card,
  Container,
  BreadcrumbItem,
} from "react-bootstrap";
import "./Notifications.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {

  faFolderPlus,
  faComment,
  faBan,
  faHandPaper,
  faBell,
  faTrash,
  faHourglassEnd,
  faGrinWink,
  faNewspaper,
} from "@fortawesome/free-solid-svg-icons";
import {
  fechaStringBasicoAFechaJS,
  fechaMilisegundosAFechaString,
} from "../../utils/ComponentUtils";

// selectores
import { notificationsResult } from "../../redux/selectors/notificationsSelectors";

// Actions
import {
  getMyNotifications,
  readNotification,
  updateNotifications,
  resetNotifications,
} from "../../redux/actions/notificationsActions";

const notificationsTypes = {
  nueva_propuesta: faHandPaper,
  cambiar_estado_propuesta: faBell,
  intervenir_comentario_comunicado: faBan,
  apoyo_propuesta: faHandPaper,
  escalar_propuesta: faHandPaper,
  editar_comentario_moderado: faComment,
  editar_comentario_propuesta_moderado: faComment,
  intervenir_comentario: faBan,
  intervenir_comentario_propuesta: faBan,
  eliminar_propuesta: faTrash,
  terminar_encuesta: faHourglassEnd,
  nueva_solicitud_siac: faFolderPlus,
  nueva_mensaje: faComment,
};

const notificationsTypesLinks = {
  propuesta: "propuestas",
  nueva_propuesta: "propuestas",
  cambiar_estado_propuesta: "propuestas",
  apoyo_propuesta: "propuestas",
  escalar_propuesta: "propuestas",
  // 'editar_comentario_moderado': '/',
  // 'intervenir_comentario': '/',
  eliminar_propuesta: "propuestas",
  terminar_encuesta: "encuestas",
  nueva_solicitud_siac: "solicitudes",
  nueva_mensaje: "conversaciones",
  editar_comentario_moderado: "acciones",
  editar_comentario_propuesta_moderado: "propuestas",
  intervenir_comentario: "acciones",
  intervenir_comentario_propuesta: "propuestas",
  intervenir_comentario_comunicado: "acciones",
};

const Notifications = () => {
  // const meses = [
  //   "Enero",
  //   "Febrero",
  //   "Marzo",
  //   "Abril",
  //   "Mayo",
  //   "Junio",
  //   "Julio",
  //   "Agosto",
  //   "Septiembre",
  //   "Octubre",
  //   "Noviembre",
  //   "Diciembre",
  // ];

  let [isLoading, setIsLoading] = useState(true);
  // let [notifications, setNotifications] = useState([]);
  let [notificationsAdmin, setNotificationsAdmin] = useState([]);
  let [notificationsUser, setNotificationsUser] = useState([]);
  const [grupoNotificacionesAdmin, setGrupoNotificacionesAdmin] = useState({});
  const [grupoNotificacionesUser, setGrupoNotificacionesUser] = useState({});
  let [nextUrl, setNextUrl] = useState(null);
  let [previousUrl, setPreviousUrl] = useState(null);
  let history = useHistory();
  const dispatch = useDispatch();
  const notificaciones = useSelector((state) => notificationsResult(state));

  useEffect(() => {
    dispatch(resetNotifications());
    dispatch(getMyNotifications({ url: "tipo=todas" }));
  }, []);

  console.log(notificaciones);

  useEffect(() => {
    if (notificaciones !== undefined) {
      let mergedNotifications = [];
      let notificacionesAdministrador = [];
      let notificacionesUsuario = [];

      setNextUrl((nextUrl = notificaciones.next));
      setPreviousUrl((previousUrl = notificaciones.previous));

      if (notificaciones.results.hasOwnProperty("NotificacionAdmin")) {
        // Merge both notifications arrays (admin and user)
        mergedNotifications = [
          ...notificaciones.results["NotificacionAdmin"],
          ...notificaciones.results["NotificacionUser"],
        ];
        notificacionesAdministrador = [
          ...notificationsAdmin,
          ...notificaciones.results["NotificacionAdmin"],
        ];
        notificacionesUsuario = [
          ...notificationsUser,
          ...notificaciones.results["NotificacionUser"],
        ];
        setNotificationsAdmin(notificacionesAdministrador);
        setNotificationsUser(notificacionesUsuario);
      } else {
        mergedNotifications = [...notificaciones.results["NotificacionUser"]];
        notificacionesUsuario = [
          ...notificationsUser,
          ...notificaciones.results["NotificacionUser"],
        ];
        setNotificationsUser(notificacionesUsuario);
      }

      const generaObjetoDeFechas = (
        objetoAMutar,
        arrayQueMuta,
        setEstadoObjeto
      ) => {
        arrayQueMuta.forEach((notif) => {
          if (
            !objetoAMutar[
              `${Date.parse(fechaStringBasicoAFechaJS(notif.creado_el))}`
            ]
          ) {
            objetoAMutar[
              `${Date.parse(fechaStringBasicoAFechaJS(notif.creado_el))}`
            ] = [];
          }
          objetoAMutar[
            `${Date.parse(fechaStringBasicoAFechaJS(notif.creado_el))}`
          ].push(notif);
        });
        // return objetoAMutar;
        if (arrayQueMuta.length === 0) {
          setEstadoObjeto({});
          setIsLoading((isLoading = false));
        } else {
          setEstadoObjeto(objetoAMutar);
          setIsLoading((isLoading = false));
        }
      };
      generaObjetoDeFechas(
        {},
        notificacionesAdministrador,
        setGrupoNotificacionesAdmin
      );
      generaObjetoDeFechas(
        {},
        notificacionesUsuario,
        setGrupoNotificacionesUser
      );
    } else {
      setNotificationsAdmin([]);
      setNotificationsUser([]);
    }
  }, [notificaciones]);

  function handleCreateRoute(url, objeto) {
    if (objeto.startsWith("chat")) {
      return `${url}/${objeto.split("_")[1]}`;
    }

    if (url === "solicitudes") {
      return url;
    }

    return `${url}/${objeto}`;
  }

  function handleReadNotification(
    notificacionIndice,
    fuenteNotificacion,
    url,
    objeto
  ) {
    dispatch(
      readNotification({
        tipo: fuenteNotificacion,
        url: "",
        notificacion: notificacionIndice,
      })
    );

    dispatch(updateNotifications({}));
    history.push(handleCreateRoute(url, objeto));
  }

  function handleLoadMoreNotifications() {
    dispatch(getMyNotifications({ url: nextUrl.split("?")[1] }));
  }

  const generaListaNotificaciones = (objetoDefechas) => {
    return Object.keys(objetoDefechas)
      .sort((a, b) => b - a)
      .map((fecha_agrupacion, key) => {
        return (
          <Container fluid key={key}>
            <h5
              style={{
                textAlign: "start",
                marginBottom: "2em",
                fontSize: "13px",
              }}
            >
              {fechaMilisegundosAFechaString(Number(fecha_agrupacion))}
            </h5>
            {objetoDefechas[fecha_agrupacion].map((noti, idx) => {
              return noti.leido ? (
                <Row
                  key={idx}
                  style={{
                    marginTop: "1em",
                    marginBottom: "1em",
                    fontSize: "14px",
                    alignItems: "center",
                  }}
                >
                  <Col md={1}>
                    <FontAwesomeIcon
                      style={{
                        marginTop: "0.4em",
                        fontSize: "12px",
                      }}
                      icon={notificationsTypes[noti.tipo]}
                      className="icon"
                    />
                  </Col>
                  <Col md={11}>
                    <Link
                      style={{ color: "black" }}
                      to={handleCreateRoute(
                        notificationsTypesLinks[noti.tipo],
                        noti.objeto
                      )}
                    >
                      {noti.texto}
                    </Link>
                    {/* <a href={noti.link + "/" + noti.objeto}>{noti.texto}</a> */}
                  </Col>
                </Row>
              ) : (
                <Row
                  key={idx}
                  style={{
                    marginTop: "1em",
                    marginBottom: "1em",
                    borderRadius: "10px",
                    fontSize: "14px",
                    alignItems: "center",
                  }}
                >
                  <Col md={1}>
                    <FontAwesomeIcon
                      style={{
                        marginTop: "0.4em",
                        fontSize: "12px",
                        width: "100%",
                      }}
                      icon={notificationsTypes[noti.tipo]}
                      className="icon"
                    />
                  </Col>
                  <Col md={11}>
                    <p
                      style={{
                        color: "#0056b3",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleReadNotification(
                          noti.id,
                          noti.fuente,
                          notificationsTypesLinks[noti.tipo],
                          noti.objeto
                        )
                      }
                    >
                      {noti.texto}
                    </p>
                  </Col>
                </Row>
              );
            })}
          </Container>
        );
      });
  };

  return (
    <div className="section">
      <Row>
        <Container fluid className="container-information">
          <div className="breadcrumb-section">
            <BreadcrumbItem>Inicio</BreadcrumbItem>
            <h3 className="titulo-table-actions">Notificaciones</h3>
          </div>
          <Card>
            <Card.Body>
              {isLoading === true ? (
                <h5 style={{ textAlign: "center", marginBottom: "2em" }}>
                  <i>Cargando notificaciones...</i>
                </h5>
              ) : (
                generaListaNotificaciones(grupoNotificacionesUser)
              )}
            </Card.Body>
          </Card>
          {nextUrl ? (
            <div className="load-more-container">
              <p className="load-more" onClick={handleLoadMoreNotifications}>
                Cargar más notificaciones
              </p>
            </div>
          ) : (
            <p></p>
          )}
        </Container>
      </Row>
    </div>
  );
};

export default Notifications;
