import * as types from "../../consts/actionTypes";

export const cambiarPassword = (payload) => {
  console.log("Cambiando contraseña...");
  return {
    type: types.CAMBIAR_PASSWORD,
    payload,
  };
};

export const resetSuccessFlag = () => {
  return {
    type: types.RESET_SUCCESS_FLAG,
  };
};

export const getAvatares = (payload) => {
  console.log("Solicitando Avatares...");
  return {
    type: types.GET_AVATARES,
    payload,
  };
};

export const actualizarAvatar = (payload) => {
  console.log("Actualizando avatar...");
  return {
    type: types.ACTUALIZAR_AVATAR,
    payload,
  };
};

export const cambiarEmail = (payload) => {
  console.log("Actualizando email...");
  return {
    type: types.CAMBIAR_EMAIL,
    payload,
  };
};

export const toggleNotificacionesEmail = (payload) => {
  console.log("cambiando notificaciones email...");
  return {
    type: types.TOGGLE_NOTIFICACIONES_EMAIL,
    payload,
  };
};
