import * as types from "../../consts/actionTypes";

const initialState = {
  acciones: [],
  accionSuccessFlag: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.ACCION_SUCCESS_FLAG_TO_FALSE:
      return {
        ...state,
        accionSuccessFlag: false,
      };
    case types.GET_ACCIONES_SUCCESS:
      return {
        ...state,
        acciones: action.acciones.data,
      };

    case types.GET_ONE_ACCION_SUCCESS:
      return {
        ...state,
        accion: action.accion.data,
      };
    case types.APOYAR_ACCION_SUCCESS:
      return {
        ...state,
        accionSuccessFlag: true,
      };
    default:
      return {
        ...state,
      };
  }
}
