import React from "react";
import { Form, Button, Row, Col, Card, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
//Components
import HeaderMobile from "./HeaderMobile";

import comunas from "../../consts/comunas";
import juntasVecinales from "../../consts/juntasVecinales";

const RegistroForm = (props) => {
  const { handleSubmit, showAlert, setShowAlert, validated } = props;
  const [datos, setDatos] = React.useState({
    user: "",
    apellido1: "",
    apellido2: "",
    nombre: "",
    region: "",
    junta: "",
    email: "",
    password: "",
    confirmPass: "",
  });

  const handleDatos = (e) => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value,
    });
  };
  let fechaActual = {
    anio: new Date(Date.now()).getFullYear(),
    mes: new Date(Date.now()).getMonth() + 1,
    dia: new Date(Date.now()).getDate(),
  };
  return (
    <Col sm={6}>
      <Row className="d-flex justify-content-center align-content-center">
        <Col xs={12} className="d-sm-none header-registro">
          <HeaderMobile />
        </Col>

        <Col xs={11} sm={10}>
          <Card className="card-container">
            <h4 className="login__title">Registrarse</h4>

            <div className="external-links">
              ¿Ya tienes cuenta?{" "}
              <Link to="/login" style={{ marginLeft: "12px" }}>
                Inicia sesión
              </Link>
            </div>

            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => handleSubmit(e, datos)}
              id="registerForm"
            >
              <Form.Group controlId="formBasicRUN">
                <Form.Label className="label-input">
                  DNI
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  minLength="9"
                  maxLength="10"
                  pattern="(\d{7,8}[-]\w)"
                  placeholder="Ej. 2465938-4"
                  name="user"
                  onChange={handleDatos}
                  value={datos.user}
                />
                <Form.Control.Feedback type="invalid">
                  DNI no válido
                </Form.Control.Feedback>
              </Form.Group>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="formBasicApellido1">
                    <Form.Label className="label-input">
                      Apellido Paterno
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="López"
                      name="apellido1"
                      onChange={handleDatos}
                      value={datos.apellido1}
                      required
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group controlId="formBasicApellido2">
                    <Form.Label className="label-input">
                      Apellido Materno
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Canales"
                      name="apellido2"
                      onChange={handleDatos}
                      value={datos.apellido2}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group controlId="formBasicNombre">
                <Form.Label className="label-input">Nombre/s</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ej. Luis Pablo"
                  name="nombre"
                  onChange={handleDatos}
                  value={datos.nombre}
                  required
                />
              </Form.Group>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="formBasicApellido1">
                    <Form.Label className="label-input">
                      Fecha de nacimiento
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="fechaDeNacimiento"
                      max={`${fechaActual.anio - 16}-${
                        fechaActual.mes < 10
                          ? "0" + fechaActual.mes.toString()
                          : fechaActual.mes
                      }-${
                        fechaActual.dia < 10
                          ? "0" + fechaActual.dia.toString()
                          : fechaActual.dia
                      }`}
                      onChange={handleDatos}
                      value={datos.fechaDeNacimiento}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Seleccione una opción
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group controlId="formBasicApellido2">
                    <Form.Label className="label-input">Sexo</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      name="sexo"
                      as="select"
                      onChange={handleDatos}
                      pattern="(maculino|femenino)"
                      // value={user}
                    >
                      <option value="">Seleccione: </option>
                      <option value="masculino">Masculino</option>
                      <option value="femenino">Femenino</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Seleccione una opción
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId="formBasicRegion">
                <Form.Label className="label-input">
                  Provincia
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="region"
                  as="select"
                  defaultValue="Selecciona un provincia: "
                  onChange={handleDatos}
                  // value={user}
                >
                  <option value="">Selecciona una provincia: </option>
                  {comunas.map((comuna) => (
                    <option value={comuna}>{comuna}</option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  Seleccione una opción
                </Form.Control.Feedback>
              </Form.Group>
              {(datos.region === "Iquique" ||
                datos.region === "Alto Hospicio") && (
                <Form.Group controlId="formBasicRegion">
                  <Form.Label className="label-input">Junta Vecinal</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="junta"
                    as="select"
                    defaultValue="Selecciona una junta: "
                    onChange={handleDatos}
                    // value={user}
                  >
                    <option value="">Selecciona una junta: </option>
                    {Object.keys(
                      juntasVecinales[datos.region.toUpperCase()]
                    ).map((junta) => (
                      <option>{junta}</option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Seleccione una opción
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="label-input">
                  Correo Electrónico
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Ej. luisito_lopez@mail.com"
                  name="email"
                  onChange={handleDatos}
                  value={datos.email}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Correo no valido
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label className="label-input">Contraseña</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="**********"
                  name="password"
                  onChange={handleDatos}
                  value={datos.password}
                  required
                  minLength="8"
                />
                <Form.Control.Feedback type="invalid">
                  La contraseña debe ser al menos 8 caracteres
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBasicConfirmPassword">
                <Form.Label className="label-input">
                  Confirmar Contraseña
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="**********"
                  name="confirmPass"
                  onChange={handleDatos}
                  value={datos.confirmPass}
                  minLength="8"
                  required
                />
                <Form.Control.Feedback type="invalid">
                  La contraseña debe ser al menos 8 caracteres
                </Form.Control.Feedback>
              </Form.Group>

              {/* <Form.Group controlId="formBasicConfirmPassword">
                                <Form.Label>Confirmar Contraseña</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="**********"
                                    name="confirmPassword"
                                    onChange={(event) => {
                                        setPassword(event.target.value);
                                    }}
                                    value={confirmPass}
                                />
                                <Form.Text className="text-muted "></Form.Text>
                            </Form.Group> */}

              <Button type="submit" variant="primary" block>
                Registrarme
              </Button>
              {/* <Form.Label>{error}</Form.Label> */}
            </Form>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default RegistroForm;
