import * as types from "../../consts/actionTypes"

export const getEncuestas = (payload) =>{
    return{
        type: types.GET_ENCUESTAS,
        payload
    }
}

export const getEncuestasSuccess = (payload) =>{
    return{
        type: types.GET_ENCUESTAS_SUCCESS,
        payload
    }
}

export const getEncuestasDetails = (payload) =>{
    return{
        type: types.GET_ENCUESTAS_DETAILS,
        payload
    }
}

export const postVotacionUser = (payload) =>{
    return{
        type: types.POST_VOTACION_USER, 
        payload
    }
}