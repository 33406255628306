import React from "react";
import { useLocation } from "react-router-dom";
import {
  Facebook,
  Linkedin,
  Twitter,
  Whatsapp,
  MessageCircle,
} from "react-feather";
import facebookIcon from "../../assets/icons/facebook.png";
import twitterIcon from "../../assets/icons/twitter.png";
import instagramIcon from "../../assets/icons/instagram.png";
import whatsAppIcon from "../../assets/icons/whats-app.png";

import "./SocialMediaComponent.css";

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const SocialMediaComponent = ({
  paramForPath,
  classNameSocialMediaDiv,
  classNameSocialMediaIcons,
  twitterHashtags,
  titulo,
}) => {
  let location = paramForPath
    ? window.location.href + paramForPath
    : window.location.href;
  console.log(location);
  return (
    <div className={classNameSocialMediaDiv}>
      <div className={classNameSocialMediaIcons}>
        <p>Compartir</p>
        <div
          className="icon-social-media-container"
          data-href={location}
          data-layout="button"
          data-size="small"
        >
          {/* <a
            target="_blank"
            href={`https://www.facebook.com/sharer/sharer.php?display=page&u=${location}/&amp;src=sdkpreparse`}
            class="fb-xfbml-parse-ignore"
          > */}
          <FacebookShareButton
            url={location}
            quote={titulo}
            class="fb-xfbml-parse-ignore"
          >
            <Facebook />
          </FacebookShareButton>
          {/* </a> */}
        </div>
        <div className="icon-social-media-container">
          <a
            target="_blank"
            href={`https://twitter.com/intent/tweet?text=${titulo}&url=${location}&hashtags=${twitterHashtags}`}
          >
            {/* // <TwitterShareButton
          //   title={titulo}
          //   hashtags={twitterHashtags}
          //   url={location}
          // > */}
            <Twitter />
            {/* </TwitterShareButton> */}
          </a>
        </div>
        <div className="icon-social-media-container">
          <a
            target="_blank"
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${location}&title=${titulo}&source=${location}`}
          >
            {/* <LinkedinShareButton title={titulo} source={location}> */}
            <Linkedin />
            {/* </LinkedinShareButton> */}
          </a>
        </div>
        <div className="icon-social-media-container">
          {/* <a
            target="_blank"
            href={`whatsapp://send?text=${location}`}
            data-action="share/whatsapp/share"
          > */}
          <WhatsappShareButton url={location} title={titulo} separator={" "}>
            <MessageCircle />
          </WhatsappShareButton>
          {/* </a> */}
        </div>

        {/* <img src={facebookIcon} />
				<img src={twitterIcon} />
				<img src={instagramIcon} />
				<img src={whatsAppIcon} /> */}
      </div>
    </div>
  );
};

export default SocialMediaComponent;
