//USER & AUTH
export const LOGIN="LOGIN"
export const LOGIN_SUCCESS="LOGIN_SUCCESS"
export const LOGIN_ERROR ="LOGIN_ERROR"
export const VERIFY_TOKEN ="VERIFY_TOKEN"
export const VALID_TOKEN ="VALID_TOKEN"
export const INVALID_TOKEN ="INVALID_TOKEN"
export const REFRESH_TOKEN ="REFRESH_TOKEN"
export const REFRESH_TOKEN_SUCCESS ="REFRESH_TOKEN_SUCCESS"
export const REFRESH_TOKEN_ERROR ="REFRESH_TOKEN_ERROR"
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS"
export const REGISTER = "REGISTER"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAIL = "REGISTER_FAIL"
export const REGISTER_STATE_RESET = "REGISTER_STATE_RESET"
export const LOGOUT="LOGOUT"
export const REFRESH_USER ="REFRESH_USER"
export const GET_POLITICAS_PRIVACIDAD = "GET_POLITICAS_PRIVACIDAD"
export const GET_POLITICAS_PRIVACIDAD_SUCCESS = "GET_POLITICAS_PRIVACIDAD_SUCCESS"
export const GET_POLITICAS_PRIVACIDAD_ERROR = "GET_POLITICAS_PRIVACIDAD_ERROR"
export const POST_ACEPTA_POLITICAS_PRIVACIDAD = "POST_ACEPTA_POLITICAS_PRIVACIDAD"
export const POST_ACEPTA_POLITICAS_PRIVACIDAD_SUCCESS = "POST_ACEPTA_POLITICAS_PRIVACIDAD_SUCCESS"
export const POST_ACEPTA_POLITICAS_PRIVACIDAD_ERROR = "POST_ACEPTA_POLITICAS_PRIVACIDAD_ERROR"
export const POST_ACEPTA_VIDEO_TUTORIAL = "POST_ACEPTA_VIDEO_TUTORIAL"
export const POST_ACEPTA_VIDEO_TUTORIAL_SUCCESS = "POST_ACEPTA_VIDEO_TUTORIAL_SUCCESS"
export const POST_ACEPTA_VIDEO_TUTORIAL_ERROR = "POST_ACEPTA_VIDEO_TUTORIAL_ERROR"
export const VERIFY_EMAIL = "VERIFY_EMAIL"
export const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS"
export const VERIFY_EMAIL_ERROR = "VERIFY_EMAIL_ERROR"

//RECUPERACION 
export const ENVIAR_CORREO_RECUPERACION = "ENVIAR_CORREO_RECUPERACION"
export const ENVIAR_CORREO_RECUPERACION_SUCCESS = "ENVIAR_CORREO_RECUPERACION_SUCCESS"
export const ENVIAR_CORREO_RECUPERACION_ERROR = "ENVIAR_CORREO_RECUPERACION_ERROR"

export const RESTABLECER_PASSWORD = "RESTABLECER_PASSWORD"
export const RESTABLECER_PASSWORD_SUCCESS = "RESTABLECER_PASSWORD_SUCCESS"
export const RESTABLECER_PASSWORD_ERROR = "RESTABLECER_PASSWORD_ERROR"


//ENCUESTAS-VOTACION
export const GET_ENCUESTAS = "GET_ENCUESTAS"
export const GET_ENCUESTAS_SUCCESS = "GET_REQUEST_SUCCESS"
export const GET_ENCUESTAS_DETAILS = "GET_ENCUESTAS_DETAILS"
export const GET_ENCUESTAS_DETAILS_SUCCESS = "GET_ENCUESTAS_DETAILS_SUCCESS"
export const POST_VOTACION_USER = "POST_VOTACION_USER"
export const POST_VOTACION_USER_SUCCESS = "POST_VOTACION_USER_SUCCESS"
export const POST_VOTACION_USER_ERROR = "POST_VOTACION_USER_ERROR"

// CONVERSACIONES 
export const GET_CONVERSACIONES = "GET_CONVERSACIONES";
export const GET_CONVERSACIONES_SUCCESS = "GET_CONVERSACIONES_SUCCESS";

export const GET_CONVERSATION_MESSAGES = "GET_CONVERSATION_MESSAGE";
export const GET_CONVERSATION_MESSAGES_SUCCESS = "GET_CONVERSATION_MESSAGE_SUCCESS";

export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";

export const UPLOAD_CONVERSATION_FILE = "UPLOAD_CONVERSATION_FILE";
export const UPLOAD_CONVERSATION_FILE_SUCCESS = "UPLOAD_CONVERSATION_FILE_SUCCESS";

// CONVERSACIONES REAL TIME
export const OPEN_CONVERSATION_CHANNEL = "OPEN_CONVERSATION_CHANNEL";
export const CLOSE_CONVERSATION_CHANNEL = "CLOSE_CONVERSATION_CHANNEL";
export const RECEIVE_MESSAGE = "RECEIVE_MESSAGE";

//PROPUESTAS
export const GET_PROPUESTAS = "GET_PROPUESTAS"
export const GET_PROPUESTAS_SUCCESS = "GET_PROPUESTAS_SUCCESS"
export const GET_MY_PROPUESTAS = "GET_MY_PROPUESTAS"
export const GET_MY_PROPUESTAS_SUCCESS = "GET_MY_PROPUESTAS_SUCCESS"
export const GET_ONE_PROPUESTA = "GET_ONE_PROPUESTA"
export const GET_ONE_PROPUESTA_SUCCESS = "GET_ONE_PROPUESTA_SUCCESS"
export const GET_TENDENCIAS_PROPUESTAS = "GET_TENDENCIAS_PROPUESTAS"
export const GET_TENDENCIAS_PROPUESTAS_SUCCESS = "GET_TENDENCIAS_PROPUESTAS_SUCCESS"
export const POST_SUPPORT_PROPUESTA = "POST_SUPPORT_PROPUESTA"
export const POST_SUPPORT_PROPUESTA_SUCCESS = "POST_SUPPORT_PROPUESTA_SUCCESS"

export const POST_PROPUESTA = "POST_PROPUESTA"
export const POST_PROPUESTA_SUCCESS = "POST_PROPUESTA_SUCCESS"
export const POST_PROPUESTA_ERROR = "POST_PROPUESTA_ERROR"

export const PUT_PROPUESTA = "PUT_PROPUESTA"
export const PUT_PROPUESTA_SUCCESS = "PUT_PROPUESTA_SUCCESS"
export const PUT_PROPUESTA_ERROR = "PUT_PROPUESTA_ERROR"

export const PROPUESTAS_SUCCESS_FLAG_TO_FALSE = "PROPUESTAS_SUCCESS_FLAG_TO_FALSE"

export const GET_APOYOS_MAXIMOS = "GET_APOYOS_MAXIMOS"
export const GET_APOYOS_MAXIMOS_SUCCESS = "GET_APOYOS_MAXIMOS_SUCCESS"
export const GET_APOYOS_MAXIMOS_ERROR = "GET_APOYOS_MAXIMOS_ERROR"

export const DELETE_PROPUESTA = "DELETE_PROPUESTA"
export const DELETE_PROPUESTA_SUCCESS = "DELETE_PROPUESTA_SUCCESS"
export const DELETE_PROPUESTA_ERROR = "DELETE_PROPUESTA_ERROR"

//PROYECTOS
export const GET_TENDENCIAS_PROYECTOS = "GET_TENDENCIAS_PROYECTOS"
export const GET_TENDENCIAS_PROYECTOS_SUCCESS = "GET_TENDENCIAS_PROYECTOS_SUCCESS"
export const GET_PROYECTOS = "GET_PROYECTOS"
export const GET_PROYECTOS_SUCCESS = "GET_PROYECTOS_SUCCESS"
export const GET_ONE_PROYECTO = "GET_ONE_PROYECTO"
export const GET_ONE_PROYECTO_SUCCESS = "GET_ONE_PROYECTO_SUCCESS"

//COMENTARIOS
export const POST_CREATE_PROPUESTAS_COMMENTS = "POST_CREATE_PROPUESTAS_COMMENTS"
export const POST_CREATE_PROPUESTAS_COMMENTS_SUCCESS = "POST_CREATE_PROPUESTAS_COMMENTS_SUCCESS"
export const POST_VOTE_PROPUESTAS_COMMENTS = "POST_VOTE_PROPUESTAS_COMMENTS"
export const POST_VOTE_PROPUESTAS_COMMENTS_SUCCESS = "POST_VOTE_PROPUESTAS_COMMENTS_SUCCESS"
export const PUT_VOTE_PROPUESTAS_COMMENTS = "PUT_VOTE_PROPUESTAS_COMMENTS"
export const PUT_VOTE_PROPUESTAS_COMMENTS_SUCCESS = "PUT_VOTE_PROPUESTAS_COMMENTS_SUCCESS"
export const DELETE_VOTE_PROPUESTAS_COMMENTS = "DELETE_VOTE_PROPUESTAS_COMMENTS"
export const DELETE_VOTE_PROPUESTAS_COMMENTS_SUCCESS = "DELETE_VOTE_PROPUESTAS_COMMENTS_SUCCESS"
export const POST_CREATE_PROPUESTAS_RESPUESTA = "POST_CREATE_PROPUESTAS_RESPUESTA"
export const POST_CREATE_PROPUESTAS_RESPUESTA_SUCCESS = "POST_CREATE_PROPUESTAS_RESPUESTA_SUCCESS"

export const POST_CREATE_COMUNICADOS_COMMENTS = "POST_CREATE_COMUNICADOS_COMMENTS"
export const POST_CREATE_COMUNICADOS_COMMENTS_SUCCESS = "POST_CREATE_COMUNICADOS_COMMENTS_SUCCESS"
export const POST_VOTE_COMUNICADOS_COMMENTS = "POST_VOTE_COMUNICADOS_COMMENTS"
export const POST_VOTE_COMUNICADOS_COMMENTS_SUCCESS = "POST_VOTE_COMUNICADOS_COMMENTS_SUCCESS"
export const PUT_VOTE_COMUNICADOS_COMMENTS = "PUT_VOTE_COMUNICADOS_COMMENTS"
export const PUT_VOTE_COMUNICADOS_COMMENTS_SUCCESS = "PUT_VOTE_COMUNICADOS_COMMENTS_SUCCESS"
export const DELETE_VOTE_COMUNICADOS_COMMENTS = "DELETE_VOTE_COMUNICADOS_COMMENTS"
export const DELETE_VOTE_COMUNICADOS_COMMENTS_SUCCESS = "DELETE_VOTE_COMUNICADOS_COMMENTS_SUCCESS"
export const POST_CREATE_COMUNICADOS_RESPUESTA = "POST_CREATE_COMUNICADOS_RESPUESTA"
export const POST_CREATE_COMUNICADOS_RESPUESTA_SUCCESS = "POST_CREATE_COMUNICADOS_RESPUESTA_SUCCESS"

export const DISLIKE_COMENTARIO_PROPUESTA = "DISLIKE_COMENTARIO_PROPUESTA"
export const DISLIKE_COMENTARIO_PROPUESTA_SUCCESS = "DISLIKE_COMENTARIO_PROPUESTA_SUCCESS"
export const DISLIKE_COMENTARIO_PROPUESTA_ERROR = "DISLIKE_COMENTARIO_PROPUESTA_ERROR"

export const DISLIKE_COMENTARIO_ACCION = "DISLIKE_COMENTARIO_ACCION"
export const DISLIKE_COMENTARIO_ACCION_SUCCESS = "DISLIKE_COMENTARIO_ACCION_SUCCESS"
export const DISLIKE_COMENTARIO_ACCION_ERROR = "DISLIKE_COMENTARIO_ACCION_ERROR"

export const RESPONDER_COMENTARIO_PROPUESTA = "RESPONDER_COMENTARIO_PROPUESTA"
export const RESPONDER_COMENTARIO_PROPUESTA_SUCCESS = "RESPONDER_COMENTARIO_PROPUESTA_SUCCESS"
export const RESPONDER_COMENTARIO_PROPUESTA_ERROR = "RESPONDER_COMENTARIO_PROPUESTA_ERROR"

export const RESPONDER_COMENTARIO_ACCION = "RESPONDER_COMENTARIO_ACCION"
export const RESPONDER_COMENTARIO_ACCION_SUCCESS = "RESPONDER_COMENTARIO_PROPUESTA_SUCCESS"
export const RESPONDER_COMENTARIO_ACCION_ERROR = "RESPONDER_COMENTARIO_PROPUESTA_ERROR"

export const EDIT_COMENTARIO_PROPUESTA = "EDIT_COMENTARIO_PROPUESTA"
export const EDIT_COMENTARIO_PROPUESTA_SUCCESS = "EDIT_COMENTARIO_PROPUESTA_SUCCESS"
export const EDIT_COMENTARIO_PROPUESTA_ERROR = "EDIT_COMENTARIO_PROPUESTA_ERROR"

export const EDIT_COMENTARIO_ACCION = "EDIT_COMENTARIO_ACCION"
export const EDIT_COMENTARIO_ACCION_SUCCESS = "EDIT_COMENTARIO_PROPUESTA_SUCCESS"
export const EDIT_COMENTARIO_ACCION_ERROR = "EDIT_COMENTARIO_PROPUESTA_ERROR"

export const DELETE_COMENTARIO_PROPUESTA = "DELETE_COMENTARIO_PROPUESTA"
export const DELETE_COMENTARIO_PROPUESTA_SUCCESS = "DELETE_COMENTARIO_PROPUESTA_SUCCESS"
export const DELETE_COMENTARIO_PROPUESTA_ERROR = "DELETE_COMENTARIO_PROPUESTA_ERROR"

export const DELETE_COMENTARIO_ACCION = "DELETE_COMENTARIO_ACCION"
export const DELETE_COMENTARIO_ACCION_SUCCESS = "DELETE_COMENTARIO_ACCION_SUCCESS"
export const DELETE_COMENTARIO_ACCION_ERROR = "DELETE_COMENTARIO_ACCION_ERROR"

export const COMENTARIOS_SUCCESS_FLAG_TO_FALSE = "COMENTARIOS_SUCCESS_FLAG_TO_FALSE"


//CONFIGURACION
export const CAMBIAR_PASSWORD = "CAMBIAR_PASSWORD"
export const CAMBIAR_PASSWORD_SUCCESS = "CAMBIAR_PASSWORD_SUCCESS"
export const CAMBIAR_PASSWORD_ERROR = "CAMBIAR_PASSWORD_ERROR"

export const RESET_SUCCESS_FLAG = "RESET_SUCCESS_FLAG"

export const GET_AVATARES = "GET_AVATARES"
export const GET_AVATARES_SUCCESS = "GET_AVATARES_SUCCESS"
export const GET_AVATARES_ERROR = "GET_AVATARES_ERROR"

export const ACTUALIZAR_AVATAR = "ACTUALIZAR_AVATAR"
export const ACTUALIZAR_AVATAR_SUCCESS = "ACTUALIZAR_AVATAR_SUCCESS"
export const ACTUALIZAR_AVATAR_ERROR = "ACTUALIZAR_AVATAR_ERROR"

export const CAMBIAR_EMAIL = "CAMBIAR_EMAIL"
export const CAMBIAR_EMAIL_SUCCESS = "CAMBIAR_EMAIL_SUCCESS"
export const CAMBIAR_EMAIL_ERROR = "CAMBIAR_EMAIL_ERROR"

export const TOGGLE_NOTIFICACIONES_EMAIL = "TOGGLE_NOTIFICACIONES_EMAIL"
export const TOGGLE_NOTIFICACIONES_EMAIL_SUCCESS = "TOGGLE_NOTIFICACIONES_EMAIL_SUCCESS"
export const TOGGLE_NOTIFICACIONES_EMAIL_ERROR = "TOGGLE_NOTIFICACIONES_EMAIL_ERROR"

//ACCIONES 
export const GET_ACCIONES = "GET_ACCIONES"
export const GET_ACCIONES_SUCCESS = "GET_ACCIONES_SUCCESS"
export const GET_ONE_ACCION = "GET_ACCION"
export const GET_ONE_ACCION_SUCCESS = "GET_ONE_ACCION_SUCCESS"

export const APOYAR_ACCION = "APOYAR_ACCION"
export const APOYAR_ACCION_SUCCESS = "APOYAR_ACCION_SUCCESS"
export const APOYAR_ACCION_ERROR = "APOYAR_ACCION_ERROR"

export const ACCION_SUCCESS_FLAG_TO_FALSE = "ACCION_SUCCESS_FLAG_TO_FALSE"

//ANY REQUEST
export const GET_REQUEST_ERROR = "GET_REQUEST_ERROR"
export const POST_REQUEST_ERROR = "POST_REQUEST_ERROR"
export const PUT_REQUEST_ERROR = "PUT_REQUEST_ERROR"
export const DELETE_REQUEST_ERROR = "DELETE_REQUEST_ERROR"

// Notifications
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS"
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS"
export const GET_NOTIFICATIONS_ERROR = "GET_NOTIFICATIONS_ERROR"

export const GET_TODAY_NOTIFICATIONS = "GET_TODAY_NOTIFICATIONS"
export const GET_TODAY_NOTIFICATIONS_SUCCESS = "GET_TODAY_NOTIFICATIONS_SUCCESS"
export const GET_TODAY_NOTIFICATIONS_ERROR = "GET_TODAY_NOTIFICATIONS_ERROR"

export const PUT_NOTIFICATIONS = "PUT_NOTIFICATIONS"
export const PUT_NOTIFICATIONS_SUCCESS = "PUT_NOTIFICATIONS_SUCCESS"
export const PUT_NOTIFICATIONS_ERROR = "PUT_NOTIFICATIONS_ERROR"

export const SEND_NUMBER_OF_UNREAD_NOTIFICATIONS = "SEND_NUMBER_OF_UNREAD_NOTIFICATIONS"

export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS"

export const RESET_NOTIFICATIONS = "RESET_NOTIFICATIONS"


//Cotacta SIAC

export const POST_SOLICITUD_SIAC = "POST_SOLICITUD_SIAC"
export const POST_SOLICITUD_SIAC_SUCCESS = "POST_SOLICITUD_SIAC_SUCCESS"
export const POST_SOLICITUD_SIAC_ERROR = "POST_SOLICITUD_SIAC_ERROR"

//Busqueda
export const GET_BUSQUEDA = "GET_BUSQUEDA"
export const SET_RESULT = "SET_RESULT"

export const REDUCER_EXCEPTIONS = [
    'GET_LOADING',
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    VERIFY_TOKEN,
    REFRESH_TOKEN,
    INVALID_TOKEN,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_ERROR,
    REGISTER,
    REGISTER_SUCCESS,
    "toggle",
    LOGOUT,
    RESET_SUCCESS_FLAG,
    GET_POLITICAS_PRIVACIDAD,
    PROPUESTAS_SUCCESS_FLAG_TO_FALSE,
    ENVIAR_CORREO_RECUPERACION,
    ENVIAR_CORREO_RECUPERACION_SUCCESS,
    ENVIAR_CORREO_RECUPERACION_ERROR,
    RESTABLECER_PASSWORD,
    RESTABLECER_PASSWORD_SUCCESS,
    RESTABLECER_PASSWORD_ERROR,
    VERIFY_EMAIL,
    VERIFY_EMAIL_SUCCESS, 
    VERIFY_EMAIL_ERROR,
    REGISTER_STATE_RESET,
]

