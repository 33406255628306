import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as GAservices from "../../redux/services/GoogleAnalyticsServices";
//Bostrap
import { Form, Button, Card, Row, Col } from "react-bootstrap";
//Actions
import { enviarCorreoRecuperacion } from "../../redux/actions/recuperacionActions";
//Selector
import { recuperacionRespuestaResult } from "../../redux/selectors/recuperacionSelector";
import Swal from "sweetalert2";

const Recuperacion = () => {
  const dispatch = useDispatch();
  const respuesta = useSelector((state) => recuperacionRespuestaResult(state));
  const [validated, setValidated] = useState(false);
  const [email, setEmail] = useState();

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      e.stopPropagation();
      console.log(email);
      try {
        dispatch(enviarCorreoRecuperacion({ email: email }));
      } catch (e) {
        console.log(e);
      }
    }
    setValidated(true);
  };

  useEffect(() => {
    GAservices.GApageView("Restablecer");
  }, []);

  useEffect(() => {
    if (respuesta && respuesta.status === 200) {
      Swal.fire(
        "Correo enviado",
        "Se ha enviado un correo con las instrucciones para restablecer su contraseña",
        "success"
      );
    }
  }, [respuesta]);

  return (
    <div style={{ width: "100%", height: "calc(100vh - 144px)" }}>
      <Row
        className="d-flex justify-content-center align-content-center"
        style={{ height: "100%" }}
      >
        <Col
          xs={12}
          className="d-sm-none header-registro"
          style={{ position: "absolute", padding: "0.5rem" }}
        ></Col>
        <Col xs={11} sm={9}>
          <Card className="card-container login">
            <h3 className="login__title">Recuperación de contraseña</h3>
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => handleSubmit(e)}
            >
              <Form.Group controlId="formBasicEmail">
                <Form.Label style={{ color: "#1069B4" }}>
                  Escriba su correo electrónico a donde enviaremos el enlace de
                  recuperación
                </Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Ingrese su correo"
                  name="user"
                  onChange={(event) => setEmail(event.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Correo no válido
                </Form.Control.Feedback>
              </Form.Group>

              <Button type="submit" className="submit-login" block>
                Enviar
              </Button>
              <Form.Label className="error-label"></Form.Label>
            </Form>
            <Link to="/login">Regresar a inicio de sesión</Link>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Recuperacion;
