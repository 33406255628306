import React from "react";
import { makeStyles } from "@material-ui/core";
import TextsmsIcon from "@material-ui/icons/Textsms";

const useStyles = makeStyles(() => ({
  questionnaireTextWrapper: {
    display: "flex",
    alignItems: "center",
  },
  chatIconBox: {
    width: "40px",
    height: "40px",
    backgroundColor: "var(--chat-main-color)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginRight: "20px",
    color: "white",
  },
  questionnaireText: {
    color: "var(--chat-main-color)",
    "& p": {
      margin: "10px  0",
    },
  },
}));

const PreQuestionnaire = ({
  questionnaire,
  multilineField,
  questionnaireMultiply,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.questionnaireTextWrapper}>
      <div className={classes.chatIconBox}>
        <TextsmsIcon />
      </div>
      <div className={classes.questionnaireText}>
        <p>Selección de color de la fuente en la plaza central</p>
        <p>
          Pregunta{" "}
          {(questionnaire && 1) 

            }
          /1
        </p>
      </div>
    </div>
  );
};

export default PreQuestionnaire;
