import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// assets
import DrawerButton from "./drawerButton";
// import logo from "./logo2.png";
// import victor from "../../assets/icons/Avatar_Curvas-10.png";
// icons
import { Search, MoreHorizontal, Bell, Home, Settings } from "react-feather";

// styles
import "./navbar.css";

//actions
import { toogleSideBarShow } from "../../redux/actions/sideBarActions";
import { updateNotifications } from "../../redux/actions/notificationsActions";

// selectores
import { unreadNotificationsResult } from "../../redux/selectors/notificationsSelectors";

// Selectors
import * as Selector from "../../redux/selectors/rootSelectors";
import {
  Button,
  AppBar,
  Toolbar,
  IconButton,
  // Grid,
  Badge,
  // TextField,
} from "@material-ui/core";
import Notification from "../Notification-PopOver/PopoverNoti";
import MiniPanelUsuario from "./MiniPanelUsuario";
import * as Service from "../../redux/services/authServices"
// import { Navbar, Nav, Row, Col } from "react-bootstrap";


// Websocket
import { w3cwebsocket as W3CWebSocket } from "websocket";
//api info 
import  baseUrl from "../../consts/GeneralUrl"

export default (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [busqueda, setBusqueda] = useState('')
  const [popover, setPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [abrirMiniPanel, setAbrirMiniPanel] = useState(false);
  const userInfo = useSelector((state) => Selector.user(state));
  const userInfoLS= Service.getUser()
  console.log({userInfo})
  let notificacionesNoLeidas = useSelector((state) =>
    unreadNotificationsResult(state)
  );

  // Get Auth state
  const authState = useSelector((state) => state.AuthReducer);
  const { usr, tokenAccess } = authState;

  const togglePopover = (e) => {
    setPopover(!popover);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      if(busqueda!=='') history.push(`/busqueda/${busqueda}`)
    }
  }

  let [invisible, setInvisible] = useState(true);
  let [totalUnread, setTotalUnread] = useState(0);

  useEffect(() => {
    if (notificacionesNoLeidas !== undefined) {
      setTotalUnread((totalUnread = parseInt(notificacionesNoLeidas.unread)));
    }
  }, [notificacionesNoLeidas]);

  useEffect(() => {
    totalUnread > 0 ? setInvisible(false) : setInvisible(true);
  }, [totalUnread]);

  let notification_channel = useRef(null);

  useEffect(() => {
    if (notification_channel){
    notification_channel.current = new W3CWebSocket(
      `wss://api.participacion.management-r.com/ws/pwa/notificaciones/${userInfo?userInfo.user["username"]:userInfoLS}/?token=${tokenAccess}`
    );

    // Handle error connection
    notification_channel.current.onerror = () => {
      console.log(
        "Notificaciones: No se pudo establecer la conexión con el servidor"
      );
    };

    // Handle socket open connection
    notification_channel.current.onopen = () => {
      console.log("Notificaciones: Conexión establecida exítosamente");
    };

    notification_channel.current.onclose = () => {
      console.log("Notificaciones: Closing connection");
    };

    notification_channel.current.onmessage = (event) => {
      const { data } = event;
      let message = JSON.parse(data);
      console.log(message);
      setTotalUnread((totalUnread += 1));
      dispatch(updateNotifications({}));
    };}
  }, [userInfo, tokenAccess]);

  return (
    <div>
      <AppBar id="appBar">
        <div className="div-appbar-izquierda">
          <Toolbar id="drawwer-button-header">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => dispatch(toogleSideBarShow())}
            >
              <DrawerButton />
            </IconButton>
          </Toolbar>
          <div className="searchbar-header">
            <Search color="#0F69B4" />
            <div>
              <input
                type="text"
                placeholder="Buscar en todo el sistema"
                id="input-searchbar-header"
                value={busqueda}
                onKeyPress={handleKeyPress}
                onChange={(e) => setBusqueda(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="div-appbar-derecha">
          <Button className="navbar-button-circle icon" onClick={togglePopover}>
            <Notification
              open={popover}
              anchorEl={anchorEl}
              handleClose={handleClose}
            />
            <Bell size={20} color="#0F69B4" />
            <Badge
              className="navbar-badge"
              color="secondary"
              variant="dot"
              invisible={invisible}
            ></Badge>
          </Button>
          <Button
            className="navbar-button-circle icon"
            onClick={() => {
              history.push("/configuracion");
            }}
          >
            <Settings size={20} color="#0F69B4" />
          </Button>
          {userInfo && (
            <div className="div-nombre-header">
              <p>{userInfo.user.first_name}</p>
              <p>{userInfo.user.last_name}</p>
            </div>
          )}
          <div
            onClick={() => setAbrirMiniPanel(!abrirMiniPanel)}
            id="div-div-imagen-de-perfil"
          >
            <img
              className="navbar-profile-picture"
              src={userInfo?
                "https://static-pc.nyc3.digitaloceanspaces.com/static-pc/static/avatar/" +
                userInfo.avatar:"https://static-pc.nyc3.digitaloceanspaces.com/static-pc/static/avatar/"
              }
              alt={"profile"}
            />
            {abrirMiniPanel && (
              <MiniPanelUsuario
                id="area-min-panel-usuario"
                usuario={userInfo}
                setAbrirMiniPanel={setAbrirMiniPanel}
              />
            )}
          </div>
        </div>
        {/* <Grid container style={{ height: "100%" }}>
          <Grid
            item
            md={0}
            sm={1}
            className={props.styleClasses.buttonToggleDrawer}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => dispatch(toogleSideBarShow())}
              >
                <DrawerButton />
              </IconButton>
            </Toolbar>
          </Grid>
          <Grid item xs={4} sm={6} md={8} style={{ height: "100%" }}>
            <div className="searchbar-header">
              <Search color="#0F69B4" />
              <div>
                <input
                  type="text"
                  placeholder="Buscar en todo el sistema"
                  id="input-searchbar-header"
                />
              </div>
            </div>
          </Grid>
          <Grid item md={2}>
            <div className="navbar-botton-group">
              <Button
                className="navbar-button-circle d-flex justify-content-center align-items-center icon"
                onClick={togglePopover}
              >
                <Notification
                  open={popover}
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                />
                <Bell size={20} color="#0F69B4" />
                <Badge className="navbar-badge" color="secondary" variant="dot" invisible={invisible}></Badge>
              </Button>

              <Button
                className="navbar-button-circle d-flex justify-content-center align-items-center icon"
                onClick={() => {
                  history.push("/configuracion");
                }}
              >
                <Settings size={20} color="#0F69B4" />
              </Button>
            </div>
          </Grid>
          <Grid className="nombre-profile-header" md={2}>
            <div
              style={{ display: "flex", textAlign: "right", float: "right" }}
            >
              {userInfo && (
                <div className="div-nombre-header">
                  <p>{userInfo.user.first_name}</p>
                  <p>{userInfo.user.last_name}</p>
                </div>
              )}
              <div
                onClick={() => setAbrirMiniPanel(!abrirMiniPanel)}
                id="div-div-imagen-de-perfil"
              >
                <img
                  className="navbar-profile-picture"
                  src={
                    "https://app01.pwa.expandit.io/display-avatar/" +
                    userInfo.avatar
                  }
                  alt={"profile"}
                />
              </div>
            </div>
            {abrirMiniPanel && (
              <MiniPanelUsuario
                id="area-min-panel-usuario"
                usuario={userInfo}
                setAbrirMiniPanel={setAbrirMiniPanel}
              />
            )}
          </Grid>
        </Grid> */}
      </AppBar>
    </div>
  );
};
