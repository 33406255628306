import * as types from '../../consts/actionTypes'

const initialState = {
    proyectos:[]
}

export default function (state = initialState, action) {

    switch (action.type) {

        case types.GET_TENDENCIAS_PROYECTOS:
            return {
                ...state
            }

        case types.GET_TENDENCIAS_PROYECTOS_SUCCESS:
            console.log(action.tendenciasProyecto.data)
            return {
                ...state,
                proyectosTendencias: action.tendenciasProyecto.data
            }

        case types.GET_PROYECTOS_SUCCESS:
            return {
                ...state,
                proyectos: action.proyectos.data
            }

        case types.GET_ONE_PROYECTO_SUCCESS:
            return {
                ...state,
                oneProyecto: action.proyecto.data
            }

        default:
            return {
                ...state
            }
    }
}