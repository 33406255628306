import React from "react";
import { makeStyles } from "@material-ui/core";
import PDFIcon from "../../assets/images/pdf.png";

const useStyles = makeStyles(() => ({
  sentMessage: {
    backgroundColor: "var(--chat-main-color)",
    borderRadius: "5px",
    padding: "20px",
    maxWidth: "300px",
    wordBreak: "break-all",
  },
  receivedMessage: {
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "20px",
    maxWidth: "300px",
    wordBreak: "break-all",
    border: "1px solid  var(--searchForm-section-color)",
    borderRadius: "5px",
    marginBottom: "20px",
    boxShadow: "0px 5px 25px rgba(0, 0, 0, 0.1)",
  },
  imageWrapper: {
    justifyContent: "center",
    display: "flex",
    margin: "10px 0 10px 0",
    backgroundColor: "white",
    padding: "5px",
    borderRadius: "5px",
    maxHeight: "150px",
    width: "auto",
    "&:hover": {
      cursor: "pointer",
    },
  },
  imageWrapperReverse: {
    justifyContent: "center",
    display: "flex",
    margin: "10px 0 10px 0",
    backgroundColor: "var(--chat-main-color)",
    padding: "5px",
    borderRadius: "5px",
    maxHeight: "150px",
    width: "auto",
    "&:hover": {
      cursor: "pointer",
    },
  },
  imageRender: {
    objectFit: "cover",
    width: "100%",
  },
  pdfRender: {
    backgroundColor: "inherit",
    border: "none",
    outline: "none",
    textDecoration: "underline",
    cursor: "pointer",
    color: "var(--chat-main-color)",
    fontSize: "14px",
    wordWrap: "break-word",
    width: "calc(100% - 50px)",
  },
  pdfRenderReverse: {
    backgroundColor: "inherit",
    border: "none",
    outline: "none",
    textDecoration: "underline",
    cursor: "pointer",
    color: "white",
    width: "calc(100% - 50px)",
    wordWrap: "break-word",
    fontSize: "14px",
  },
  pdfWrapper: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    borderRadius: "5px",
    padding: "20px",
    maxWidth: "300px",
    margin: "10px 0 10px 0",
  },
  pdfWrapperReverse: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "var(--chat-main-color)",
    borderRadius: "5px",
    padding: "20px",
    maxWidth: "300px",
    margin: "10px 0 10px 0",
  },
  pdfIcon: {
    height: "50px",
    width: "auto",
  },
  receivedConversationText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    color: "black",
    marginBottom: 0,
    whiteSpace: "pre-line",
  },
  sentConversationText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    color: "white",
    marginBottom: 0,
    whiteSpace: "pre-line",
  },
}));

const MessagesRender = ({ item, has_image, has_pdf, is_message_owner }) => {
  const classes = useStyles();

  const handleOpenPdf = () => {
    window.open(item.file.file);
  };

  const getFileName = (file) => {
    const split = file.split("/");
    const name = split[split.length - 1];
    return name;
  };

  return (
    <div
      className={
        is_message_owner ? "chat-mensajes-enviados" : "chat-mensajes-recibidos"
      }
    >
      {has_image && (
        <div
          className={
            is_message_owner
              ? classes.imageWrapper
              : classes.imageWrapperReverse
          }
          onClick={() => window.open(item.file.file)}
        >
          <img className={classes.imageRender} src={item.file.file} alt="img" />
        </div>
      )}

      {has_pdf && (
        <div
          className={
            is_message_owner ? classes.pdfWrapper : classes.pdfWrapperReverse
          }
        >
          <img className={classes.pdfIcon} src={PDFIcon} />
          <button
            className={
              is_message_owner ? classes.pdfRender : classes.pdfRenderReverse
            }
            onClick={() => handleOpenPdf(item.file.file)}
            src={item.content}
            alt="pdf"
          >
            {getFileName(item.file.file)}
          </button>
        </div>
      )}

      {item.content && (
        <div>
          <p
            className={
              is_message_owner
                ? classes.sentConversationText + " texto-chat-mensajes-enviados"
                : classes.receivedConversationText +
                  " texto-chat-mensajes-recibidos"
            }
            // dangerouslySetInnerHTML={{ __html: item.content }}
          >
            {item.content}
          </p>
        </div>
      )}
    </div>
  );
};

export default MessagesRender;
