export const TOPIC_CONSTANTS = [
  {
    label: "Reparación de calle o avenida",
    value: "1",
  },
  {
    label: "Internet gratis en las plazas",
    value: "2",
  },
  {
    label: "Fuga de agua en la región",
    value: "3",
  },
  {
    label: "Problemas ambientales en la región",
    value: "4",
  },
  {
    label: "Atención de funcionarios en la región",
    value: "5",
  },
  {
    label: "Problemas de seguridad en la región",
    value: "6",
  },
];

export const AREA_CONSTANTS = [
  "UNIDAD DE PARTICIPACIÓN CIUDADANA",
  "SUPERIORES A LA UNIDAD DE PARTICIPACION CIUDADANA",
  "JEFATURA DE GABINETE",
  "INTENDENTE / ADMINISTRADOR REGIONAL",
  "SENAME",
  "SERNAC",
  "INDAP",
  "SERNATUR",
  "SUBPESCA",
  "SERNAPESCA",
  "DIREC. PROVINCIAL DE EDUCACIÓN TARAPACÁ",
  "DIREC. DEL TRABAJO",
  "SENCE",
  "IPS",
  "DIREC. DE AGUAS",
  "INE",
  "IND",
  "SERNAMEG",
  "CORFO",
  "SERCOTEC",
  "SENADIS",
  "SENAMA",
  "INJUV",
  "FOSIS",
  "CONADI",
  "JUNAEB",
  "JUNJI",
  "SERVIU",
].map((area) => {
  return {
    label: area,
    value: area,
  };
});

export const ANTIQUITY_CONSTANTS = [
  {
    label: "Más recientes",
    value: 0,
  },
  {
    label: "Últimos 30 días",
    value: 30,
  },
  {
    label: "Últimos 3 meses",
    value: 90,
  },
  {
    label: "Últimos 6 meses",
    value: 120,
  },
  {
    label: "Personalizado",
    value: "personalizado",
  },
];
