import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

import IconoCalendarioAzul from "../../assets/icons/calendario.svg";
import IconoUsuarioAzul from "../../assets/icons/usuario-azul.svg";
import IconoComentariosAzul from "../../assets/icons/doble-cuadro-chat-azul.svg";
import IconoEncuestaAzul from "../../assets/icons/encuesta-azul.svg";
import imagenDefault from "../../assets/images/PWA-imagen-default.jpg";
import Swal from "sweetalert2";
const CardComunicados = (props) => {
  const localTime = moment(props.subtitle);
  const history = useHistory();
  moment.locale("es");
  localTime.locale(false);

  const goToEncuesta = (encuesta) => {
    if (encuesta.estado === "3") {
      Swal.fire({
        title:
          "Esta votación ha terminado, puede ver los resultados a continuación",
        confirmButtonText: `Ok`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          history.push(`/votaciones/estadisticas/${encuesta.id}`);
        }
      });
    } else {
      history.push(`/votaciones/${encuesta.id}`);
    }
  };

  console.log(props.encuesta);

  return (
    <Link to={props.to} className={props.classnameLink}>
      <Card style={{ marginBottom: "1.2em" }} className="cuerpo-tarjeta-accion">
        <Card.Body>
          <Row>
            <Col sm={8} className="info-tarjeta-accion">
              <Card.Title className="titulo-acciones-en-proyectos">
                {props.title}
              </Card.Title>
              <Card.Subtitle className="mb-4 text-muted fecha-area-accion-en-proyecto">
                <img src={IconoCalendarioAzul} alt="fecha" />
                <p>{localTime.format("LL")}</p>
                {props.area && <img src={IconoUsuarioAzul} alt="area" />}
                <p>Ayuntamiento</p>
              </Card.Subtitle>

              <Card.Text>
                <p
                  dangerouslySetInnerHTML={{ __html: props.description }}
                  className="contenido-accion-en-proyecto"
                ></p>
              </Card.Text>
              <Card.Text>{props.badges}</Card.Text>
              <div className="div-comentarios-encuesta-accion-en-proyecto">
                <div>
                  <img src={IconoComentariosAzul} alt="numero de comentarios" />
                  <p>{props.numComentarios} Comentarios</p>
                </div>
                {props.encuesta && (
                  <div>
                    <img src={IconoEncuestaAzul} alt="Encuesta" />
                    <Link onClick={() => goToEncuesta(props.encuesta)}>
                      {props.encuesta.nombre}
                    </Link>
                  </div>
                )}
              </div>
            </Col>
            <Col sm={4} style={{ height: "fit-content" }}>
              <div
                className="div-imagen-tarjetas-acciones"
                style={{
                  backgroundImage:
                    "url(" +
                    (props.imageCard ? props.imageCard : imagenDefault) +
                    ")",
                }}
              >
                <div
                  style={{
                    backgroundImage:
                      "url(" +
                      (props.imageCard ? props.imageCard : imagenDefault) +
                      ")",
                  }}
                />
              </div>
              <div className="div-comentarios-encuesta-accion-en-proyecto-movil">
                <div>
                  <img src={IconoComentariosAzul} alt="numero de comentarios" />
                  <p>{props.numComentarios} Comentarios</p>
                </div>
                {props.encuesta && (
                  <div>
                    <img src={IconoEncuestaAzul} alt="Encuesta" />
                    <Link to={`/votaciones/${props.encuesta.id}`}>
                      {props.encuesta.nombre}
                    </Link>
                  </div>
                )}
              </div>
              <div className={props.classNameSocialMedia}>
                <Card.Text>{props.socialMedia}</Card.Text>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Link>
  );
};

export default CardComunicados;
