import React, { Component } from 'react';

import ProposalsNavigation from '../components/proposals-navigation/ProposalsNavigation';
import CardInfoComponent from '../components/card-proposals/CardInfoComponent';
import BadgeComponent from '../components/elements/BadgeComponent';
import SocialMediaComponent from '../components/elements/SocialMediaComponent';

import { BADGES } from '../components/constants/ProposalsConstants';

import infoImage from '../assets/images/Rectangle-151.png';

import './propuestasInfo.css';

class MyRequestsInfo extends Component {
	renderBadges = () => {
		return BADGES.map((badge) => {
			return <BadgeComponent className="badge-component-style" text={badge.text} />;
		});
	};

	renderSocialMediaUpper = () => {
		return (
			<SocialMediaComponent
				classNameSocialMediaDiv="social-media-centered-text"
				classNameSocialMediaIcons="custom-social-media-icons-mis-solicitudes-info"
			/>
		);
	};

	renderNavigation = () => {
		return <ProposalsNavigation paragraph="Inicio" title="Solicitud ciudadana" isHaveButton={true} />;
	};

	renderProposalsInfoContent = () => {
		return (
			<div className="proposals-info-content">
				<CardInfoComponent
					classNameUpperPartDiv="custom-solicitudes-info-upper-div-style"
					classNameTitleDiv="custom-solicitudes-info-title-div-style"
					classNameSocialMediaUpperDiv="custom-silicitudes-info-social-media-div-style"
					classNameTitle="card-big-title"
					classNameImageDiv="custom-solicitudes-div-style"
					title="Ciudadanos abogan por la creación de un centro de reciclaje en Camiña"
					classNameSubtitle="subtitle-style"
					subtitle="12 de Junio e 2020"
					badges={this.renderBadges()}
					classNameText="card-text-style"
					text="Creación de un consejo ciudadano para cuidar el ambiente en Pica. Creación de un consejo ciudadano para cuidar el ambiente en Pica."
					src={infoImage}
					isHaveSocialMediaUpper={true}
					socialMediaUpper={this.renderSocialMediaUpper()}
				/>
			</div>
		);
	};

	render() {
		return (
			<div className="propuestas-content-page">
				{this.renderNavigation()}
				{this.renderProposalsInfoContent()}
			</div>
		);
	}
}

export default MyRequestsInfo;
