import React, { Component } from "react";
import { withRouter, useLocation } from "react-router-dom";

class ScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			document.body.scrollTop = 0;
		}
	}

	render() {
		return <React.Fragment />
	}
}

export default withRouter(ScrollToTop)