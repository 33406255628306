import React from 'react'
import { Clipboard, List, FileText, CheckSquare } from 'react-feather'
import { Container, Button } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'

const SuccessVote = () => {

    const {idEncuesta} = useParams()

    console.log(idEncuesta)

    return (
        <Container style={{marginTop:"5em", backgroundColor:"white", padding:"5em 0em"}}>
            <div style={{textAlign:"center"}}>
                <CheckSquare size="5em" />
            </div>
            <div className="text" style={{textAlign:"center"}}>
                <h6>¡Gracias! Tu votación ha sido enviada.</h6>
            </div>
            <div className="buttonResult" style={{textAlign:"center"}}>
                <Link to={"/votaciones/estadisticas/" + idEncuesta}>
                    <Button>Ver Estadísticas</Button>
                </Link>
            </div>
        </Container>
    )

}

export default SuccessVote