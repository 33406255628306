import React from "react";

import { RotateCw } from "react-feather";

import "./ModalResRequest.css";

import ModalEstandar from "../modalEstandar/ModalEstandar";

const ModalResRequest = ({
  loading,
  mensajeDeEspera,
  exito,
  mensajeExito,
  mensajeError,
  visibilidad,
  setModalResRequest,
}) => {
  return (
    <ModalEstandar
      visibilidad={visibilidad}
      forzado={true}
      setModal={setModalResRequest}
    >
      <div className="contendor-modal-resrequest">
        {loading ? (
          <div className="div-loading-modal-resrequest">
            <RotateCw className="icono-rotate-modal-resrequest" />
            <p>{mensajeDeEspera}</p>
          </div>
        ) : (
          <div className="div-resultado-modal-resrequest">
            <p>
              {exito === true && mensajeExito}
              {exito === false && mensajeError}
            </p>
            <button onClick={() => setModalResRequest(false)}>Cerrar</button>
          </div>
        )}
      </div>
    </ModalEstandar>
  );
};

export default ModalResRequest;
