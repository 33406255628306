import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../api/pwa_api";
import * as Services from "../services/authServices";
import * as types from "../../consts/actionTypes";

export function* getAccionesSagas({ payload }) {
  try {
    const acciones = yield call(
      apiCall,
      "comunicados/",
      {},
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "GET"
    );

    yield put({ type: types.GET_ACCIONES_SUCCESS, acciones });
  } catch (error) {
    console.log(error);
    yield put({ type: types.GET_REQUEST_ERROR });
  }
}

export function* getOneAccionSagas({ payload }) {
  try {
    const accion = yield call(
      apiCall,
      "comunicados/" + payload,
      {},
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "GET"
    );
    console.log("Accion solicitada satisfactoriamente")
    yield put({ type: types.GET_ONE_ACCION_SUCCESS, accion });
  } catch (error) {
    console.log("Error al solicitar accion: ",error);
    yield put({ type: types.GET_REQUEST_ERROR });
  }
}

export function* apoyarAccionSagas({ payload }) {
  try {
    const accion = yield call(
      apiCall,
      "comunicados/apoyar/",
      {comunicado: payload},
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Services.getAccessToken(),
      },
      "POST"
    );
    console.log("Accion apoyada satisfactoriamente");
    yield put({ type: types.APOYAR_ACCION_SUCCESS, accion });
  } catch (error) {
    console.log("Error al apoyar la accion: ", error);
    yield put({ type: types.APOYAR_ACCION_ERROR });
  }
}

export default function* accionSagas() {
  yield takeLatest(types.GET_ACCIONES, getAccionesSagas);
  yield takeLatest(types.GET_ONE_ACCION, getOneAccionSagas);
  yield takeLatest(types.APOYAR_ACCION, apoyarAccionSagas);
}
