import React, { Component, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import {
  Button,
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import sectores from "../consts/sectores";
import juntasVecinales from "../consts/juntasVecinales";
import comunas from "../consts/comunas";

//Actions
import {
  postPropuesta,
  putPropuesta,
  deletePropuesta,
  getMyPropuestas,
  successFlagToFalse,
} from "../redux/actions/propuestaActions";

import * as SelectorPropuestas from "../redux/selectors/propuestaSelector";
import {
  ExternalLink,
  ChevronLeft,
  Upload,
  Archive,
  Trash,
} from "react-feather";
import IconoPropuestaEnviada from "../assets/icons/propuesta-enviada.svg";
import "./nuevaPropuesta.css";

//Componentes
import ModalEstandar from "../components/modalEstandar/ModalEstandar";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";

const CreateProposal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { idPropuesta } = useParams();
  const myPropuestas = useSelector((state) =>
    SelectorPropuestas.myPropuestas(state)
  );
  const successFlag = useSelector((state) =>
    SelectorPropuestas.successFlagResult(state)
  );
  const respuestaPost = useSelector((state) =>
    SelectorPropuestas.responsePostPropuesta(state)
  );
  const respuestaPut = useSelector((state) =>
    SelectorPropuestas.responsePutPropuesta(state)
  );

  console.log(successFlag, respuestaPost, respuestaPut);

  //Estados
  const [titulo, setTitulo] = useState();
  const [sector, setSector] = useState();
  const [imagen, setImagen] = useState();
  const [existeImagen, setExisteImagen] = useState(false);
  const [contenido, setContenido] = useState();
  const [etiquetas, setEtiquetas] = useState([]);
  const [comunaDestino, setComunaDestino] = useState("General");
  const [juntaVecinalDestino, setJuntaVecinalDestino] = useState();
  const [esBorrador, setEsBorrador] = useState();
  const [modal, setModal] = useState(false);

  const handleEnviar = (estatus) => {
    if (titulo && contenido && sector && comunaDestino) {
      let body = new FormData();
      body.append("titulo", titulo);
      body.append("sector", sector);
      if (imagen) {
        body.append("imagen", imagen);
      }
      body.append("contenido", contenido);
      body.append("comuna_destino", comunaDestino);
      body.append(
        "junta_vecinal",
        juntaVecinalDestino ? juntaVecinalDestino : null
      );
      body.append("status", estatus);
      body.append("etiquetas", JSON.stringify(etiquetas));
      if (estatus === "B") {
        setEsBorrador(true);
      }
      if (idPropuesta) {
        dispatch(putPropuesta({ id: idPropuesta, body: body, setModal }));
        //setModal(true);
      } else {
        dispatch(postPropuesta({ body, setModal }));
        //setModal(true);
      }
    } else {
      Swal.fire(
        "Complete los datos",
        "Asegurese de que el titulo, el contenido, el sector y el distrito destino no estén vacions",
        "warning"
      );
    }
  };

  const handleBorrar = () => {
    dispatch(deletePropuesta(idPropuesta));
    history.push("/mis-propuestas");
  };

  const handleTags = (string, key) => {
    let contenidoInput = document.getElementById(
      "input-etiquetas-crear-propuesta"
    ).value.replaceAll(',','');
    if (
      (key === "Enter") &&
      contenidoInput != "" &&
      etiquetas.length < 10
    ) {
      let arr = [...etiquetas];
      string.trim();
      arr.push({ nombre: string });
      setEtiquetas(arr);
      document.getElementById("input-etiquetas-crear-propuesta").value = "";
    } else if (string === "" && key === "Backspace" && etiquetas.length > 0) {
      let arr = [...etiquetas];
      arr.pop();
      setEtiquetas(arr);
    } else if (etiquetas.length >= 10) {
      document.getElementById("input-etiquetas-crear-propuesta").value = "";
    }
  };

  useEffect(() => {
    dispatch(getMyPropuestas());
  }, []);

  useEffect(() => {
    if (myPropuestas && idPropuesta) {
      const miPropuesta =
        myPropuestas.filter(
          (elemento) => Number(elemento.id) === Number(idPropuesta)
        ).length > 1
          ? false
          : myPropuestas.filter(
              (elemento) => Number(elemento.id) === Number(idPropuesta)
            )[0];
      if (miPropuesta === undefined) {
        Swal.fire(
          "Sin autorización",
          "Esa propuesta no pertenece a sus propuestas",
          "error"
        );
        history.push("/mis-propuestas");
      } else if (miPropuesta === false) {
        Swal.fire(
          "Hubo un error",
          "Hay dos propuestas con el mismo número de identificación, contacte a soporte por favor",
          "error"
        );
        history.push("/mis-propuestas");
      } else {
        setTitulo(miPropuesta.titulo);
        setContenido(miPropuesta.contenido);
        setSector(miPropuesta.sector);
        setComunaDestino(miPropuesta.comuna_destino);
        setJuntaVecinalDestino(miPropuesta.junta_vecinal);
        setContenido(miPropuesta.contenido);
        setExisteImagen(true);
        setEtiquetas(
          miPropuesta.etiquetas.map((etiqueta) => {
            return { nombre: etiqueta.nombre };
          })
        );
      }
    }
  }, [myPropuestas]);

  useEffect(() => {
    if (existeImagen && imagen) {
      setExisteImagen(false);
    }
  }, [imagen]);

  console.log(comunaDestino);

  return (
    <div>
      <Helmet>
        <title>Participación Ciudadana | Crear Propuesta</title>
      </Helmet>
      <section id="header-propuesta">
        <Link id="regresar-propuesta" to={"/mis-propuestas"}>
          <ChevronLeft />
          <p>Regresar a Mis Propuestas</p>
        </Link>
        <div id="div-header-titulo-botones-propuesta">
          <p id="titulo-propuesta">
            {idPropuesta ? (
              <p>Editar propuesta: {idPropuesta}</p>
            ) : (
              <p>Crear propuesta</p>
            )}
          </p>
          <div id="div-header-botones-propuesta">
            {idPropuesta && (
              <Button
                className="boton-enviar-propuesta-borrador"
                onClick={handleBorrar}
              >
                <Trash />
                <p>Borrar</p>
              </Button>
            )}
            <Button
              className="boton-enviar-propuesta-borrador"
              onClick={() => handleEnviar("B")}
            >
              <Archive />
              <p>Guardar como borrador</p>
            </Button>
            <Button
              className="boton-enviar-propuesta-detalle"
              onClick={() => handleEnviar("ER")}
            >
              <ExternalLink />
              <p>Enviar</p>
            </Button>
          </div>
        </div>
      </section>
      <section id="datos-propuesta">
        <Form id="form-propuesta">
          <Row className="secciones-propuesta">
            <Col sm={6}>
              <label className="labels-propuesta">Titulo de la Propuesta</label>
              <InputGroup>
                <FormControl
                  type="text"
                  maxLength="150"
                  placeholder="Ingrese el titulo de la propuesta"
                  className="inputs-propuesta"
                  defaultValue={titulo}
                  onChange={(event) => setTitulo(event.target.value)}
                />
              </InputGroup>
            </Col>
            <Col sm={3}>
              <label className="labels-propuesta">Sector</label>
              <FormControl
                as="select"
                value={sector ? sector : "Seleccione..."}
                className="inputs-propuesta"
                onChange={(event) => setSector(event.target.value)}
              >
                <option value={false}>Seleccione...</option>
                {sectores.map((sectorin) => (
                  <option>{sectorin}</option>
                ))}
              </FormControl>
            </Col>
            <Col sm={3}>
              <label
                htmlFor="subir-imagen-proyecto"
                className="labels-propuesta"
              >
                Subir Imagen
              </label>
              <input
                id="subir-imagen-propuesta"
                type="file"
                onChange={(event) => setImagen(event.target.files[0])}
              />

              <Button
                id="boton-subir-imagen-propuesta"
                onClick={() => {
                  if (document.getElementById("subir-imagen-propuesta")) {
                    document.getElementById("subir-imagen-propuesta").click();
                  }
                }}
              >
                {existeImagen ? (
                  <p>Subir nueva imagen</p>
                ) : (
                  <p>{imagen ? imagen.name : "JPEG, JPG o PNG"}</p>
                )}
                <Upload />
              </Button>
            </Col>
          </Row>
          <Row className="secciones-propuesta">
            <Col sm={12}>
              <label className="labels-propuesta">
                Contenido de la Propuesta
              </label>
              <ReactQuill
                theme="snow"
                value={contenido ? contenido.toString() : ""}
                onChange={(content) => setContenido(content)}
                rows="20"
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link", "image"],
                    ["clean"],
                  ],
                }}
                formats={[
                  "header",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "blockquote",
                  "list",
                  "bullet",
                  "indent",
                  "link",
                  "image",
                ]}
              ></ReactQuill>
            </Col>
          </Row>
          <Row className="secciones-propuesta">
            <Col sm={8}>
              <label
                htmlFor="input-nombre-de-encuesta"
                className="labels-propuesta"
              >
                Tags
              </label>
              <div
                id="div-etiquetas-crear-propuesta"
                className="input-crear-propuesta"
              >
                <div id="div-solo-etiquetas-crear-propuesta">
                  {etiquetas &&
                    etiquetas.map((etiqueta) => (
                      <p className="etiquetas-propuesta">{etiqueta.nombre}</p>
                    ))}
                </div>
                <InputGroup>
                  <FormControl
                    type="text"
                    name="input-nombre-de-encuesta"
                    id="input-etiquetas-crear-propuesta"
                    onKeyDown={(event) =>
                      handleTags(event.target.value, event.key)
                    }
                  />
                </InputGroup>
              </div>
            </Col>
            <Col sm={4}>
              <label className="labels-propuesta">Provincia Destino</label>
              <FormControl
                as="select"
                value={comunaDestino ? comunaDestino : "General"}
                className="inputs-propuesta"
                onChange={(event) => {
                  setComunaDestino(event.target.value);
                  if (
                    event.target.value != "Alto Hospicio" &&
                    event.target.value != "Iquique"
                  ) {
                    setJuntaVecinalDestino("");
                    console.log("Se elimino la junta vecinal");
                  }
                }}
              >
                <option value="General">General</option>
                {comunas.map((comuna,i) => (
                  <option>{comuna}</option>
                ))}
              </FormControl>
              {comunaDestino === "Iquique" && (
                <div>
                  <label className="labels-propuesta">
                    Junta Vecinal Destino
                  </label>
                  <FormControl
                    as="select"
                    defaultValue={
                      juntaVecinalDestino
                        ? juntaVecinalDestino
                        : "Seleccione..."
                    }
                    className="inputs-propuesta"
                    onChange={(event) =>
                      setJuntaVecinalDestino(event.target.value)
                    }
                  >
                    <option value="">Seleccione...</option>
                    {Object.keys(juntasVecinales["IQUIQUE"]).map((comuna) => (
                      <option>{comuna}</option>
                    ))}
                  </FormControl>
                </div>
              )}
              {comunaDestino === "Alto Hospicio" && (
                <div style={{ marginTop: "10px" }}>
                  <label className="labels-propuesta">
                    Junta Vecinal Destino
                  </label>
                  <FormControl
                    as="select"
                    defaultValue={
                      juntaVecinalDestino
                        ? juntaVecinalDestino
                        : "Seleccione..."
                    }
                    className="inputs-propuesta"
                    onChange={(event) =>
                      setJuntaVecinalDestino(event.target.value.toUpperCase())
                    }
                  >
                    <option value={false}>Seleccione...</option>
                    {Object.keys(juntasVecinales["ALTO HOSPICIO"]).map(
                      (comuna) => (
                        <option>{comuna.toLowerCase()}</option>
                      )
                    )}
                  </FormControl>
                </div>
              )}
            </Col>
          </Row>
        </Form>
      </section>
      <ModalEstandar visibilidad={modal} setModal={setModal} forzado={true}>
        <div className="modal-propuesta-enviada">
          <div className="header-modal-propuesta-enviada">
            <p>{esBorrador ? "Propuesta Guardada" : "Propuesta Enviada"}</p>
          </div>
          <div className="body-modal-propuesta-enviada">
            {successFlag && (
              <img src={IconoPropuestaEnviada} alt="propuesta-enviada" />
            )}
            <p>
              {successFlag && (respuestaPut || respuestaPost)
                ? esBorrador
                  ? respuestaPut || respuestaPost
                    ? "Su propuesta ha sido guardada con exito"
                    : "Hubo un error al intentar guardar su propuesta"
                  : respuestaPut || respuestaPost
                  ? "Su propuesta ha sido enviada con exito, un moderador la revisara a la brevedad para proceder a su publicacion"
                  : "Hubo un error al enviar su propuesta"
                : esBorrador
                ? "Guardando propuesta"
                : "Enviando Propuesta"}
            </p>
          </div>
          <div className="footer-modal-propuesta-enviada">
            {successFlag ? (
              <button
                onClick={() => {
                  setModal(false);
                  dispatch(successFlagToFalse());
                  history.push("/mis-propuestas");
                }}
              >
                Regresar a mis Propuestas
              </button>
            ) : null}
          </div>
        </div>
      </ModalEstandar>
    </div>
  );
};

export default CreateProposal;
