import verticalImg from '../../assets/images/login_bg.png'

export const fakeVotaciones = [
    {
        id: 3,
        imagen: verticalImg,
        nombre: "Tarapacá 100% Sostenible, Billete único para el transporte público y Plaza de Chile (Barrio Central)",
        preguntas: [
            { pregunta: "¿Estás de acuerdo en que el transporte público colectivo debe mantener su prioridad en la circulación rodada en la Gran Vía?" },
            { pregunta: "¿Consideras que sería necesario incrementar el número de pasos peatonales de la Gran Vía para mejorar la comunicación peatonal?" },
            { pregunta: "¿Estás de acuerdo con mejorar el espacio peatonal de la Gran Vía mediante la ampliación de sus aceras?" },
            { pregunta: "¿Consideras necesario mejorar las condiciones de las plazas traseras vinculadas a Gran Vía para que puedan ser utilizadas como espacio de descanso y/o de estancia?" },

        ],
        area: "Público",
        timeRemaining: "Quedan 3 días",
        inYourUbication: true
    },
    {
        id: 3,
        imagen: verticalImg,
        nombre: "Tarapacá 100% Sostenible, Billete único para el transporte público y Plaza de Chile (Barrio Central)",
        preguntas: [
            { pregunta: "¿Estás de acuerdo en que el transporte público colectivo debe mantener su prioridad en la circulación rodada en la Gran Vía?" },
            { pregunta: "¿Consideras que sería necesario incrementar el número de pasos peatonales de la Gran Vía para mejorar la comunicación peatonal?" },
            { pregunta: "¿Estás de acuerdo con mejorar el espacio peatonal de la Gran Vía mediante la ampliación de sus aceras?" },
            { pregunta: "¿Consideras necesario mejorar las condiciones de las plazas traseras vinculadas a Gran Vía para que puedan ser utilizadas como espacio de descanso y/o de estancia?" },

        ],
        area: "Público",
        timeRemaining: "Quedan 3 días",
        inYourUbication: true
    },
    {
        id: 3,
        imagen: verticalImg,
        nombre: "Tarapacá 100% Sostenible, Billete único para el transporte público y Plaza de Chile (Barrio Central)",
        preguntas: [
            { pregunta: "¿Estás de acuerdo en que el transporte público colectivo debe mantener su prioridad en la circulación rodada en la Gran Vía?" },
            { pregunta: "¿Consideras que sería necesario incrementar el número de pasos peatonales de la Gran Vía para mejorar la comunicación peatonal?" },
            { pregunta: "¿Estás de acuerdo con mejorar el espacio peatonal de la Gran Vía mediante la ampliación de sus aceras?" },
            { pregunta: "¿Consideras necesario mejorar las condiciones de las plazas traseras vinculadas a Gran Vía para que puedan ser utilizadas como espacio de descanso y/o de estancia?" },

        ],
        area: "Barrio Central",
        timeRemaining: "Quedan 3 días",
        inYourUbication: false
    },
    {
        id: 3,
        imagen: verticalImg,
        nombre: "Tarapacá 100% Sostenible, Billete único para el transporte público y Plaza de Chile (Barrio Central)",
        preguntas: [
            { pregunta: "¿Estás de acuerdo en que el transporte público colectivo debe mantener su prioridad en la circulación rodada en la Gran Vía?" },
            { pregunta: "¿Consideras que sería necesario incrementar el número de pasos peatonales de la Gran Vía para mejorar la comunicación peatonal?" },
            { pregunta: "¿Estás de acuerdo con mejorar el espacio peatonal de la Gran Vía mediante la ampliación de sus aceras?" },
            { pregunta: "¿Consideras necesario mejorar las condiciones de las plazas traseras vinculadas a Gran Vía para que puedan ser utilizadas como espacio de descanso y/o de estancia?" },

        ],
        area: "Público",
        timeRemaining: "Quedan 3 días",
        inYourUbication: true
    }
]

export const fakeVotationQuiz =
    [
        {
            id: 5,
            pregunta: "¿No de integrantes de su familia?",
            tipo: 2,
            indice: 1,
            encuesta: 2,
            respuestas: [
                {
                    id: 10,
                    etiqueta: "2 - 4",
                    valor: "2 - 4"
                },
                {
                    id: 11,
                    etiqueta: "5 - 6",
                    valor: "5 - 6"
                },
                {
                    id: 12,
                    etiqueta: "Más de 7",
                    valor: "Más de 7"
                }
            ]
        },
        {
            id: 6,
            pregunta: "¿Estás de acuerdo en que el transporte público colectivo debe mantener su prioridad en la circulación rodada en la Gran Vía?",
            tipo: 1,
            indice: 2,
            encuesta: 2,
            respuestas: [
                {
                    id: 13,
                    etiqueta: "Totalmente de Acuerdo",
                    valor: "Totalmente de Acuerdo"
                },
                {
                    id: 14,
                    etiqueta: "De Acuerdo",
                    valor: "De Acuerdo"
                },
                {
                    id: 15,
                    etiqueta: "Ni de acuerdo ni desacuerdo",
                    valor: "Ni de acuerdo ni desacuerdo"
                },
                {
                    id: 16,
                    etiqueta: "En desacuerdo",
                    valor: "En desacuerdo"
                },
                {
                    id: 17,
                    etiqueta: "No estoy de acuerdo",
                    valor: "No estoy de acuerdo",
                }
            ]
        },
        {
            id: 7,
            pregunta: "¿Qué cantidad considerarías incrementar el número de pasos peatonales de la Gran Vía para mejorar la comunicación peatonal?",
            tipo: 1,
            indice: 3,
            encuesta: 2,
            respuestas: [
                {
                    id: 18,
                    etiqueta: "1",
                    valor: "1"
                },
                {
                    id: 19,
                    etiqueta: "2",
                    valor: "2"
                },
                {
                    id: 20,
                    etiqueta: "3",
                    valor: "3"
                },
                {
                    id: 21,
                    etiqueta: "4",
                    valor: "4"
                }
            ]
        },
        {
            id: 8,
            pregunta: "¿Consideras que sería necesario incrementar el número de pasos peatonales de la Gran Vía para mejorar la comunicación peatonal?",
            tipo: 1,
            indice: 4,
            encuesta: 2,
            respuestas: [
                {
                    id: 22,
                    etiqueta: "Si",
                    valor: "Si"
                },
                {
                    id: 23,
                    etiqueta: "No",
                    valor: "No"
                }
            ]
        }
    ]


/**
 *
    quiz:[
        {
            question: "¿Estás de acuerdo en que el transporte público colectivo debe mantener su prioridad en la circulación rodada en la Gran Vía?",
            answers:[
                "Totalmente de Acuerdo",
                "De Acuerdo",
                "Ni de acuerdo ni desacuerdo",
                "En desacuerdo",
                "No estoy de acuerdo",

            ]
        },
        {
            question: "¿Consideras que sería necesario incrementar el número de pasos peatonales de la Gran Vía para mejorar la comunicación peatonal?",
            answers:[
                "Si",
                "Me abstengo",
                "No",
            ]
        },
        {
            question: "¿Estás de acuerdo con mejorar el espacio peatonal de la Gran Vía mediante la ampliación de sus aceras?",
            answers:[
                "Totalmente de Acuerdo",
                "De Acuerdo",
                "Ni de acuerdo ni desacuerdo",
                "En desacuerdo",
                "No estoy de acuerdo",

            ]
        },
        {
            question: "¿Qué cantidad considerarías incrementar el número de pasos peatonales de la Gran Vía para mejorar la comunicación peatonal?",
            answers:[
                "1",
                "2",
                "3",
                "4",

            ]
        },
    ]

 */