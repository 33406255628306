import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import icon from "../../assets/images/solicitudes/sm-user-add.png";

const drawerWidth = "12%";

const styles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  wrapper: {
    backgroundColor: "#f7f7f9",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth})`,
      marginLeft: drawerWidth,
    },
  },
  textWrapper: {
    padding: "20px",
  },
  inicio: {
    color: "#8987ad",
    fontSize: "14px",
  },
  title: {
    color: "#1d1961",
    fontSize: "18px",
    margin: 0,
  },
  boxWrapper: {
    backgroundColor: "#feffff",
    height: "calc(100vh - 132px - 75px)",
    margin: "20px",
    display: "flex",
    justifyContent: "center",
  },
  conversationBox: {
    paddingTop: "10%",
    color: "#828282",
    alignItems: "center",
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  img: {
    width: "45px",
    height: "45px",
  },
  boxTitle: {
    fontSize: "36px",
  },
  boxDescription: {
    fontSize: "16px",
  },
}));

const Solicitudes = () => {
  const classes = styles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.textWrapper}>
        <p className={classes.inicio}>Inicio</p>
        <h6 className={classes.title}>Propuestas ciudadanas</h6>
      </div>

      <div className={classes.boxWrapper}>
        <div className={classes.conversationBox}>
          <img className={classes.img} src={icon} />
          <p className={classes.boxTitle}>Aún no hay solicitudes</p>
          <p className={classes.boxDescription}>
            Crea una nueva solicitud para verla aquí
          </p>
        </div>
      </div>
    </div>
  );
};
export default Solicitudes;
