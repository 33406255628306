import React from "react";
import { Form, Col } from "react-bootstrap";

import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./DateComponent.css";

function range(startAt, end) {
  return [...Array(end - startAt).keys()].map((i) => i + startAt);
}

function getYear(date) {
  return date.getFullYear();
}

const dateComponent = ({
  md,
  controlId,
  classNameFormGroup,
  classNameLabel,
  labelName,
  selected,
  name,
  onDateChange,
}) => {
  const years = range(getYear(new Date()) - 5, getYear(new Date()) + 5);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <Form.Group
      as={Col}
      md={md}
      controlId={controlId}
      className={classNameFormGroup}
    >
      <Form.Label className="label-filtros">{labelName}</Form.Label>
      <DatePicker
        renderCustomHeader={({ date, changeYear, changeMonth }) => (
          <div className="input-filtros">
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[date.getMonth()]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        )}
        selected={selected}
        dateFormat="dd/MM/yyyy"
        // className="input-style centered-text"
        name={name}
        locale="pt-BR"
        onChange={onDateChange}
        wrapperClassName="date-filter-wrapper"
        className="input-filtros"
        popperClassName="date-filter-popper"
      />
    </Form.Group>
  );
};

export default dateComponent;
