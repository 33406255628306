import * as types from "../../consts/actionTypes";
const initialState = {
  comentarioComunicado: null,
  comentarioPropuesta: null,
  comentariosSuccessFlag: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.COMENTARIOS_SUCCESS_FLAG_TO_FALSE:
      return {
        ...state,
        comentariosSuccessFlag: false,
      };

    //Propuestas
    case types.POST_CREATE_PROPUESTAS_COMMENTS_SUCCESS:
      return {
        ...state,
        comentarioPropuesta: action.comentarioPropuesta.data,
        comentariosSuccessFlag: true,
      };

    case types.POST_VOTE_PROPUESTAS_COMMENTS_SUCCESS:
      return {
        ...state,
        voteComentarioPropuesta: action.voteComentarioPropuesta.data,
        comentariosSuccessFlag: true,
      };

    case types.DELETE_VOTE_PROPUESTAS_COMMENTS_SUCCESS:
      return {
        ...state,
        voteComentarioPropuesta: action.deleteComentarioPropuesta.data,
      };

    case types.PUT_VOTE_PROPUESTAS_COMMENTS_SUCCESS:
      return {
        ...state,
        voteComentarioPropuesta: action.updateComentarioPropuesta.data,
      };

    case types.DISLIKE_COMENTARIO_PROPUESTA_SUCCESS:
      return {
        ...state,
        comentariosSuccessFlag: true,
      };

    case types.RESPONDER_COMENTARIO_PROPUESTA_SUCCESS:
      return {
        ...state,
        comentariosSuccessFlag: true,
      };

    case types.EDIT_COMENTARIO_PROPUESTA_SUCCESS:
      return {
        ...state,
        comentariosSuccessFlag: true,
      };

    case types.DELETE_COMENTARIO_PROPUESTA_SUCCESS:
      return {
        ...state,
        comentariosSuccessFlag: true,
      };

    //Comunicados

    case types.POST_CREATE_COMUNICADOS_COMMENTS_SUCCESS:
      return {
        ...state,
        comentarioComunicado: action.comentarioComunicado.data,
        comentariosSuccessFlag: true,
      };

    case types.POST_VOTE_COMUNICADOS_COMMENTS_SUCCESS:
      return {
        ...state,
        voteComentarioComunicado: action.votoComentarioComunicado.data,
        comentariosSuccessFlag: true,
      };

    case types.PUT_VOTE_COMUNICADOS_COMMENTS_SUCCESS:
      return {
        ...state,
        voteComentarioComunicado: action.votoActualizadoComunicado.data,
      };

    case types.DELETE_VOTE_COMUNICADOS_COMMENTS_SUCCESS:
      return {
        ...state,
        voteComentarioComunicado: action.votoEliminadoComunicado.data,
      };

    case types.DISLIKE_COMENTARIO_ACCION_SUCCESS:
      return {
        ...state,
        comentariosSuccessFlag: true,
      };

    case types.RESPONDER_COMENTARIO_ACCION_SUCCESS:
      return {
        ...state,
        comentariosSuccessFlag: true,
      };

    case types.EDIT_COMENTARIO_ACCION_SUCCESS:
      return {
        ...state,
        comentariosSuccessFlag: true,
      };

    case types.DELETE_COMENTARIO_ACCION_SUCCESS:
      return {
        ...state,
        comentariosSuccessFlag: true,
      };

    default:
      return {
        ...state,
      };
  }
}
