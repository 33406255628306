import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import ProposalsNavigation from '../../../components/proposals-navigation/ProposalsNavigation'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions  from '../../../redux/actions/votacionActions'
import * as Selector from '../../../redux/selectors/votacionSelector'
import Estadisticas from './Estadisticas'

const EncuestasDetail = () => {

    const { idEncuesta } = useParams()
    const dispatch = useDispatch();
    const pollDetail = useSelector((state) => Selector.encuestaDetail(state))
    console.log(pollDetail)

    const getPollDetail = () =>{
        dispatch(Actions.getEncuestasDetails(idEncuesta))
    }

    useEffect(() =>{
        getPollDetail()
    },[])


    const renderProposalsNavigation = () => {
        return (
            <ProposalsNavigation
                paragraph="Regresar a Votaciones"
                goBackRoute={"/votaciones"}
            />
        )
    }

    return(
        <Container fluid>
            {renderProposalsNavigation()}
            {
                pollDetail.length > 0 ? 
                <Estadisticas encuesta ={pollDetail} />
                : <div></div>
            }
            {console.log(pollDetail)}
        </Container>
    )

}

export default EncuestasDetail