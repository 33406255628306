import { put, call, takeLatest } from "redux-saga/effects";
import { apiCall } from "../api/pwa_api";
import * as Services from "../services/authServices";
import * as types from "../../consts/actionTypes";

export function* contactaSiacSagas({ payload }) {
  try {
    const results = yield call(
      apiCall,
      "solicitudesSIAC/",
      null,
      {
        "Content-Type": "application/json",
        Host: "localhost:3000",
        Authorization: `Bearer ${Services.getAccessToken()}`,
      },
      "POST"
    );
    console.log("Contador para solicitudes SIAC satisfactorio");
    yield put({ type: types.POST_SOLICITUD_SIAC_SUCCESS, results });
  } catch (e) {
    console.log("Error al aumentar contador de solicitudesSIAC: ", e);
    yield put({ type: types.POST_SOLICITUD_SIAC_ERROR, payload });
  }
}

export default function* accionSagas() {
  yield takeLatest(types.POST_SOLICITUD_SIAC, contactaSiacSagas);
}
