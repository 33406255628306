import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Settings, User, MapPin, Mail, Info, LogOut } from "react-feather";
import { logOut } from "../../redux/actions/userActions";

//Utils
import { clicksInside } from "../../utils/ComponentUtils";

//Style
import "./MiniPanelUsuario.css";

const MiniPanelUsuario = ({ usuario, setAbrirMiniPanel }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [fechaYHora, setFechaYHora] = useState(new Date(Date.now()));

  const fechaParser = (fecha) => {
    const nombreDeMes = (num) => {
      switch (num) {
        case 0:
          return "enero";
        case 1:
          return "febrero";
        case 2:
          return "marzo";
        case 3:
          return "abril";
        case 4:
          return "mayo";
        case 5:
          return "junio";
        case 6:
          return "julio";
        case 7:
          return "agosto";
        case 8:
          return "septiembre";
        case 9:
          return "octubre";
        case 10:
          return "noviembre";
        case 11:
          return "diciembre";
        default:
      }
    };
    return `${fecha.getDate()} de ${nombreDeMes(
      fecha.getMonth()
    )} de ${fecha.getFullYear()}`;
  };


  useEffect(() => {
    clicksInside(["mini-panel-usuario", "div-div-imagen-de-perfil"], () =>
      setAbrirMiniPanel(false)
    );
  }, []);

  return (
    <div id="mini-panel-usuario">
      <p id="mini-panel-date">{fechaParser(fechaYHora)}</p>
      <div>
        <MapPin />
        <p>
          {usuario && usuario.user.first_name + " " + usuario.user.last_name}
        </p>
      </div>
      <div>
        <Mail />
        <p>{usuario && usuario.user.email}</p>
      </div>
      <div>
        <MapPin />
        <p>{usuario && "Ayuntamiento"}</p>
      </div>
      <div>
        <User />
        <p>{usuario && usuario.user.username}</p>
      </div>
      <div>
        <Info />
        <p>{usuario && usuario.user.id}</p>
      </div>
      <button
        id="mini-panel-boton-configuracion"
        onClick={() => {
          history.push("/configuracion")
          setAbrirMiniPanel(false)
        }}
      >
        <Settings />
        <p>Configuración</p>
      </button>
      <button id="mini-panel-boton-logout" onClick={() => dispatch(logOut())}>
        <LogOut />
        <p>Cerrar sesión</p>
      </button>
    </div>
  );
};

export default MiniPanelUsuario;
