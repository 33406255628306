export default {
    'Almería':{"lat": "37.194667", "lon":"-2.183385"},
    'Cádiz':{"lat": "36.600697", "lon":"-5.730637"},
    'Córdoba':{"lat":"37.864366" , "lon":"-4.790738"},
    'Granada':{"lat":"37.224029" , "lon":"-3.279184"},
    'Huelva':{"lat": "37.551510", "lon":"-6.847076"},
    'Jaén':{"lat":"38.040554" , "lon":"-3.369092"},
    'Málaga':{"lat":"36.701948" , "lon":"-4.450678"},
    'Sevilla':{"lat": "37.4937911", "lon":"-5.650263"}
}
// export default {
//     "CAMIÑA": { "lat": "-19.311605", "lon": "-69.426811" },
//     "COLCHANE": { "lat": "-19.275561", "lon": "-68.638375" },
//     "HUARA": { "lat": "-19.995690", "lon": "-69.769564" },
//     "PICA": { "lat": "-20.489397", "lon": "-69.330262" },
//     "POZO ALMONTE": { "lat": "-20.258156", "lon": "-69.785921" },
//     "IQUIQUE": {
//         "LA PUNTILLA": { "lat": "-20.208799", "lon": "-70.148604" },
//         "LA UNIÓN": { "lat": "-20.209466", "lon": "-70.145171" },
//         "REM. EL MORRO": { "lat": "-20.214200", "lon": "-70.156668" },
//         "EL MORRO": { "lat": "-20.216548", "lon": "-70.155292" },
//         "CENTRAL": { "lat": "-20.217081", "lon": "-70.143168" },
//         "PLAZA BRAZIL": { "lat": "-20.219755", "lon": "-70.144039" },
//         "COLISEO": { "lat": "-20.216044", "lon": "-70.145249" },
//         "FERRONOR": { "lat": "-20.211791", "lon": "-70.138829" },
//         "PUEBlO NUEVO": { "lat": "-20.217029", "lon": "-70.135646" },
//         "GRUMETE BOLADOS": { "lat": "-20.217955", "lon": "-70.140547" },
//         "TENIENTE IBÁÑEZ": { "lat": "-20.223610", "lon": "-70.140841" },
//         "BARROS ARANA": { "lat": "-20.220718", "lon": "-70.147817" },
//         "18 DE SEPTIEMBRE": { "lat": "-20.219048", "lon": "-70.145799" },
//         "CARAMPANGUE": { "lat": "-20.222380", "lon": "-70.147877" },
//         "21 DE MAYO": { "lat": "-20.223808", "lon": "-70.145901" },
//         "GRANADEROS": { "lat": "-20.225833", "lon": "-70.148084" },
//         "O'HGGINS": { "lat": "-20.226739", "lon": "-70.135187" },
//         "AMERICA": { "lat": "-20.229453", "lon": "-70.131219" },
//         "AMPLIACIÓN NUEVA VICTORIA": { "lat": "-20.224369", "lon": "-70.131668" },
//         "PLAZA ARICA": { "lat": "-20.212441", "lon": "-70.143245" },
//         "CEMENTERIO": { "lat": "-20.211791", "lon": "-70.138829" },
//         "VILLA NAVIDAD": { "lat": "-20.210358", "lon": "-70.130214" },
//         "POR UNA NUEVA ESPERANZA": { "lat": "-20.209122", "lon": "-70.129900" },
//         "VALLE VERDE": { "lat": "-20.227474", "lon": "-70.129298" },
//         "SANTA ROSA DE HUARA": { "lat": "-20.229578", "lon": "-70.128622" },
//         "PADRE HURTADO": { "lat": "-20.231666", "lon": "-70.137372" },
//         "NUEVO CHILE": { "lat": "-20.231442", "lon": "-70.140186" },
//         "SIGLO XXI": { "lat": "-20.233139", "lon": "-70.129235" },
//         "PEDRO PRADO I Y II": { "lat": "-20.234367", "lon": "-70.131080" },
//         "MAUQUE": { "lat": "-20.238111", "lon": "-70.128615" },
//         "CAVANCHA": { "lat": "-20.235478", "lon": "-70.150087" },
//         "VILLA OLÍMPICA": { "lat": "-20.236359", "lon": "-70.145470" },
//         "ALELIES II": { "lat": "-20.236359", "lon": "-70.145470" },
//         "LAS ROSAS": { "lat": "-20.237553", "lon": "-70.142421" },
//         "PLAYA BRAVA": { "lat": "-20.239023", "lon": "-70.144245" },
//         "LAS DUNAS I": { "lat": "-20.240446", "lon": "-70.127412" },
//         "COMPLEJO DEPORTIVO": { "lat": "-20.241825", "lon": "-70.131757" },
//         "COMITÉ DUNAS DEL MAR": { "lat": "-20.243083", "lon": "-70.126012" },
//         "TAMARUGAL III": { "lat": "-20.244863", "lon": "-70.125325" },
//         "COMITÉ ESFUERZO II": { "lat": "-20.244863", "lon": "-70.125325" },
//         "DOLORES": { "lat": "-20.247062", "lon": "-70.134684" },
//         "PRIMERAS PIEDRAS TOLIA NAVARRETE": { "lat": "-20.248496", "lon": "-70.138197" },
//         "ERNESTO TORRES": { "lat": "-20.245109", "lon": "-70.139094" },
//         "SANTA BÁRBARA TOMAS LAGUNA VERD": { "lat": "-20.247471", "lon": "-70.124239" },
//         "MATILLA-NUEVA MATILLA I": { "lat": "-20.250828", "lon": "-70.123561" },
//         "CHIPANA SUR": { "lat": "-20.253389", "lon": "-70.125638" },
//         "HUANTAJAYA I": { "lat": "-20.256087", "lon": "-70.125145" },
//         "HUAYQUIQUENUEVO RENACER": { "lat": "-20.256087", "lon": "-70.125145" },
//         "OASIS IQUIQUE": { "lat": "-20.255939", "lon": "-70.130597" },
//         "NORTE MAR": { "lat": "-20.258396", "lon": "-70.130500" },
//         "PUQUIOS I": { "lat": "-20.256087", "lon": "-70.125145" },
//         "HUANTAJAYA II": { "lat": "-20.261052", "lon": "-70.123939" },
//         "HUANTAJAYA III": { "lat": "-20.261052", "lon": "-70.123939" },
//         "SIN INFORMACION": { "lat": "-20.263165", "lon": "-70.131631" },
//         "PUQUIOS IV": { "lat": "-20.262957", "lon": "-70.122474" },
//         "SANTA CECILIA": { "lat": "-20.265725", "lon": "-70.122431" },
//         "LAS PARINAS": { "lat": "-20.267210", "lon": "-70.122372" },
//         "CERRO COLORADO": { "lat": "-20.266863", "lon": "-70.128443" },
//         "ALTOS DEL DRAGÓN": { "lat": "-20.270149", "lon": "-70.123161" },
//         "QUEBRADA BLANCA I y II": { "lat": "-20.270509", "lon": "-70.125189" },
//         "REY DEL MAR": { "lat": "-20.275912", "lon": "-70.125649" },
//         "REINA MAR": { "lat": "-20.278272", "lon": "-70.125469" },
//         "LA PORTADA": { "lat": "-20.291999", "lon": "-70.129622" },
//         "BAJO MOLLE": { "lat": "-20.291408", "lon": "-70.123203" }
//     },
//     "ALTO HOSPICIO": {
//         "El BORO": { "lat": "-20.248577", "lon": " -70.103365" },
//         "SAN LORENZO": { "lat": "-20.248158", "lon": " -70.099981" },
//         "EBENEZER": { "lat": "-20.244906", "lon": " -70.094780" },
//         "EMPRENDEDORES DEL DESIERTO": { "lat": "-20.244517", "lon": " -70.097634" },
//         "DESPERTAR DEL BORO": { "lat": "-20.244419", "lon": " -70.095803" },
//         "ESTRELLA DEL NORTE I": { "lat": "-20.245176", "lon": " -70.095336" },
//         "VILLA LAS AMÉRICAS": { "lat": "-20.245095", "lon": " -70.099615" },
//         "ELENA CAFARENA": { "lat": "-20.283077", "lon": " -70.099725" },
//         "CASAS DEL ALTO": { "lat": "-20.250472", "lon": " -70.107588" },
//         "VISTA HERMOSA": { "lat": "-20.256597", "lon": " -70.106936" },
//         "CONDOMINIO DOÑA OLGA": { "lat": "-20.255782", "lon": " -70.105445" },
//         "MUJER DEL FUTURO": { "lat": "-20.255603", "lon": " -70.102087" },
//         "NUEVA VIDA": { "lat": "-20.260707", "lon": " -70.097754" },
//         "REPUBLICA DE NORUEGA": { "lat": "-20.261673", "lon": " -70.102867" },
//         "13 DE JUNIO": { "lat": "-20.262757", "lon": " -70.100801" },
//         "29 DE ENERO": { "lat": "-20.263122", "lon": " -70.102693" },
//         "SECTOR IGLESIA": { "lat": "-20.264297", "lon": " -70.101431" },
//         "GÉNESIS": { "lat": "-20.264300", "lon": " -70.103465" },
//         "LIBERTAD": { "lat": "-20.262653", "lon": " -70.103739" },
//         "ZAPIGA II": { "lat": "-20.262501", "lon": " -70.104646" },
//         "ENQUELGA": { "lat": "-20.263964", "lon": " -70.106599" },
//         "ZAPIGA I": { "lat": "-20.264840", "lon": " -70.107422" },
//         "CALICHE I": { "lat": "-20.266847", "lon": " -70.105839" },
//         "SOL DEL NORTE": { "lat": "-20.266501", "lon": " -70.107161" },
//         "NUEVO IQUIQUE": { "lat": "-20.267825", "lon": " -70.106361" },
//         "EL MIRADOR": { "lat": "-20.268207", "lon": " -70.107022" },
//         "ALIANZA": { "lat": "-20.267075", "lon": " -70.108652" },
//         "SANTA LAURA": { "lat": "-20.269781", "lon": " -70.108421" },
//         "SANTA CATALINA I": { "lat": "-20.266941", "lon": " -70.109285" },
//         "11 DE MARZO": { "lat": "-20.266855", "lon": " -70.100596" },
//         "CERRO TARAPACA III": { "lat": "-20.268918", "lon": " -70.101165" },
//         "PROGRESO": { "lat": "-20.268626", "lon": " -70.099378" },
//         "1ERO DE OCTUBRE": { "lat": "-20.270661", "lon": " -70.108449" },
//         "PABLO NERUDA": { "lat": "-20.271451", "lon": " -70.107079" },
//         "SANTA CATALINA II": { "lat": "-20.272347", "lon": " -70.108768" },
//         "AVE FENIX": { "lat": "-20.270588", "lon": " -70.105469" },
//         "LOS VEINTICINCO": { "lat": "-20.270505", "lon": " -70.104268" },
//         "CHIAPA SUR": { "lat": "-20.271230", "lon": " -70.102948" },
//         "NARVAL": { "lat": "-20.272601", "lon": " -70.102259" },
//         "RAUL RETTIG": { "lat": "-20.273268", "lon": " -70.106864" },
//         "PAMPA PERDIZ": { "lat": "-20.272425", "lon": " -70.100859" },
//         "PORTALES DEL NORTE": { "lat": "-20.273384", "lon": " -70.108924" },
//         "BERNARDO LEIGHTON": { "lat": "-20.269957", "lon": " -70.099512" },
//         "NEGREIROS": { "lat": "-20.270782", "lon": " -70.097897" },
//         "CERRO TARAPACA": { "lat": "-20.273140", "lon": " -70.099287" },
//         "TARAPACA": { "lat": "-20.274433", "lon": " -70.097753" },
//         "16 DE JULIO": { "lat": "-20.275485", "lon": " -70.102708" },
//         "NUEVA ESPERANZA": { "lat": "-20.276683", "lon": " -70.105606" },
//         "VISTA AL MAR": { "lat": "-20.277641", "lon": " -70.107900" },
//         "VIDA NUEVA": { "lat": "-20.277911", "lon": " -70.100488" },
//         "16 DE DICIEMBRE": { "lat": "-20.277447", "lon": " -70.102973" },
//         "LA TORTUGA": { "lat": "-20.279225", "lon": " -70.104573" },
//         "URBINAS I": { "lat": "-20.276686", "lon": " -70.094555" },
//         "SANTA TERESA": { "lat": "-20.279061", "lon": " -70.091365" },
//         "MUJER EMPRENDEDORA I": { "lat": "-20.276336", "lon": " -70.081229" },
//         "MUJERES EMPRENDEDORAS II": { "lat": "-20.278515", "lon": " -70.079330" },
//         "KATHERINE ARCE": { "lat": "-20.277992", "lon": " -70.081857" },
//         "SANTA ROSA": { "lat": "-20.279703", "lon": " -70.082882" },
//         "MARIO MIRANDA": { "lat": "-20.280065", "lon": " -70.081240" },
//         "VILLA SANTA ROSA": { "lat": "-20.281393", "lon": " -70.079464" },
//         "PIONEROS DEL DESIERTO": { "lat": "-20.283191", "lon": " -70.103608" },
//         "UNION Y ESPERANZA": { "lat": "-20.283808", "lon": " -70.098695" },
//         "NUEVO DESPERTAD": { "lat": "-20.285626", "lon": " -70.103474" },
//         "SAN JORGE": { "lat": "-20.286794", "lon": " -70.102026" },
//         "318": { "lat": "-20.286689", "lon": " -70.095667" },
//         "LOS VOLCANES": { "lat": "-20.288204", "lon": " -70.091976" },
//         "VILLA MAR DEL PACÍFICO": { "lat": "-20.289940", "lon": " -70.091827" },
//         "RAMÓN GALLEGUILLOS": { "lat": "-20.288212", "lon": " -70.105219" },
//         "SALVADOR ALLENDE": { "lat": "-20.288582", "lon": " -70.106359" },
//         "JAIME GUZMAN": { "lat": "-20.289631", "lon": " -70.104919" },
//         "SAN LORENZO DE TARAPACA": { "lat": "-20.290620", "lon": " -70.102588" },
//         "GENDECORA": { "lat": "-20.292517", "lon": " -70.098555" },
//         "NORTE GRANDE": { "lat": "-20.292203", "lon": " -70.095968" },
//         "DOMANSAN": { "lat": "-20.291811", "lon": " -70.094474" },
//         "CONDOMINIO MAR DEL PACÍFICO": { "lat": "-20.290712", "lon": " -70.092543" },
//         "MIRADOR DEL PACIFICO": { "lat": "-20.297095", "lon": " -70.109974" },
//         "FUERZA JOVEN": { "lat": "-20.290539", "lon": " -70.107507" },
//         "VILLA QUITASOLES": { "lat": "-20.292602", "lon": " -70.108381" },
//         "VILLA VISTA AL MAR": { "lat": "-20.294046", "lon": " -70.108939" },
//         "NUEVO RENACER": { "lat": "-20.296340", "lon": " -70.107673" },
//         "SANTIAGO HUMBERSTONE": { "lat": "-20.295480", "lon": " -70.103618" },
//         "ALTO MOLLE": { "lat": "-20.291571", "lon": " -70.105796" },
//         "SOL NACIENTE": { "lat": "-20.293105", "lon": " -70.100765" },
//         "DOÑA INES DE COLLAHUASI": { "lat": "-20.292386", "lon": " -70.104428" }
//     }
// }
